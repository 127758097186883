import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../Loading";
import { RebalanceContext } from "./RebalanceInfo";
import HouseholdModelTrading from "../clients/household/HouseholdModelTrading";

const RebalanceHouseholdModelTrading = () => {
  const { rebalance, household } = useContext(RebalanceContext);

  return typeof rebalance === "undefined" ? (
    <Loading />
  ) : (
    <Row>
      <Col>
        {household && (
          <HouseholdModelTrading household={household} isEditable={false} />
        )}
      </Col>
    </Row>
  );
};

export default RebalanceHouseholdModelTrading;
