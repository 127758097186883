import _ from "lodash";
import React, { useContext, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import TaxPerformancePeriodSelector from "../../components/TaxPerformancePeriodSelector";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatCurrencyComponent } from "../../lib/numbers";
import { HouseholdContext } from "./HouseholdInfo";
import { HouseholdWithAccounts } from "../../../../api/src/households/households.service";

const HouseholdTaxSummary = ({
  rowSelection = {},
  useall = false,
  customTaxPeriodParams,
  setCustomTaxPeriodParams,
  household: propHousehold,
}: {
  rowSelection?: Record<string, boolean>;
  useall?: boolean;
  customTaxPeriodParams?: string;
  setCustomTaxPeriodParams?: React.Dispatch<React.SetStateAction<string>>;
  household?: HouseholdWithAccounts;
}) => {
  const { household: contextHousehold } = useContext(HouseholdContext);
  const household = propHousehold ?? contextHousehold;
  const householdId = household?.id;

  const [customTaxPeriodParamsInner, setCustomTaxPeriodParamsInner] =
    useState("");
  const {
    data: dataHouseholdAccountTaxPerformance,
    isPending: isPendingHouseholdAccountTaxPerformance,
    isError: isErrorHouseholdAccountTaxPerformance,
  } = useAuthenticatedFetch<
    UnpackResponse<HouseholdsController["getHouseholdPerformance"]>
  >(
    `/households/${householdId}/performance?${
      customTaxPeriodParams ?? customTaxPeriodParamsInner
    }`,
  );

  const taxableAccountTotals = (household?.accounts ?? [])
    .filter(
      (account) =>
        account.isTaxable && (useall || rowSelection[account.id] === true),
    )
    .reduce(
      (sums, account) => ({
        aum: sums.aum + (account.accountBalance ?? 0),
        cash: sums.cash + (account.cashBalance ?? 0),
        unrealizedGain: sums.unrealizedGain + (account.unrealizedGain ?? 0),
        unrealizedLoss: sums.unrealizedLoss + (account.unrealizedLoss ?? 0),
      }),
      {
        aum: 0,
        cash: 0,
        unrealizedGain: 0,
        unrealizedLoss: 0,
      },
    );

  const householdAccountTaxPerformanceData = (
    dataHouseholdAccountTaxPerformance?.data ?? []
  ).filter(
    (account) =>
      account.isTaxable &&
      (useall ||
        (typeof account.accountId !== "undefined" &&
          rowSelection[account.accountId] === true)),
  );

  const taxTotals = householdAccountTaxPerformanceData.reduce(
    (sums, account) => ({
      realizedGainST: sums.realizedGainST + account.realizedGainLossST,
      realizedGainLT: sums.realizedGainLT + account.realizedGainLossLT,
      taxableIncome: sums.taxableIncome + account.income,
      capitalGainDistributions:
        sums.capitalGainDistributions + account.capitalGainDistributions,
    }),
    {
      realizedGainST: 0,
      realizedGainLT: 0,
      taxableIncome: 0,
      capitalGainDistributions: 0,
    },
  );

  const oldestAccount = _.minBy(household?.accounts ?? [], "inceptionDate");

  return (
    <Row className="mb-2">
      <Col>
        <h4>Tax Summary</h4>
        <Row>
          <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
            Unrealized Gain
          </Col>
          <Col as="dd">
            {formatCurrencyComponent(taxableAccountTotals.unrealizedGain, 2)}
          </Col>
        </Row>
        <Row>
          <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
            Unrealized Loss
          </Col>
          <Col as="dd">
            {formatCurrencyComponent(taxableAccountTotals.unrealizedLoss, 2)}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xl={4} md={2} sm={4}>
            <TaxPerformancePeriodSelector
              inceptionDate={oldestAccount?.inceptionDate}
              setCustomPeriodParams={
                setCustomTaxPeriodParams ?? setCustomTaxPeriodParamsInner
              }
            />
          </Col>
        </Row>
        {isPendingHouseholdAccountTaxPerformance ? (
          <Loading message="Tax Details" />
        ) : isErrorHouseholdAccountTaxPerformance ? (
          <Alert variant="danger">Failed to load tax summary</Alert>
        ) : (
          <dl>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Realized LT G/L
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(taxTotals.realizedGainLT, 2)}
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Realized ST G/L
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(taxTotals.realizedGainST, 2)}
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Taxable Div./Int.
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(taxTotals.taxableIncome, 2)}
              </Col>
            </Row>
            <Row>
              <Col as="dt" xxl={5} xl={6} lg={3} md={4}>
                Capital Gain Distributions
              </Col>
              <Col as="dd">
                {formatCurrencyComponent(taxTotals.capitalGainDistributions, 2)}
              </Col>
            </Row>
          </dl>
        )}
      </Col>
    </Row>
  );
};

export default HouseholdTaxSummary;
