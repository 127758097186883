import { useCallback } from "react";
import { Button, ButtonGroup, Form, InputGroup, Table } from "react-bootstrap";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  Path,
  UseFieldArrayRemove,
  UseFormRegister,
} from "react-hook-form";
import type { SimpleAssignedItem } from "../../../api/src/models/models.service";
import FormFieldError from "../components/FormFieldError";
import { addRemoveLabel } from "../lib/display";
import { useQuerySecurityGroups } from "./lib";

type SecurityGroupField = SimpleAssignedItem & { _field_id: string };

const emptySecurityGroup: SecurityGroupField = {
  id: -1,
  weight: 0,
  _field_id: "",
};

/**
 * A multi-item form component for security groups in an investing model.
 * This component displays an arbitrary number of rows for users to view
 * and modify selected security groups.
 */
function SecurityGroupsSelector<
  TForm extends { securityGroups: SimpleAssignedItem[] },
>({
  fields,
  replace,
  add,
  remove,
  register,
  errors,
}: {
  /** The collection of sleeves in a model */
  fields: SecurityGroupField[];
  /** The setter function for the fields prop */
  replace: (securityGroups: SecurityGroupField[]) => void;
  add: (securityGroup: SecurityGroupField) => void;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<TForm>;
  errors?: Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<SimpleAssignedItem>> | undefined)[]
  >;
}) {
  const {
    isPending: isPendingSecurityGroups,
    isError: isErrorSecurityGroups,
    data: dataSecurityGroups,
  } = useQuerySecurityGroups({ includeMetrics: false });

  const sleeveOptions = isPendingSecurityGroups
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorSecurityGroups
      ? [
          <option key={null} value="">
            There was an error loading security groups
          </option>,
        ]
      : [
          <option key={null} value="">
            Select a security group
          </option>,
          ...(dataSecurityGroups ?? []).map((securityGroup) => (
            <option key={securityGroup.id} value={securityGroup.id}>
              {securityGroup.name}
            </option>
          )),
        ];

  const addSecurityGroup = useCallback(() => {
    add({ ...emptySecurityGroup });
  }, [add]);

  const removeSecurityGroup = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove],
  );

  return (
    <Table className="form-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Weight</th>
          <th>{addRemoveLabel}</th>
        </tr>
      </thead>
      <tbody>
        {fields.length <= 0 ? (
          <tr>
            <td>None</td>
            <td>{/* Empty */}</td>
            <td>
              <Button onClick={addSecurityGroup}>+</Button>
            </td>
          </tr>
        ) : (
          fields.map((securityGroup, index) => (
            <tr key={securityGroup._field_id}>
              <td className="align-top">
                <Form.Select
                  placeholder="Select sleeve"
                  // Registering before loading creates a race condition
                  {...(isPendingSecurityGroups
                    ? {}
                    : register(`securityGroups.${index}.id` as Path<TForm>))}
                >
                  {sleeveOptions}
                </Form.Select>
                <FormFieldError field={errors?.[index]?.id} />
              </td>
              <td className="align-top">
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step={0.01}
                      {...register(
                        `securityGroups.${index}.weight` as Path<TForm>,
                      )}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <FormFieldError field={errors?.[index]?.weight} />
                </Form.Group>
              </td>
              <td className="align-top">
                <ButtonGroup>
                  <Button onClick={removeSecurityGroup(index)}>-</Button>
                  {index !== fields.length - 1 ? null : (
                    <Button onClick={addSecurityGroup}>+</Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
}

export default SecurityGroupsSelector;
