import { FormEvent, useCallback, useContext, useState } from "react";
import { ButtonToolbar, Col, Offcanvas, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import type { UnpackResponse } from "../../../api/src/lib";
import type { RebalancesController } from "../../../api/src/rebalances/rebalances.controller";
import type { RebalanceHistory } from "../../../api/src/rebalances/rebalances.service";
import { NotificationContext } from "../Notifications";
import HouseholdNotesButton from "../clients/household/HouseholdNotesButton";
import ActionButton from "../components/ActionButton";
import EntityAuditTrail from "../components/EntityAuditTrail";
import SummaryPill from "../components/SummaryPill";
import { useAuthenticatedFetch } from "../lib/api";
import { formatCurrency } from "../lib/numbers";
import { RebalanceContext } from "./RebalanceInfo";
import { useChangeRebalanceState } from "./lib";

const RebalanceHeader = () => {
  const { rebalance, household } = useContext(RebalanceContext);

  const { rebalanceId } = useParams();
  const navigate = useNavigate();

  const updateRebalance = useChangeRebalanceState();

  const notificationContext = useContext(NotificationContext);

  const approve = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      await updateRebalance.mutateAsync({
        state: "approved",
        rebalanceIds: [parseInt(rebalanceId ?? "")],
        rebalance: rebalance,
      });

      notificationContext.pushNotification({
        id: `rebalance-${rebalanceId}`,
        header: "Rebalance Approved",
        body: `Rebalance ${rebalanceId} approved`,
        variant: "success",
      });

      navigate("..");
    },
    [navigate, notificationContext, rebalance, rebalanceId, updateRebalance],
  );

  const reject = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      await updateRebalance.mutateAsync({
        state: "proposed",
        rebalanceIds: [parseInt(rebalanceId ?? "")],
      });

      notificationContext.pushNotification({
        id: `rebalance-${rebalanceId}`,
        header: "Rebalance Rejected",
        body: `Rebalance ${rebalanceId} rejected`,
        variant: "warning",
      });

      navigate("..");
    },
    [navigate, notificationContext, rebalanceId, updateRebalance],
  );

  const cancel = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      await updateRebalance.mutateAsync({
        state: "canceled",
        rebalanceIds: [parseInt(rebalanceId ?? "")],
      });

      notificationContext.pushNotification({
        id: `rebalance-${rebalanceId}`,
        header: "Rebalance Cancelled",
        body: `Rebalance ${rebalanceId} canceled`,
        variant: "warning",
      });

      navigate("..");
    },
    [navigate, notificationContext, rebalanceId, updateRebalance],
  );

  const [isShowHistory, setIsShowHistory] = useState(false);
  const {
    isPending: isPendingRebalanceHistory,
    isError: isErrorRebalanceHistory,
    data: dataRebalanceHistory,
  } = useAuthenticatedFetch<
    UnpackResponse<RebalancesController["getRebalanceHistory"]>
  >(`/rebalances/${rebalanceId}/history`);
  const rebalanceHistory = dataRebalanceHistory?.data ?? {
    items: [],
    users: {},
  };

  const showHistory = useCallback(() => {
    setIsShowHistory(true);
  }, []);

  const hideHistory = useCallback(() => {
    setIsShowHistory(false);
  }, []);

  const renderRebalanceHistory = useCallback((item: RebalanceHistory) => {
    const results: JSX.Element[] = [];

    if (typeof item.status !== "undefined") {
      results.push(<li>Status: {item.status}</li>);
    }

    if (typeof item.dcaStartDate !== "undefined") {
      results.push(
        <li>DCA Start Date: {new Date(item.dcaStartDate).toLocaleString()}</li>,
      );
    }

    if (typeof item.dcaEndDate !== "undefined") {
      results.push(
        <li>DCA End Date: {new Date(item.dcaEndDate).toLocaleString()}</li>,
      );
    }

    if (typeof item.dcaInterval !== "undefined") {
      results.push(<li>DCA Interval: {item.dcaInterval}</li>);
    }

    if (typeof item.dcaBuySell !== "undefined") {
      results.push(<li>DCA Buy/Sell: {item.dcaBuySell}</li>);
    }

    if (typeof item.dcaCash !== "undefined") {
      results.push(<li>DCA Cash: {item.dcaCash}</li>);
    }

    if (typeof item.proposedExecutedTime !== "undefined") {
      results.push(
        <li>
          Proposed Executed Time:{" "}
          {new Date(item.proposedExecutedTime).toLocaleString()}
        </li>,
      );
    }

    if (item.trades.length > 0) {
      results.push(<li>Trades Modified: {item.trades.join(", ")}</li>);
    }

    return <ul className="ps-3">{results}</ul>;
  }, []);

  const trades = rebalance?.accounts.flatMap((account) => account.trades) ?? [];

  const buyTotal = trades
    .filter((trade) => trade.side === "buy")
    .reduce((sum, trade) => sum + trade.amount * trade.price, 0);

  const sellTotal = trades
    .filter((trade) => trade.side === "sell")
    .reduce((sum, trade) => sum + trade.amount * trade.price, 0);

  const linkedToWealthbox =
    typeof household?.idExternal.wealthbox !== "undefined";
  const linkedToRedtail = typeof household?.idExternal.redtail !== "undefined";
  const linkedToCrm = linkedToWealthbox || linkedToRedtail;

  return typeof rebalance === "undefined" ||
    typeof household === "undefined" ? null : (
    <RebalanceContext.Provider value={{ rebalance, household }}>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3 justify-content-end gap-2">
            {rebalance?.status === "proposed" ? (
              <ActionButton
                label="Approve & Save"
                icon="/icons/approve.svg"
                type="button"
                onClick={approve}
                disabled={updateRebalance.isPending}
              />
            ) : rebalance?.status === "approved" ? (
              <ActionButton
                label="Reject"
                icon="/icons/reject.svg"
                type="button"
                onClick={reject}
                disabled={updateRebalance.isPending}
              />
            ) : null}
            {!["requested", "proposed", "approved"].includes(
              rebalance?.status ?? "",
            ) ? null : (
              <ActionButton
                label="Cancel"
                icon="/icons/trash.svg"
                type="button"
                onClick={cancel}
                disabled={updateRebalance.isPending}
              />
            )}
            <ActionButton
              variant="secondary"
              icon="/icons/audit.svg"
              label="History"
              type="button"
              onClick={showHistory}
            />
            {!linkedToCrm ? null : (
              <HouseholdNotesButton householdId={household.id} />
            )}
            <ActionButton
              as={Link}
              to="/rebalances"
              label="Exit"
              icon="/icons/exit.svg"
            />
          </ButtonToolbar>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-between flex-wrap gap-3"
        >
          <span className="fs-3 fw-semibold lh-1 text-wrap">
            <Link to={`/clients/households/${household.id}`}>
              {household.name}
            </Link>
          </span>
          {rebalance.status === "requested" ? null : (
            <div>
              <SummaryPill
                label="AUM"
                value={formatCurrency(rebalance.totalBalance ?? 0)}
                size="sm"
                className="me-3 mb-3"
              />
              <SummaryPill
                label="Cash"
                value={formatCurrency(rebalance.cashBalance ?? 0)}
                size="sm"
                className="me-3 mb-3"
              />
              <SummaryPill
                label="Buy $"
                value={formatCurrency(buyTotal)}
                size="sm"
                className="me-3 mb-3"
              />
              <SummaryPill
                label="Sell $"
                value={`${formatCurrency(sellTotal)}`}
                size="sm"
                className="me-3 mb-3"
              />
              <SummaryPill
                label="Net $"
                value={`${formatCurrency(sellTotal - buyTotal)}`}
                size="sm"
              />
            </div>
          )}
        </Col>
      </Row>
      <Offcanvas show={isShowHistory} onHide={hideHistory} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Rebalance History</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <EntityAuditTrail
            history={rebalanceHistory}
            itemDisplay={renderRebalanceHistory}
            itemDisplayName="Rebalance"
            isLoading={isPendingRebalanceHistory}
            isError={isErrorRebalanceHistory}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </RebalanceContext.Provider>
  );
};

export default RebalanceHeader;
