import { useQueryClient } from "@tanstack/react-query";
import type { ContactsController } from "../../../../api/src/contacts/contacts.controller";
import type {
  Contact,
  ContactType,
  Gender,
  MaritalStatus,
  Prefix,
  Suffix,
} from "../../../../api/src/contacts/lib";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import { deserializeDate, useAuthenticatedMutationNew } from "../../lib/api";
import { capitalize } from "../../lib/display";
import { deserializeNote } from "../../lib/notes";
import { deserializeTask, deserializeWorkflow } from "../../tasks/lib";

export const CONTACT_TYPES: ContactType[] = ["person", "trust", "organization"];

export const GENDERS: Gender[] = [
  "male",
  "female",
  "non-binary",
  "other",
  "unknown",
];

export const MARITAL_STATUSES: MaritalStatus[] = [
  "single",
  "married",
  "divorced",
  "widowed",
  "life partner",
  "separated",
  "unknown",
];

export const HOUSEHOLD_TITLES = ["head", "spouse", "dependent", "other"];
export const HOUSEHOLD_TITLES_OPTIONS = HOUSEHOLD_TITLES.map(
  (title, index) => ({
    label: capitalize(title),
    value: index + 1,
  }),
);

export const PREFIXES: Prefix[] = [
  "Dr.",
  "Dr. & Mr.",
  "Dr. & Mrs.",
  "Drs.",
  "Father",
  "Judge",
  "Maj.",
  "Miss",
  "Mr.",
  "Mr. & Mrs.",
  "Mrs.",
  "Ms.",
  "Pastor",
  "Pfc.",
  "Prof.",
  "Rev.",
  "Sgt.",
  "Sister",
];

export const SUFFIXES: Suffix[] = ["II", "III", "IV", "V", "Jr.", "Sr."];

export function deserializeContact(
  contact: SerializedObject<Contact>,
): Contact {
  return {
    ...contact,
    birthDate: deserializeDate(contact.birthDate),
    clientSince: deserializeDate(contact.clientSince),
    anniversary: deserializeDate(contact.anniversary),
    dateOfDeath: deserializeDate(contact.dateOfDeath),
    retirementDate: deserializeDate(contact.retirementDate),
    archiveDate: deserializeDate(contact.archiveDate),
    notes: contact.notes.map(deserializeNote),
    tasks: contact.tasks.map(deserializeTask),
    workflows: contact.workflows.map(deserializeWorkflow),
  };
}

export function useCreateContact() {
  return useAuthenticatedMutationNew<
    UnpackResponse<ContactsController["create"]>,
    number,
    { type: ContactType; name: string }
  >({
    mutationKey: ["POST", "contacts"],
    mutationFn: ({ data }: { data: number }) => data,
  });
}

export function useArchiveContact(archiveContactId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ContactsController["archiveContact"]>,
    void,
    void
  >({
    mutationKey: ["POST", "contacts", archiveContactId, "archive"],
    mutationFn: () => {
      queryClient.invalidateQueries({
        queryKey: [`/contacts/${archiveContactId}`],
      });
    },
  });
}

export function useUnarchiveContact(archiveContactId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ContactsController["unarchiveContact"]>,
    void,
    void
  >({
    mutationKey: ["POST", "contacts", archiveContactId, "unarchive"],
    mutationFn: () => {
      queryClient.invalidateQueries({
        queryKey: [`/contacts/${archiveContactId}`],
      });
    },
  });
}
