import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { SleeveRequest } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError, { useErrorMessage } from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import HelpTooltip from "../components/HelpTooltip";
import SubmitButton from "../components/SubmitButton";
import {
  getSchemaFieldLabel,
  modelAssetCategorySchema,
  modelRegionSchema,
  primarySecuritiesSchema,
  securityGroupsSchema,
} from "../lib/forms";
import ModelAssetAllocationChart from "./ModelAssetAllocationChart";
import ModelInfoActionButtons from "./ModelInfoActionButtons";
import ModelPerformance from "./ModelPerformance";
import ModelRiskAllocationChart from "./ModelRiskAllocationChart";
import PrimarySecurities from "./PrimarySecurities";
import SecurityGroupsSelector from "./SecurityGroupsSelector";
import SleeveDeleteDialog from "./SleeveDeleteDialog";
import {
  primarySecurityHelpMessage,
  securityGroupHelpMessage,
  sumModelWeights,
  useCreateSleeve,
  useQuerySleeve,
  useUpdateSleeve,
} from "./lib";

type SleeveForm = Omit<SleeveRequest, "id">;

const schema: yup.ObjectSchema<SleeveForm> = yup
  .object({
    name: yup.string().required().label("Sleeve Name"),
    description: yup.string().label("Description"),
    assetCategory: modelAssetCategorySchema.required().label("Asset Category"),
    region: modelRegionSchema.required().label("Region"),
    securityGroups: securityGroupsSchema.required(),
    primarySecurities: primarySecuritiesSchema.required(),
  })
  .test({
    name: "weights-sum-100",
    test: (value) =>
      sumModelWeights(value.primarySecurities, value.securityGroups) === 100,
    message: "The sum of all weights must equal 100%",
  });

const SleeveInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValidating },
    reset,
    control,
    watch,
    getValues,
  } = useForm<SleeveForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    fields: securityGroupFields,
    append: appendSecurityGroup,
    remove: removeSecurityGroup,
    replace: replaceSecurityGroups,
  } = useFieldArray({ name: "securityGroups", control, keyName: "_field_id" });
  const {
    fields: primarySecurityFields,
    append: appendPrimarySecurity,
    remove: removePrimarySecurity,
    replace: replacePrimarySecurities,
  } = useFieldArray({
    name: "primarySecurities",
    control,
    keyName: "_field_id",
  });

  const { setError, resetError, errorMessage } = useErrorMessage();

  const { sleeveId: sleeveIdStr } = useParams();
  const sleeveId = parseInt(sleeveIdStr ?? "-1");
  const isNew = sleeveIdStr === "new";

  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: dataSleeve,
  } = useQuerySleeve(sleeveId, { enabled: !isNew });

  useEffect(() => {
    if (typeof dataSleeve !== "undefined") {
      const { securityGroups, primarySecurities } = dataSleeve;
      reset(dataSleeve);
      replaceSecurityGroups(securityGroups);
      replacePrimarySecurities(primarySecurities);
    }
  }, [dataSleeve, reset, replacePrimarySecurities, replaceSecurityGroups]);

  const createSleeve = useCreateSleeve();
  const updateSleeve = useUpdateSleeve(sleeveId);

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useCallback(
    async (data: SleeveForm) => {
      try {
        if (isNew) {
          const newSleeveId = await createSleeve.mutateAsync(data);
          notificationContext.pushNotification({
            id: `sleeve-${newSleeveId}`,
            header: "Sleeve Created",
            body: `${data.name} sleeve created`,
            variant: "success",
          });
          navigate(`../${newSleeveId}`);
        } else {
          const sleeveBody = await updateSleeve.mutateAsync(data);
          reset(sleeveBody);
          notificationContext.pushNotification({
            id: `sleeve-${sleeveIdStr}`,
            header: "Sleeve Updated",
            body: `${data.name} sleeve updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save sleeve", err);
        notificationContext.pushNotification({
          id: `sleeve-${sleeveIdStr}`,
          header: "Failed to Save Sleeve",
          body: `Sleeve ${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
    [
      createSleeve,
      isNew,
      navigate,
      notificationContext,
      reset,
      sleeveIdStr,
      updateSleeve,
    ],
  );

  const watchPrimarySecurityArray = watch("primarySecurities");
  const controlledPrimarySecurityFields = primarySecurityFields.map(
    (field, index) => ({
      ...field,
      ...watchPrimarySecurityArray[index],
    }),
  );

  const watchSecurityGroupsArray = watch("securityGroups", []);

  // react-hook-form does not have form-level validation,
  // so we must perform it manually.
  useEffect(() => {
    if (isValidating) {
      const sum = sumModelWeights(
        watchPrimarySecurityArray,
        watchSecurityGroupsArray,
      );

      if (sum === 100) {
        resetError();
      } else {
        setError("The sum of all weights must equal 100");
      }
    }
  }, [
    watchPrimarySecurityArray,
    setError,
    resetError,
    isValidating,
    watchSecurityGroupsArray,
  ]);

  const [showModal, setShowModal] = useState(false);
  const [deleteSleeveId, setDeleteSleeveId] = useState(0);
  const handleDeleteSleeveClick = useCallback(() => {
    setDeleteSleeveId(sleeveId);
    setShowModal(true);
  }, [sleeveId]);

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !dataSleeve && !isNew ? (
    <FormError message="Sleeve not found" />
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <h1>Sleeve Details</h1>
        </Col>
        <Col md="auto">
          <ModelInfoActionButtons deleteHandler={handleDeleteSleeveClick} />
        </Col>
      </Row>
      <Content>
        {errorMessage}
        <Row>
          <Col xxl={9} md={7}>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group controlId="form-name">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.name)}
                  </Form.Label>
                  <Form.Control type="text" {...register("name")} />
                  <FormFieldError field={errors.name} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group controlId="form-description">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.description)}
                  </Form.Label>
                  <Form.Control type="text" {...register("description")} />
                  <FormFieldError field={errors.description} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group controlId="form-assetCategory">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.assetCategory)}
                  </Form.Label>
                  <Form.Select
                    placeholder="Select an asset category"
                    {...register("assetCategory")}
                  >
                    <option value="stock">Stock</option>
                    <option value="bond">Bond</option>
                    <option value="other">Other</option>
                    <option value="cash">Cash</option>
                  </Form.Select>
                  <FormFieldError field={errors.assetCategory} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group controlId="formRegion">
                  <Form.Label>
                    {getSchemaFieldLabel(schema.fields.region)}
                  </Form.Label>
                  <Form.Select
                    placeholder="Select a region"
                    {...register("region")}
                  >
                    <option value="US">US</option>
                    <option value="global">Global</option>
                  </Form.Select>
                  <FormFieldError field={errors.region} />
                </Form.Group>
              </Col>
            </Row>
            <div className="d-flex">
              <h2 className="me-1">Security Groups</h2>
              <HelpTooltip tooltip={securityGroupHelpMessage} />
            </div>
            <SecurityGroupsSelector
              fields={securityGroupFields}
              replace={replaceSecurityGroups}
              add={appendSecurityGroup}
              remove={removeSecurityGroup}
              register={register}
            />
            <div className="d-flex">
              <h2 className="me-1">Primary Securities</h2>
              <HelpTooltip tooltip={primarySecurityHelpMessage} />
            </div>
            <PrimarySecurities
              fields={controlledPrimarySecurityFields}
              replace={replacePrimarySecurities}
              add={appendPrimarySecurity}
              remove={removePrimarySecurity}
              register={register}
              control={control}
              getValues={getValues}
              errors={errors.primarySecurities}
              securityGroups={watchSecurityGroupsArray}
            />
            <Row>
              <Col>
                <SubmitButton isSubmitting={isSubmitting} />
              </Col>
            </Row>
          </Col>
          <Col>
            <ModelPerformance model={getValues()} isLoading={isLoading} />
            <ModelAssetAllocationChart
              model={getValues()}
              isLoading={isLoading}
            />
            <ModelRiskAllocationChart
              model={getValues()}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Content>
      <SleeveDeleteDialog
        showModal={showModal}
        setShowModal={setShowModal}
        deleteSleeveId={deleteSleeveId}
        setDeleteSleeveId={setDeleteSleeveId}
      />
    </Form>
  );
};

export default SleeveInfo;
