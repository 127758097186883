import _ from "lodash";
import { ChangeEventHandler, useEffect, useState } from "react";
import * as yup from "yup";
import type { HouseholdTitle } from "../../../api/src/contacts/lib";
import type { SecurityRestrictionTreatment } from "../../../api/src/models/models.service";
import { HOUSEHOLD_TITLES } from "../clients/contact/lib";
import { monthOptions, toISODateNoTimezone } from "./date";
import { phoneMask } from "./masks";

export const setNestedCollectionField = <TValue = any>(
  properties: (string | number)[],
  collection: any[],
  setCollection: (collection: any[]) => void,
  value: TValue,
  type = "text",
) => {
  const newObj = [...collection];

  _.set(
    newObj,
    properties.map((propName) => propName.toString()).join("."),
    type === "number" ? Number(value) : value,
  );

  setCollection(newObj);
};

export const setCollectionField = <TValue = any>(
  index: number,
  fieldName: string,
  collection: any[],
  setCollection: (collection: any[]) => void,
  value: TValue,
  type = "text",
) => {
  return setNestedCollectionField(
    [index, fieldName],
    collection,
    setCollection,
    value,
    type,
  );
};

export const setCollectionFieldHandler =
  (
    index: number,
    fieldName: string,
    collection: any[],
    setCollection: (collection: any[]) => void,
  ): ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  > =>
  (ev) => {
    setCollectionField(
      index,
      fieldName,
      collection,
      setCollection,
      ev.target.value,
      ev.target.type,
    );
  };

// The following debouncer implementation was adopted from a StackOverflow answer.
// https://stackoverflow.com/a/68228099/691501

type Deferred<TResult> = {
  promise: Promise<TResult> | null;
  cancel: (reason?: any) => void;
};

function deferred<TResult>(ms: number): Deferred<TResult> {
  let cancel: (reason?: any) => void = () => null;
  const promise = new Promise<TResult>((resolve, reject) => {
    cancel = reject;
    window.setTimeout(resolve, ms);
  });
  return { promise, cancel };
}

export function debouncer<TResult>(task: () => Promise<TResult>, ms: number) {
  let t: Deferred<TResult> = { promise: null, cancel: () => null };
  return [
    async () => {
      try {
        t.cancel();
        t = deferred(ms);
        await t.promise;
        await task();
      } catch {
        /* prevent memory leak */
      }
    },
    () => t.cancel(),
  ];
}

export function useDebounce<TResult>(task: () => Promise<TResult>, ms: number) {
  const [f, cancel] = debouncer(task, ms);
  useEffect(() => cancel);
  return [f, cancel];
}

export function useDebounceValue(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

/**
 * Get the label value for a given schema field. This accepts an 'any' type
 * argument because the `describe` return type in Yup v1 is incorrect.
 *
 * @param schema - The schema value to describe
 */
export function getSchemaFieldLabel(schema: any): string {
  return schema.describe().label;
}

export const undefinedStringSchema = yup
  .string()
  .transform((value, originalValue) => {
    return typeof originalValue === "undefined" ||
      originalValue === null ||
      originalValue === ""
      ? undefined
      : value;
  });

/**
 * Yup's number formatting error will override the required error, which is bad
 * from a UX perspective. Transform an empty value into a value that will
 * trigger the required field error instead of a type error.
 */
export const requiredNumberSchema = yup
  .number()
  .required()
  .transform((value, originalValue) => {
    return typeof originalValue === "undefined" ||
      originalValue === null ||
      originalValue === ""
      ? null
      : value;
  });
export const undefinedNumberSchema = yup
  .number()
  .transform((value, originalValue) => {
    return typeof originalValue === "undefined" ||
      originalValue === null ||
      originalValue === ""
      ? undefined
      : value;
  });
export const nullableNumberSchema = yup
  .number()
  .defined()
  .nullable()
  .transform((value, originalValue) => {
    return typeof originalValue === "undefined" ||
      originalValue === null ||
      originalValue === ""
      ? null
      : value;
  });
export const idOptionalSelectorSchema = yup
  .number()
  .transform((value, originalValue) => {
    return typeof originalValue === "undefined" ||
      originalValue === null ||
      originalValue === ""
      ? undefined
      : value;
  });
export const idsOptionalSelectorSchema = yup
  .array()
  .of(yup.number().required());

export function optionalStringValueTransform<TValue>(
  value: TValue,
  originalValue: string,
) {
  return originalValue === "" ? undefined : value;
}

export const monthSchema = yup.string().oneOf(monthOptions);

export const givenNameSchema = yup.string().label("First Name");
export const familyNameSchema = yup.string().label("Last Name");
export const additionalNameSchema = yup.string().label("Middle Name");
export const householdTitleSchema = undefinedStringSchema
  .oneOf(HOUSEHOLD_TITLES as HouseholdTitle[])
  .label("Household Title");
export const emailSchema = yup.string().email().label("Email");
export const phoneNumberSchema = yup
  .string()
  .transform(phoneMask.transform)
  .matches(/^\+[0-9]{11}$/, { excludeEmptyString: true }) // Only support US phone numbers for now
  .label("Phone Number");
export const dateSchema = yup.date().transform(optionalStringValueTransform);
export const birthDateSchema = dateSchema
  .max(toISODateNoTimezone(new Date()))
  .label("Birthdate");
export const zipCodeSchema = yup
  .string()
  .matches(/^[0-9]{5}(-[0-9]{4})?$/, { excludeEmptyString: true })
  .label("Zip Code");

export const riskScoreSchema = yup
  .number()
  .min(1)
  .max(10)
  .transform((val) => (val === Number(val) ? val : null))
  .required()
  .nullable()
  .label("Risk Score");

export const modelAssetCategorySchema = yup
  .string()
  .oneOf(["stock", "bond", "other", "cash"]);
export const modelRegionSchema = yup.string().oneOf(["US", "global"]);
export const modelSecurityWeightSchema = yup
  .number()
  .min(0)
  .max(100)
  .typeError(({ label }) => `${label} must be a number.`);

export const primarySecuritiesSchema = yup.array().of(
  yup.object({
    symbol: yup.string().required().label("Symbol"),
    weight: modelSecurityWeightSchema.required().label("Weight"),
  }),
);
export const secondarySecuritiesSchema = yup.array().of(
  yup.object({
    symbol: yup.string().required().label("Symbol"),
    treatment: yup
      .string()
      .oneOf(["like_primary", "hold_only", "sell_no_tax"])
      .required()
      .label("Treatment"),
  }),
);
export const sleevesSchema = yup.array().of(
  yup.object({
    id: yup.number().required().label("Sleeve"),
    weight: modelSecurityWeightSchema.required().label("Weight"),
  }),
);
export const securityGroupsSchema = yup.array().of(
  yup.object({
    id: yup.number().required().label("Security Group"),
    weight: modelSecurityWeightSchema.required().label("Weight"),
  }),
);

export const securityRestrictionTreatmentSchema = yup
  .string()
  .oneOf<SecurityRestrictionTreatment>(["hold_only", "sell_no_tax"])
  .required();

export const securityRestrictionSchema = yup
  .array()
  .of(
    yup.object({
      symbol: yup.string().required().label("Symbol"),
      treatment: securityRestrictionTreatmentSchema.label("Treatment"),
    }),
  )
  .label("Security Restrictions");
