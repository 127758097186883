import type { Month } from "../../../api/src/lib/date";

/**
 * Date without time or timezone. Constructing a new instance with another Date
 * object will create a DateWithoutTime object by converting the date to the
 * system timezone and stripping the time portion.
 */
export class DateWithoutTime extends Date {
  constructor(date: number | string | Date) {
    if (typeof date === "number") {
      super(date);
      this.setUTCHours(0, 0, 0, 0);
    } else if (typeof date === "string") {
      super(date.substring(0, 10));
    } else if (date instanceof Date) {
      super(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    } else {
      throw new Error("Invalid date type");
    }
  }

  getFullYear = super.getUTCFullYear;
  getMonth = super.getUTCMonth;
  getDate = super.getUTCDate;
  getDay = super.getUTCDay;
  getHours = super.getUTCHours;
  getMinutes = super.getUTCMinutes;
  getSeconds = super.getUTCSeconds;
  getMilliseconds = super.getUTCMilliseconds;

  toString(): string {
    return this.toISOString();
  }

  toISOString(): string {
    return super.toISOString().substring(0, 10);
  }

  toLocaleString(
    locales?: Intl.LocalesArgument | string | string[],
    options?: Intl.DateTimeFormatOptions,
  ): string {
    return this.toLocaleDateString(locales, options);
  }

  toLocaleDateString(
    locales?: Intl.LocalesArgument | string | string[],
    options?: Intl.DateTimeFormatOptions,
  ): string {
    return super.toLocaleDateString(locales, { ...options, timeZone: "UTC" });
  }
}

/**
 * Get a date string in ISO format when we don't care about the timezone.
 * 'Date.prototype.toISOString` uses the UTC date value, which might be
 * different from the date we want.
 * @param date The date to translate into a string
 * @returns An ISO-formatted date string (ex. 2012-01-01)
 */
export function toISODateNoTimezone(date: string | Date): string {
  if (date === "") {
    return "";
  }

  const dateObj = new Date(
    typeof date !== "string" ? date : `${date.substring(0, 10)}T00:00:00`,
  );

  return `${dateObj.getFullYear().toString().padStart(2, "0")}-${(
    dateObj.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${dateObj.getDate().toString().padStart(2, "0")}`;
}

/**
 * Get a date object in the local timezone from an ISO date string.
 * 'Date.parse` assumes the given value is UTC, which might be different
 * from the date we want.
 * @param date An ISO date string
 * @returns A date object in the local timezone
 */
export function parseISODateNaive(date: string): Date {
  const segments = date.split("-").map((number) => parseInt(number));

  if (segments.length !== 3 || segments.some(Number.isNaN)) {
    throw new Error(`Invalid ISO date string: ${date}`);
  }

  return new Date(segments[0], segments[1] - 1, segments[2]);
}

export function sortISODate(dateA: Date | null, dateB: Date | null) {
  if (dateA === dateB) {
    return 0;
  } else if (dateB === null || (dateA !== null && dateA > dateB)) {
    return 1;
  } else {
    return -1;
  }
}

export function dateIsUpcoming(
  date: Date | string | null,
  leadTimeSeconds: number,
  asOfDate = new Date(),
) {
  const diff = dateDiff(date, asOfDate);
  return diff >= 0 && diff <= leadTimeSeconds * 1000;
}

export function dateIsUpcomingOrOverdue(
  date: Date | string | null,
  leadTimeSeconds: number,
  asOfDate = new Date(),
) {
  const diff = dateDiff(date, asOfDate);
  return diff <= leadTimeSeconds * 1000;
}

export function dateDiff(
  dateA: Date | string | null,
  dateB: Date | string | null,
  units: "ms" | "s" | "m" | "h" | "d" = "ms",
) {
  // Convert date objects to UTC first to avoid issues that span DST changes.
  const endDateUTC =
    dateA === null
      ? new Date(0)
      : typeof dateA === "string"
        ? new Date(dateA)
        : Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate());
  const startDateUTC =
    dateB === null
      ? new Date(0)
      : typeof dateB === "string"
        ? new Date(dateB)
        : Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate());
  const diff = endDateUTC.valueOf() - startDateUTC.valueOf();

  switch (units) {
    case "s":
      return diff / 1000;
    case "m":
      return diff / 1000 / 60;
    case "h":
      return diff / 1000 / 60 / 60;
    case "d":
      return diff / 1000 / 60 / 60 / 24;
    default:
      return diff;
  }
}

export function isLeapYear(year: number) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function getStartOfYear(year = new Date().getFullYear()) {
  return new Date(year, 0, 1);
}

export function dateComparator(a: Date, b: Date) {
  return a > b ? 1 : b > a ? -1 : 0;
}

export const monthOptions: Month[] = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export function monthToDisplay(monthStr: Month) {
  switch (monthStr) {
    case "jan":
      return "January";
    case "feb":
      return "February";
    case "mar":
      return "March";
    case "apr":
      return "April";
    case "may":
      return "May";
    case "jun":
      return "June";
    case "jul":
      return "July";
    case "aug":
      return "August";
    case "sep":
      return "September";
    case "oct":
      return "October";
    case "nov":
      return "November";
    case "dec":
      return "December";
    default:
      throw new Error(`Unknown month: ${monthStr}`);
  }
}

export type PerformancePeriod =
  | "ytd"
  | "last-year"
  | "6month"
  | "1year"
  | "3year"
  | "5year"
  | "inception"
  | "custom";

export const performancePeriods: PerformancePeriod[] = [
  "ytd",
  "last-year",
  "6month",
  "1year",
  "3year",
  "5year",
  "inception",
  "custom",
];

/**
 * Get the date range for a given account performance period.
 * @param period The period enum
 * @param customStartDate The optional start date for a custom period
 * @param customEndDate The optional end date for a custom period
 * @returns The start and end dates of the period
 */
export function performancePeriodToDateRange(
  period: PerformancePeriod,
  customStartDate?: Date,
  customEndDate?: Date,
) {
  const now = new Date();
  let startDate: Date | undefined;
  let endDate = now;

  switch (period) {
    case "ytd":
      startDate = getStartOfYear(now.getFullYear());
      break;
    case "last-year":
      startDate = getStartOfYear(now.getFullYear() - 1);
      endDate = getStartOfYear(now.getFullYear());
      break;
    case "6month":
      startDate = new Date(now);
      startDate.setMonth(now.getMonth() - 6);
      break;
    case "1year":
      startDate = new Date(now);
      startDate.setFullYear(now.getFullYear() - 1);
      break;
    case "3year":
      startDate = new Date(now);
      startDate.setFullYear(now.getFullYear() - 3);
      break;
    case "5year":
      startDate = new Date(now);
      startDate.setFullYear(now.getFullYear() - 5);
      break;
    case "inception":
      break;
    case "custom":
      if (
        typeof customStartDate === "undefined" ||
        typeof customEndDate === "undefined"
      ) {
        throw new Error(
          "Start and/or End date not specified for custom performance period",
        );
      }

      startDate = customStartDate;
      endDate = customEndDate;
      break;
  }

  return { startDate, endDate };
}

/**
 * Get the human-readable name for the given account performance period.
 * @param period The period enum
 * @returns The human-readable period name
 */
export function performancePeriodToDisplay(period: PerformancePeriod) {
  switch (period) {
    case "ytd":
      return "YTD";
    case "last-year":
      return "Last Year";
    case "6month":
      return "6 Months";
    case "1year":
      return "1 Year";
    case "3year":
      return "3 Years";
    case "5year":
      return "5 Years";
    case "inception":
      return "Inception";
    case "custom":
      return "Custom";
  }
}

export const MAX_DATE = new Date("9999-12-31");
