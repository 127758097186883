import { Navigate, Outlet, RouteObject } from "react-router-dom";
import Auth from "./Auth";
import Layout from "./Layout";
import Login from "./Login";
import ProtectedRoute from "./ProtectedRoute";
import SignUp from "./SignUp";
import AssetExclusionInfo from "./billing/AssetExclusionInfo";
import AssetExclusionTable from "./billing/AssetExclusionTable";
import BillingMinimumInfo from "./billing/BillingMinimumInfo";
import BillingMinimumTable from "./billing/BillingMinimumTable";
import BillingReportInfo from "./billing/BillingReportInfo";
import BillingReportsTable from "./billing/BillingReportsTable";
import BillingSplitsInfo from "./billing/BillingSplitsInfo";
import BillingSplitsTable from "./billing/BillingSplitsTable";
import BillingSummary from "./billing/BillingSummary";
import CreateBillingReport from "./billing/CreateBillingReport";
import FeeStructureInfo from "./billing/FeeStructureInfo";
import FeeStructureTable from "./billing/FeeStructureTable";
import AccountCashSettings from "./cash/AccountCashSettings";
import CashSettingInfo from "./cash/CashSettingInfo";
import CashSettings from "./cash/CashSettings";
import CreateForecastedTransfer from "./cash/CreateForecastedTransfer";
import RecurringCashPredictions from "./cash/RecurringCashPredictions";
import AccountCashBillingSettings from "./clients/account/AccountCashBillingSettings";
import AccountInfo from "./clients/account/AccountInfo";
import AccountModelTrading from "./clients/account/AccountModelTrading";
import AccountOverview from "./clients/account/AccountOverview";
import AccountPortfolio from "./clients/account/AccountPortfolio";
import AccountProfile from "./clients/account/AccountProfile";
import AccountTax from "./clients/account/AccountTax";
import AccountTransactions from "./clients/account/AccountTransactions";
import Accounts from "./clients/account/Accounts";
import AccountsHouseholdsMapping from "./clients/account/AccountsHouseholdsMapping";
import ContactInfo from "./clients/contact/ContactInfo";
import ContactMapping from "./clients/contact/ContactMapping";
import Contacts from "./clients/contact/Contacts";
import CreateContact from "./clients/contact/CreateContact";
import CreateHousehold from "./clients/household/CreateHousehold";
import HouseholdAccountCashSettings from "./clients/household/HouseholdAccountCashSettings";
import HouseholdCashBilling from "./clients/household/HouseholdCashBilling";
import HouseholdInfo from "./clients/household/HouseholdInfo";
import HouseholdMapping from "./clients/household/HouseholdMapping";
import HouseholdModelTrading from "./clients/household/HouseholdModelTrading";
import HouseholdOverview from "./clients/household/HouseholdOverview";
import HouseholdPortfolio from "./clients/household/HouseholdPortfolio";
import HouseholdProfile from "./clients/household/HouseholdProfile";
import HouseholdTasks from "./clients/household/HouseholdTasks";
import HouseholdTax from "./clients/household/HouseholdTax";
import HouseholdTransactions from "./clients/household/HouseholdTransactions";
import Households from "./clients/household/Households";
import AssignedTasks from "./dashboard/AssignedTasks";
import ClientSummary from "./dashboard/ClientSummary";
import FirmSummary from "./dashboard/FirmSummary";
import TransactionSummary from "./dashboard/TransactionSummary";
import FirmInfo from "./firm/FirmInfo";
import RiskAllocationsAssetClass from "./firm/RiskAllocationsAssetClass";
import SecurityAssetCategoryOverrides from "./firm/SecurityAssetCategoryOverrides";
import CategoryAssetClass from "./firm/SecurityCategoryAssetClass";
import BenchmarkInfo from "./models/BenchmarkInfo";
import Benchmarks from "./models/Benchmarks";
import ModelInfo from "./models/ModelInfo";
import Models from "./models/Models";
import SecurityGroupInfo from "./models/SecurityGroupInfo";
import SecurityGroups from "./models/SecurityGroups";
import SleeveInfo from "./models/SleeveInfo";
import ModelSleeves from "./models/Sleeves";
import CreateUser from "./profile/CreateUser";
import IntegrationCredentials from "./profile/IntegrationCredentials";
import UserProfile from "./profile/UserProfile";
import CreateRebalance from "./rebalances/CreateRebalance";
import ManualRebalanceGenerator from "./rebalances/ManualRebalanceGenerator";
import RebalanceHouseholdCashBilling from "./rebalances/RebalanceHouseholdCashBilling";
import RebalanceHouseholdDetails from "./rebalances/RebalanceHouseholdDetails";
import RebalanceHouseholdModelTrading from "./rebalances/RebalanceHouseholdModelTrading";
import RebalanceInfo from "./rebalances/RebalanceInfo";
import RebalanceSummary from "./rebalances/RebalanceSummary";
import RebalanceTrades from "./rebalances/RebalanceTrades";
import RebalancesApproved from "./rebalances/RebalancesApproved";
import RebalancesFuture from "./rebalances/RebalancesFuture";
import RebalancesToday from "./rebalances/RebalancesToday";
import CreateReport from "./reports/CreateReport";
import DataExports from "./reports/DataExports";
import ExportAccountBalances from "./reports/ExportAccountBalances";
import ExportAccountPositions from "./reports/ExportAccountPositions";
import ReportBatchInfo from "./reports/ReportBatchInfo";
import ReportBatches from "./reports/ReportBatches";
import ReportInfo from "./reports/ReportInfo";
import ReportTemplateInfo from "./reports/ReportTemplateInfo";
import ReportTemplates from "./reports/ReportTemplates";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Navigate to="/dashboard" replace /> },
      { path: "/login", element: <Login /> },
      { path: "/signup", element: <SignUp /> },
      /* 
        The /auth route receives the redirect code following
        authentication from Auth0 so that the Auth0Provider component
        has an entry point to navigate React Router to the initial page.
      */
      {
        path: "/auth/:service",
        element: (
          <ProtectedRoute>
            <Auth />
          </ProtectedRoute>
        ),
      },
      {
        path: "/dashboard/*",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        handle: { crumb: () => "Home" },
        children: [
          { index: true, element: <Navigate to="firm" replace /> },
          {
            path: "firm",
            element: <FirmSummary />,
            handle: { crumb: () => "Firm Summary" },
          },
          {
            path: "clients",
            element: <ClientSummary />,
            handle: { crumb: () => "Households" },
          },
          {
            path: "transactions",
            element: <TransactionSummary />,
            handle: { crumb: () => "Transactions" },
          },
          {
            path: "tasks",
            element: <AssignedTasks />,
            handle: { crumb: () => "Tasks" },
          },
        ],
      },
      {
        path: "models/*",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        handle: { crumb: () => "Models" },
        children: [
          { index: true, element: <Navigate to="models" replace /> },
          {
            path: "models",
            element: <Outlet />,
            handle: { crumb: () => "Models" },
            children: [
              { index: true, element: <Models /> },
              {
                path: ":modelId",
                element: <ModelInfo />,
                handle: { crumb: () => "Details" },
              },
            ],
          },
          {
            path: "sleeves",
            element: <Outlet />,
            handle: { crumb: () => "Sleeves" },
            children: [
              { index: true, element: <ModelSleeves /> },
              {
                path: ":sleeveId",
                element: <SleeveInfo />,
                handle: { crumb: () => "Details" },
              },
            ],
          },
          {
            path: "security-groups",
            element: <Outlet />,
            handle: { crumb: () => "Security Groups" },
            children: [
              { index: true, element: <SecurityGroups /> },
              {
                path: ":securityGroupId",
                element: <SecurityGroupInfo />,
                handle: { crumb: () => "Details" },
              },
            ],
          },
          {
            path: "benchmarks",
            element: <Outlet />,
            handle: { crumb: () => "Benchmarks" },
            children: [
              { index: true, element: <Benchmarks /> },
              {
                path: ":benchmarkId",
                element: <BenchmarkInfo />,
                handle: { crumb: () => "Details" },
              },
            ],
          },
        ],
      },
      {
        path: "rebalances/*",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        handle: { crumb: () => "Rebalancing" },
        children: [
          { index: true, element: <Navigate to="today" replace /> },
          {
            path: "today",
            element: <RebalancesToday />,
            handle: { crumb: () => "Today Suggested" },
          },
          {
            path: "approved",
            element: <RebalancesApproved />,
            handle: { crumb: () => "Today Approved" },
          },
          {
            path: "future",
            element: <RebalancesFuture />,
            handle: { crumb: () => "Future" },
          },
          {
            path: "new",
            element: <CreateRebalance />,
            handle: { crumb: () => "New" },
          },
          ...(process.env.REACT_APP_FEATURE_MANUAL_REBALANCE !== "true"
            ? []
            : [
                {
                  path: "manual",
                  element: <ManualRebalanceGenerator />,
                  handle: { crumb: () => "Manual" },
                },
              ]),
          {
            path: ":rebalanceId",
            element: <RebalanceInfo />,
            handle: { crumb: () => "Details" },
            children: [
              { index: true, element: <Navigate to="overview" replace /> },
              { path: "overview", element: <RebalanceSummary /> },
              { path: "trades", element: <RebalanceTrades /> },
              {
                path: "household-details",
                element: <RebalanceHouseholdDetails />,
              },
              {
                path: "model-trading",
                element: <RebalanceHouseholdModelTrading />,
              },
              {
                path: "cash-billing",
                element: <RebalanceHouseholdCashBilling />,
              },
            ],
          },
        ],
      },
      {
        path: "cash/*",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        handle: { crumb: () => "Cash" },
        children: [
          { index: true, element: <Navigate to="accounts" replace /> },
          {
            path: "accounts",
            element: <AccountCashSettings />,
            handle: { crumb: () => "Accounts" },
          },
          {
            path: "settings",
            element: <Outlet />,
            handle: { crumb: () => "Cash Settings" },
            children: [
              { index: true, element: <CashSettings /> },
              {
                path: ":cashSettingId",
                element: <CashSettingInfo />,
                handle: { crumb: () => "Details" },
              },
            ],
          },
          {
            path: "predictions",
            element: <Outlet />,
            handle: { crumb: () => "Recurring Cash Predictions" },
            children: [
              { index: true, element: <RecurringCashPredictions /> },
              {
                path: "new",
                element: <CreateForecastedTransfer />,
                handle: { crumb: () => "Create Forecasted Transfer" },
              },
            ],
          },
        ],
      },
      {
        path: "/clients",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        handle: { crumb: () => "Clients" },
        children: [
          { index: true, element: <Navigate to="households" replace /> },
          {
            path: "households",
            element: (
              <ProtectedRoute>
                <Outlet />
              </ProtectedRoute>
            ),
            handle: { crumb: () => "Households" },
            children: [
              { index: true, element: <Households /> },
              {
                path: "mapping",
                element: <HouseholdMapping />,
                handle: { crumb: () => "Data Connections" },
              },
              {
                path: "new",
                element: <CreateHousehold />,
                handle: { crumb: () => "New" },
              },
              {
                path: ":householdId",
                element: <HouseholdInfo />,
                handle: { crumb: () => "Details" },
                children: [
                  {
                    index: true,
                    element: <Navigate to="overview" replace />,
                  },
                  { path: "overview", element: <HouseholdOverview /> },
                  { path: "portfolio", element: <HouseholdPortfolio /> },
                  {
                    path: "cash-billing",
                    element: <Outlet />,
                    children: [
                      { index: true, element: <HouseholdCashBilling /> },
                      {
                        path: ":accountId",
                        element: <HouseholdAccountCashSettings />,
                      },
                    ],
                  },
                  {
                    path: "model-trading",
                    element: <HouseholdModelTrading />,
                  },
                  { path: "tax-rmd", element: <HouseholdTax /> },
                  { path: "profile", element: <HouseholdProfile /> },
                  { path: "todos", element: <HouseholdTasks /> },
                  {
                    path: "transactions",
                    element: <HouseholdTransactions />,
                  },
                ],
              },
            ],
          },
          {
            path: "accounts",
            element: (
              <ProtectedRoute>
                <Outlet />
              </ProtectedRoute>
            ),
            handle: { crumb: () => "Accounts" },
            children: [
              { index: true, element: <Accounts /> },
              {
                path: "mapping",
                element: <AccountsHouseholdsMapping />,
                handle: { crumb: () => "Assignment" },
              },
              {
                path: ":accountId",
                element: <AccountInfo />,
                handle: { crumb: () => "Details" },
                children: [
                  {
                    index: true,
                    element: <Navigate to="overview" replace />,
                  },
                  { path: "overview", element: <AccountOverview /> },
                  { path: "portfolio", element: <AccountPortfolio /> },
                  {
                    path: "cash-billing",
                    element: <AccountCashBillingSettings />,
                  },
                  {
                    path: "model-trading",
                    element: <AccountModelTrading />,
                  },
                  { path: "tax-rmd", element: <AccountTax /> },
                  { path: "profile", element: <AccountProfile /> },
                  {
                    path: "transactions",
                    element: <AccountTransactions />,
                  },
                ],
              },
            ],
          },
          {
            path: "contacts",
            element: (
              <ProtectedRoute>
                <Outlet />
              </ProtectedRoute>
            ),
            handle: { crumb: () => "Contacts" },
            children: [
              { index: true, element: <Contacts /> },
              {
                path: "mapping",
                element: <ContactMapping />,
                handle: { crumb: () => "Data Connections" },
              },
              {
                path: "new",
                element: <CreateContact />,
                handle: { crumb: () => "New" },
              },
              {
                path: ":contactId",
                element: <ContactInfo />,
                handle: { crumb: () => "Details" },
              },
            ],
          },
        ],
      },
      {
        path: "/profile",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        handle: { crumb: () => "Profile" },
        children: [
          { index: true, element: <UserProfile /> },
          {
            path: "create",
            element: <CreateUser />,
            handle: { crumb: () => "Create User" },
          },
        ],
      },
      {
        path: "/firm/*",
        element: (
          <ProtectedRoute>
            <h1>Firm</h1>
            <Outlet />
          </ProtectedRoute>
        ),
        handle: { crumb: () => "Firm" },
        children: [
          { index: true, element: <Navigate to="info" replace /> },
          {
            path: "info",
            element: <FirmInfo />,
            handle: { crumb: () => "Settings" },
          },
          {
            path: "security-category-asset-class",
            element: <CategoryAssetClass />,
            handle: { crumb: () => "Category to Asset Class Mapping" },
          },
          {
            path: "asset-class-risk-allocations",
            element: <RiskAllocationsAssetClass />,
            handle: { crumb: () => "Asset Class to Risk Mapping" },
          },
          {
            path: "securities/asset-categories",
            element: <SecurityAssetCategoryOverrides />,
            handle: { crumb: () => "Security Category Mapping" },
          },
        ],
      },
      {
        path: "/billing",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        handle: { crumb: () => "Billing" },
        children: [
          { index: true, element: <Navigate to="summary" replace /> },
          {
            path: "summary",
            element: <BillingSummary />,
            handle: { crumb: () => "Details" },
          },
          {
            path: "reports",
            element: <Outlet />,
            handle: { crumb: () => "Billing Reports" },
            children: [
              { index: true, element: <BillingReportsTable /> },
              {
                path: "run",
                element: <CreateBillingReport />,
                handle: { crumb: () => "Run Billing" },
              },
              {
                path: ":reportId",
                element: <BillingReportInfo />,
                handle: { crumb: () => "Details" },
              },
            ],
          },
          {
            path: "fee-structure",
            element: <Outlet />,
            handle: { crumb: () => "Fee Structures" },
            children: [
              { index: true, element: <FeeStructureTable /> },
              {
                path: ":feeStructureId",
                element: <FeeStructureInfo />,
                handle: { crumb: () => "Detail" },
              },
            ],
          },
          {
            path: "billing-minimums",
            element: <Outlet />,
            handle: { crumb: () => "Billing Minimums" },
            children: [
              { index: true, element: <BillingMinimumTable /> },
              {
                path: ":billingMinimumId",
                element: <BillingMinimumInfo />,
                handle: { crumb: () => "Detail" },
              },
            ],
          },
          {
            path: "billing-splits",
            element: <Outlet />,
            handle: { crumb: () => "Fee Splits" },
            children: [
              { index: true, element: <BillingSplitsTable /> },
              {
                path: ":billingSplitId",
                element: <BillingSplitsInfo />,
                handle: { crumb: () => "Detail" },
              },
            ],
          },
          {
            path: "asset-exclusion",
            element: <Outlet />,
            handle: { crumb: () => "Asset Exclusion" },
            children: [
              { index: true, element: <AssetExclusionTable /> },
              {
                path: ":assetExclusionId",
                element: <AssetExclusionInfo />,
                handle: { crumb: () => "Detail" },
              },
            ],
          },
        ],
      },
      {
        path: "/reports",
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        handle: { crumb: () => "Reports" },
        children: [
          { index: true, element: <Navigate to="batches" replace /> },
          {
            path: "batches",
            element: <ReportBatches />,
            handle: { crumb: () => "Batches" },
          },
          {
            path: "new",
            element: <CreateReport />,
            handle: { crumb: () => "Create" },
          },
          {
            path: "templates",
            handle: { crumb: () => "Templates" },
            children: [
              { index: true, element: <ReportTemplates /> },
              {
                path: ":templateId",
                element: <ReportTemplateInfo />,
                handle: { crumb: () => "Create" },
              },
            ],
          },
          {
            path: "exports",
            handle: { crumb: () => "Data Exports" },
            children: [
              { index: true, element: <DataExports /> },
              {
                path: "balances",
                element: <ExportAccountBalances />,
                handle: { crumb: () => "Export Balances" },
              },
              {
                path: "positions",
                element: <ExportAccountPositions />,
                handle: { crumb: () => "Export Positions" },
              },
            ],
          },
          {
            path: ":reportBatchId",
            handle: { crumb: () => "Report" },
            children: [
              { index: true, element: <ReportBatchInfo /> },
              {
                path: "new",
                element: <CreateReport />,
                handle: { crumb: () => "Create" },
              },
              {
                path: ":reportId",
                element: <ReportInfo />,
                handle: { crumb: () => "Download" },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/integrations/login/:service",
    element: <IntegrationCredentials />,
  },
];

export default routes;
