import { useContext, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import TaxPerformancePeriodSelector from "../../components/TaxPerformancePeriodSelector";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatCurrencyComponent, formatPercent } from "../../lib/numbers";
import { AccountContext } from "./AccountInfo";
import AccountTaxTable from "./AccountTaxTable";

const AccountTax = () => {
  const { account } = useContext(AccountContext);
  const { accountId } = useParams();
  const [customTaxPeriodParams, setCustomTaxPeriodParams] = useState("");

  const {
    data: dataAccountTaxPerformance,
    isPending: isPendingAccountTaxPerformance,
    isError: isErrorAccountTaxPerformance,
  } = useAuthenticatedFetch<
    UnpackResponse<AccountsController["getAccountPerformance"]>
  >(`/accounts/${accountId}/performance?${customTaxPeriodParams}`);
  const taxPerformance = dataAccountTaxPerformance?.data;

  return typeof account === "undefined" ||
    typeof taxPerformance === "undefined" ? (
    <Loading />
  ) : (
    <>
      <Row className="mb-2">
        <Col xl={2} md={2}>
          <TaxPerformancePeriodSelector
            inceptionDate={account.inceptionDate}
            setCustomPeriodParams={setCustomTaxPeriodParams}
          />
        </Col>
        {isPendingAccountTaxPerformance ? (
          <Loading message="Tax Details" />
        ) : isErrorAccountTaxPerformance ? (
          <Alert variant="danger">Failed to load tax details</Alert>
        ) : (
          <>
            <Col md={4}>
              <Row>
                <Col as="dt" md={7} sm={8} xs={8}>
                  Total Taxable Div./Int.
                </Col>
                <Col as="dd" md={5} sm={4} xs={4}>
                  {formatCurrencyComponent(taxPerformance.income, 2)} (
                  {formatPercent(
                    taxPerformance.balance === 0
                      ? 0
                      : taxPerformance.income / taxPerformance.balance,
                    2,
                  )}
                  )
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={7} sm={8} xs={8}>
                  TLH Opportunities
                </Col>
                <Col as="dd" md={5} sm={4} xs={4}>
                  {formatCurrencyComponent(account.unrealizedLoss ?? 0, 2)} (
                  {formatPercent(
                    taxPerformance.balance === 0
                      ? 0
                      : (account.unrealizedLoss ?? 0) / taxPerformance.balance,
                    2,
                  )}
                  )
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col as="dt" md={8} sm={8} xs={8}>
                  Taxable Realized LT G/L
                </Col>
                <Col as="dd">
                  {formatCurrencyComponent(
                    taxPerformance.realizedGainLossLT,
                    2,
                  )}
                </Col>
              </Row>
              <Row>
                <Col as="dt" sm={8} xs={8}>
                  Taxable Realized ST G/L
                </Col>
                <Col as="dd">
                  {formatCurrencyComponent(
                    taxPerformance.realizedGainLossST,
                    2,
                  )}
                </Col>
              </Row>
              <Row>
                <Col as="dt" sm={8} xs={8}>
                  Total Taxable Realized G/L
                </Col>
                <Col as="dd">
                  {formatCurrencyComponent(
                    taxPerformance.realizedGainLossLT +
                      taxPerformance.realizedGainLossST,
                    2,
                  )}
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col>
          <h3>Account Holdings</h3>
          <AccountTaxTable customTaxPeriodParams={customTaxPeriodParams} />
        </Col>
      </Row>
    </>
  );
};

export default AccountTax;
