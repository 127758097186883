import _ from "lodash";
import { useContext, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import type { AccountWithMetrics } from "../../../../api/src/accounts/accounts.service";
import type { BillingController } from "../../../../api/src/billing/billing.controller";
import type { CashSettingsController } from "../../../../api/src/cash-settings/cash-settings.controller";
import type { AccountCashSetting } from "../../../../api/src/cash-settings/lib";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import { useAuthenticatedFetch } from "../../lib/api";
import { displayAccountName } from "../../lib/display";
import HouseholdCashBillingTable, {
  HouseholdCashBillingRow,
} from "./HouseholdCashBillingTable";
import { HouseholdContext } from "./HouseholdInfo";
import { HouseholdWithAccounts } from "../../../../api/src/households/households.service";

const HouseholdCashBillingRebalance = ({
  household: propHousehold,
}: {
  household?: HouseholdWithAccounts;
}) => {
  const { household: contextHousehold } = useContext(HouseholdContext);
  const household = propHousehold ?? contextHousehold;

  const { data: dataFeeStructures } = useAuthenticatedFetch<
    UnpackResponse<BillingController["getAllFeeStructures"]>
  >("/billing/fee-structures");
  const feeStructures = dataFeeStructures?.data;

  const { isPending: isPendingCashSettings, data: dataCashSettings } =
    useAuthenticatedFetch<
      UnpackResponse<CashSettingsController["getAllAccountSettings"]>
    >("/cash-settings/accounts");
  const cashSettings = dataCashSettings?.data;

  const combinedCashSettingsAccountsHouseholds = useMemo(() => {
    const cashSettingsMap: Record<number, AccountCashSetting> = _.keyBy(
      cashSettings ?? [],
      "accountId",
    );
    const accountsMap: Record<number, AccountWithMetrics> = _.keyBy(
      household?.accounts ?? [],
      "id",
    );
    const feeStructuresMap = _.keyBy(feeStructures ?? [], "feeStructureId");

    return Object.entries(accountsMap).map(([accountId, account]) => {
      const cashSetting = cashSettingsMap[parseInt(accountId)];

      const feeStructureId =
        account.feeStructureId ?? household?.feeStructureId;

      // Catch cases where a relocated account is no longer active
      const feeLocationAccountId =
        typeof account.feeLocationAccountId === "undefined" ||
        typeof accountsMap[account.feeLocationAccountId] === "undefined"
          ? undefined
          : account.feeLocationAccountId;

      const result: HouseholdCashBillingRow = {
        ...account,
        ...(typeof cashSetting === "undefined" ? {} : cashSetting),
        cashSettingId: account.cashSettingId,
        feeStructureId: feeStructureId,
        feeStructureName:
          typeof feeStructureId === "undefined"
            ? undefined
            : feeStructuresMap[feeStructureId]?.name,
        isFeeStructureInherited:
          typeof feeStructureId !== "undefined" &&
          typeof account.feeStructureId === "undefined",
        feeLocation: account.feeLocation,
        feeLocationAccountId,
        feeLocationAccountName:
          account.feeLocation !== "A" ||
          typeof feeLocationAccountId === "undefined"
            ? undefined
            : displayAccountName(
                accountsMap[feeLocationAccountId].displayName,
                accountsMap[feeLocationAccountId].displayNumber,
              ),
      };

      return result;
    });
  }, [cashSettings, feeStructures, household]);

  return (
    <Row className="mb-3">
      <Col>
        <h3>Account Cash Settings</h3>
        {isPendingCashSettings ? (
          <Loading message="Accounts" />
        ) : (
          <HouseholdCashBillingTable
            accounts={combinedCashSettingsAccountsHouseholds}
          />
        )}
      </Col>
    </Row>
  );
};

export default HouseholdCashBillingRebalance;
