import { useCallback } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../components/SubmitButton";
import { useDeleteBenchmark } from "./lib";

const BenchmarkDeleteDialog = ({
  showModal,
  setShowModal,
  deleteBenchmarkId,
  setDeleteBenchmarkId,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteBenchmarkId: number;
  setDeleteBenchmarkId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const navigate = useNavigate();

  const handleModalClose = useCallback(() => {
    setDeleteBenchmarkId(0);
    setShowModal(false);
  }, [setDeleteBenchmarkId, setShowModal]);

  const deleteBenchmark = useDeleteBenchmark(deleteBenchmarkId);

  const handleDeleteBenchmark = useCallback(async () => {
    await deleteBenchmark.mutateAsync();
    setShowModal(false);
    navigate("/models/benchmarks");
  }, [deleteBenchmark, navigate, setShowModal]);

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete Benchmark</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="m-2">
          This benchmark will be un-attached from all models using it. Are you
          sure you would like to delete this benchmark?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          variant="danger"
          label="Delete"
          onClick={handleDeleteBenchmark}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default BenchmarkDeleteDialog;
