import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useState } from "react";
import { ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import type { Household } from "../../../../api/src/households-base/households-base.service";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import type {
  EditableTask,
  EditableWorkflow,
} from "../../../../api/src/tasks/tasks.service";
import { NotificationContext } from "../../Notifications";
import ActionButton from "../../components/ActionButton";
import Avatar from "../../components/Avatar";
import CreateTaskPane from "../../components/CreateTaskPane";
import CreateWorkflowPane from "../../components/CreateWorkflowPane";
import FormFieldError from "../../components/FormFieldError";
import SubmitButton from "../../components/SubmitButton";
import SummaryPill from "../../components/SummaryPill";
import { useAuthenticatedMutationAsync } from "../../lib/api";
import { formatCurrency } from "../../lib/numbers";
import { deserializeTask } from "../../tasks/lib";
import HouseholdArchiveDialog from "./HouseholdArchiveDialog";
import { HouseholdContext } from "./HouseholdInfo";
import HouseholdNotesButton from "./HouseholdNotesButton";
import { useCreateHouseholdWorkflow, useUnarchiveHousehold } from "./lib";

const schema: yup.ObjectSchema<{ name: string }> = yup.object({
  name: yup.string().required().label("Name"),
});

const HouseholdNameField = ({
  household,
  householdId,
}: {
  household?: Household;
  householdId?: string;
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<{ name: string }>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({ name: household?.name });
  }, [household?.name, reset]);

  const updateHouseholdName = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<HouseholdsController["updateName"]>>
  >(`/households/${householdId}/name`, async (name: string) => ({
    method: "PUT",
    body: JSON.stringify({ name }),
  }));

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);

  const [enableNameField, setEnableNameField] = useState(false);

  const onSubmit = useMutation({
    mutationFn: async (name: string) => {
      try {
        await updateHouseholdName(name);
        setEnableNameField(false);
        queryClient.setQueryData(
          ["households", parseInt(householdId ?? ""), { includeCRM: true }],
          {
            data: { ...household, name },
          },
        );
        reset({ name });
        notificationContext.pushNotification({
          id: `household-${householdId}-name`,
          header: "Household Name Updated",
          body: `${name} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save household name", err);
        notificationContext.pushNotification({
          id: `household-${householdId}-name`,
          header: "Failed to Update Household Name",
          body: `${name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  const onSaveName = useCallback(
    (ev: React.MouseEvent) => {
      if (!enableNameField) {
        ev.preventDefault();
        setEnableNameField(true);
      }
    },
    [enableNameField],
  );

  // Focus the name field on toggle. This does not work if we call
  // it before the re-render that displays the input field, so we
  // have to watch it using the effect hook.
  useEffect(() => {
    if (enableNameField) {
      setFocus("name");
    }
  }, [enableNameField, setFocus]);

  return (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data.name))}>
      <div className="d-flex align-items-center">
        <div style={{ maxWidth: 360 }}>
          {enableNameField ? null : (
            <span className="fs-3 fw-semibold lh-1 text-wrap">
              {getValues("name")}
            </span>
          )}
          <Form.Group controlId="form-name" hidden={!enableNameField}>
            <Form.Control {...register("name")} type="text" size="lg" />
            <FormFieldError field={errors.name} />
          </Form.Group>
        </div>
        <SubmitButton
          variant="icon"
          icon={enableNameField ? "/icons/save.svg" : "/icons/edit.svg"}
          label={enableNameField ? "Save" : "Edit"}
          type={enableNameField ? "submit" : "button"}
          onClick={onSaveName}
          isSubmitting={isSubmitting}
          className="ms-2 me-3"
        />
      </div>
    </Form>
  );
};

const HouseholdHeader = () => {
  const { household } = useContext(HouseholdContext);
  const { householdId: householdIdStr } = useParams();
  const householdId = parseInt(householdIdStr ?? "");
  const linkedToWealthbox =
    typeof household?.idExternal.wealthbox !== "undefined";
  const linkedToRedtail = typeof household?.idExternal.redtail !== "undefined";
  const linkedToCrm = linkedToWealthbox || linkedToRedtail;

  const queryClient = useQueryClient();

  const [isShowCreateTask, setIsShowCreateTask] = useState(false);
  const showCreateTask = useCallback(() => {
    setIsShowCreateTask(!isShowCreateTask);
  }, [isShowCreateTask]);

  const createTask = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<HouseholdsController["createTask"]>>
  >(`/households/${householdId}/tasks`, async (data: EditableTask) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const onCreateTask = useCallback(
    async (data: EditableTask) => {
      const body = await createTask(data);
      if (body.data == null) {
        return null;
      } else {
        queryClient.setQueryData(
          [
            "households",
            householdId,
            { includeAccounts: true, includeCRM: true },
          ],
          {
            ...household,
            tasks: [...(household?.tasks ?? []), deserializeTask(body.data)],
          },
        );

        return deserializeTask(body.data);
      }
    },
    [createTask, queryClient, householdId, household],
  );

  const [isShowCreateWorkflow, setIsShowCreateWorkflow] = useState(false);
  const showCreateWorkflow = useCallback(() => {
    setIsShowCreateWorkflow(!isShowCreateWorkflow);
  }, [isShowCreateWorkflow]);

  const createWorkflow = useCreateHouseholdWorkflow(householdId);

  const onCreateWorkflow = useCallback(
    async (data: EditableWorkflow) => {
      const workflow = await createWorkflow.mutateAsync(data);

      if (workflow === null) {
        return null;
      } else {
        queryClient.setQueryData(
          [
            "households",
            householdId,
            { includeAccounts: true, includeCRM: true },
          ],
          {
            ...household,
            workflows: [...(household?.workflows ?? []), workflow],
          },
        );

        return workflow;
      }
    },
    [createWorkflow, queryClient, householdId, household],
  );

  const [showModal, setShowModal] = useState(false);
  const handleArchiveHouseholdClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const { mutateAsync: unarchiveHousehold } =
    useUnarchiveHousehold(householdId);

  const notificationContext = useContext(NotificationContext);

  const handleUnarchiveHousehold = useCallback(async () => {
    try {
      await unarchiveHousehold();

      setShowModal(false);
      notificationContext.pushNotification({
        id: `household-${householdId}`,
        header: "Household Unarchived",
        body: `${household?.name} unarchived`,
        variant: "success",
      });
    } catch (ex) {
      console.error("Failed to unarchive household", ex);
      notificationContext.pushNotification({
        id: `household-${householdId}`,
        header: "Failed to Unarchive Household",
        body: `${household?.name} was not unarchived`,
        variant: "danger",
      });
    }
  }, [household?.name, householdId, notificationContext, unarchiveHousehold]);

  return (
    <>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3 justify-content-end gap-2">
            <ActionButton
              variant="secondary"
              label="Create Report"
              as={Link}
              icon="/icons/bar-chart.svg"
              to={`/reports/batches/new?type=custom&household_id=${householdId}`}
            />
            {!linkedToCrm ? null : (
              <>
                <ActionButton
                  variant="secondary"
                  icon="/icons/tasks.svg"
                  label="Create Task"
                  type="button"
                  onClick={showCreateTask}
                />
                <ActionButton
                  variant="secondary"
                  icon="/icons/flowchart.svg"
                  label="Create Workflow"
                  type="button"
                  onClick={showCreateWorkflow}
                />
                <HouseholdNotesButton householdId={householdId} />
              </>
            )}
            {household?.isActive ? (
              <ActionButton
                type="button"
                label="Archive"
                icon="/icons/trash.svg"
                onClick={handleArchiveHouseholdClick}
              />
            ) : (
              <ActionButton
                type="button"
                label="Unarchive"
                icon="/icons/trash.svg"
                onClick={handleUnarchiveHousehold}
              />
            )}
          </ButtonToolbar>
        </Col>
        <HouseholdArchiveDialog
          showModal={showModal}
          setShowModal={setShowModal}
          archiveHouseholdId={householdId}
          archiveHouseholdName={household?.name ?? ""}
        />
      </Row>
      <Row>
        <Col
          xs={12}
          className="d-flex align-items-center justify-content-between flex-wrap"
        >
          <div className="d-flex align-items-center flex-wrap mb-3">
            {!household?.image ? null : (
              <Avatar
                src={household?.image}
                alt="household avatar"
                className="me-2"
                width={64}
                height={64}
              />
            )}
            <HouseholdNameField
              household={household}
              householdId={householdIdStr}
            />
            {!linkedToWealthbox ? null : (
              <a
                href={`${process.env.REACT_APP_WEALTHBOX_APP_ROOT}/contacts/${household.idExternal.wealthbox}`}
                rel="noreferrer"
                target="_blank"
                className="me-2"
              >
                View in Wealthbox
              </a>
            )}
          </div>
          <div>
            <SummaryPill
              label="AUM"
              value={formatCurrency(household?.householdBalance ?? 0)}
              size="sm"
              className="me-3 mb-3"
            />
            <SummaryPill
              label="Cash"
              value={`${formatCurrency(household?.cashBalance ?? 0)}`}
              size="sm"
            />
          </div>
        </Col>
      </Row>
      <CreateTaskPane
        show={isShowCreateTask}
        setShow={setIsShowCreateTask}
        onCreate={onCreateTask}
      />
      <CreateWorkflowPane
        show={isShowCreateWorkflow}
        setShow={setIsShowCreateWorkflow}
        onCreate={onCreateWorkflow}
      />
    </>
  );
};

export default HouseholdHeader;
