import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import type { ModelRequestSecurities } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import AssetAllocationBarChart, {
  AssetAllocationSeries,
} from "../components/AssetAllocationBarChart";
import { useDebounce } from "../lib/forms";
import { useQueryModelAssetAllocation } from "./lib";

const ModelAssetAllocationChart = ({
  model,
  isLoading = false,
}: {
  model: Partial<ModelRequestSecurities>;
  isLoading: boolean;
}) => {
  const {
    isPending: isPendingAssetAllocation,
    isError: isErrorAssetAllocation,
    data: dataAssetAllocation,
    refetch,
  } = useQueryModelAssetAllocation(model, { enabled: false });

  const data: AssetAllocationSeries[] = [
    {
      label: "",
      data: dataAssetAllocation ?? [],
    } as AssetAllocationSeries,
  ];

  const [debouncedRefetch] = useDebounce(
    async () => {
      await refetch();
    },
    isPendingAssetAllocation ? 0 : 1000,
  );

  useEffect(() => {
    if (
      !isLoading &&
      model &&
      ((model.primarySecurities?.length ?? 0) > 0 ||
        (model.sleeves?.length ?? 0) > 0 ||
        (model.securityGroups?.length ?? 0) > 0)
    ) {
      debouncedRefetch();
    }
    // debounceRefetch is a new instance every timer cycle;
    // Using it as a dependency causes this hook to refire repeatedly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, isLoading]);

  return isLoading || isPendingAssetAllocation ? (
    <Loading message="Asset Allocation" />
  ) : isErrorAssetAllocation ? (
    <Alert variant="danger">Failed to load asset allocation</Alert>
  ) : (dataAssetAllocation.length ?? 0) === 0 ? (
    <Alert variant="info">No Asset Allocation</Alert>
  ) : (
    <>
      <h4>Asset Allocation</h4>
      <AssetAllocationBarChart data={data} />
    </>
  );
};

export default ModelAssetAllocationChart;
