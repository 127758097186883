import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { Alert } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import type {
  AccountHoldingResponse,
  AccountWithMetrics,
} from "../../../../api/src/accounts/accounts.service";
import Loading from "../../Loading";
import SecurityLink from "../../components/SecurityLink";
import { Table, useTable } from "../../components/Table/Table";
import { displayAccountName } from "../../lib/display";
import { formatCurrencyComponent, formatPercent } from "../../lib/numbers";
import { useQueryHouseholdHoldings } from "./lib";

type AccountHoldingRow = {
  accountName?: string;
  accountType?: string;
  accountId: number;
  symbol: string;
  income: number;
  incomeYield: number;
} & Pick<
  AccountHoldingResponse,
  "value" | "security" | "unrealizedGainLoss" | "realizedGainLoss"
>;

function HouseholdTaxTable({
  accounts,
  customTaxPeriodParams,
}: {
  accounts?: AccountWithMetrics[];
  customTaxPeriodParams: string;
}) {
  const { householdId: householdIdStr } = useParams();
  const householdId = parseInt(householdIdStr ?? "-1");

  const columnHelper = useMemo(
    () => createColumnHelper<AccountHoldingRow>(),
    [],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("accountName", {
        header: "Account Name",
        cell: (info) => (
          <span className="fw-bold">
            <Link to={`/clients/accounts/${info.row.original.accountId}`}>
              {info.getValue() ?? ""}
            </Link>
          </span>
        ),
        size: 250,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("accountType", {
        header: "Type",
        cell: (info) => (
          <SecurityLink
            symbol={info.row.original.symbol}
            description={info.row.original.security?.description}
          />
        ),
        aggregatedCell: (info) => (
          <span className="fw-bold">{info.getValue()}</span>
        ),
        size: 180,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("value", {
        header: () => "Value",
        cell: (info) => formatCurrencyComponent(info.getValue(), 2),
        aggregatedCell: (info) => (
          <span className="fw-bold">
            {formatCurrencyComponent(info.getValue(), 2)}
          </span>
        ),
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("income", {
        header: () => "Total Div./Int. (% Yield)",
        cell: (info) =>
          `${formatCurrencyComponent(info.getValue(), 2)} (${formatPercent(
            info.row.original.incomeYield,
            2,
          )})`,
        aggregatedCell: (info) => (
          <span className="fw-bold">
            {formatCurrencyComponent(info.getValue(), 2)} (
            {formatPercent(
              (info.row.getGroupingValue("value") as number) -
                info.getValue() ===
                0
                ? 0
                : info.getValue() /
                    ((info.row.getGroupingValue("value") as number) -
                      info.getValue()),
              2,
            )}
            )
          </span>
        ),
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.realizedGainLoss ?? 0, {
        id: "realizedGainLoss",
        header: () => "Realized Gain/Loss",
        cell: (info) => formatCurrencyComponent(info.getValue(), 2),
        aggregatedCell: (info) => (
          <span className="fw-bold">
            {formatCurrencyComponent(info.getValue(), 2)}
          </span>
        ),
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("unrealizedGainLoss", {
        cell: (info) => formatCurrencyComponent(info.getValue(), 2),
        header: () => "Unrealized Gain/Loss",
        aggregatedCell: (info) => (
          <span className="fw-bold">
            {formatCurrencyComponent(info.getValue(), 2)}
          </span>
        ),
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
    ],
    [columnHelper],
  );

  const {
    data: dataHoldings,
    isPending: isPendingHoldings,
    isError: isErrorHoldings,
  } = useQueryHouseholdHoldings(householdId, {
    customTaxPeriodParams,
    enabled: typeof householdIdStr !== "undefined",
  });

  const taxableAccountIds = useMemo(
    () => (accounts ?? []).map((account) => account.id),
    [accounts],
  );

  const holdings: AccountHoldingRow[] = useMemo(
    () =>
      (dataHoldings ?? [])
        .filter((account) => taxableAccountIds.includes(account.id))
        .flatMap((account) => {
          const accountId = account.id;
          const accountComplete = accounts?.find(
            (account) => account.id === accountId,
          );

          return account.holdings.map((holding) => ({
            ...holding,
            symbol: holding.security?.identifier ?? "",
            accountId,
            accountName: displayAccountName(
              accountComplete?.displayName,
              accountComplete?.displayNumber,
            ),
            accountType: accountComplete?.isTaxDeferred
              ? "Tax-Deferred"
              : accountComplete?.isTaxable
                ? "Taxable"
                : "Tax-Free",
            income: holding.income,
            incomeYield:
              holding.value - holding.income === 0
                ? 0
                : holding.income / (holding.value - holding.income),
          }));
        }),
    [accounts, dataHoldings, taxableAccountIds],
  );

  const { table } = useTable({
    columns,
    data: holdings,
    initialState: {
      sorting: [{ id: "accountName", desc: false }],
      grouping: ["accountName"],
      pagination: {
        pageSize: 9999,
      },
    },
    manualPagination: true,
    getRowId: (row) => `${row.accountId}-${row.symbol}`,
  });

  return isPendingHoldings ? (
    <Loading message="Holdings" />
  ) : isErrorHoldings ? (
    <Alert variant="danger">Failed to load holdings</Alert>
  ) : holdings.length <= 0 ? (
    <Alert>No holdings found</Alert>
  ) : (
    <Table table={table} disablePagination isDisableGrouping isFixedGrouping />
  );
}

export default HouseholdTaxTable;
