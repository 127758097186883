import { useCallback, useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import SubmitButton from "../../components/SubmitButton";
import { NotificationContext } from "../../Notifications";
import { useArchiveAccount } from "./lib";

const AccountArchiveDialog = ({
  showModal,
  setShowModal,
  archiveAccountId,
  archiveAccountName,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  archiveAccountId: number;
  archiveAccountName: string;
}) => {
  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const [archiveEnabled, setArchiveEnabled] = useState(false);

  const checkArchiveEnabled = (value: string) => {
    setArchiveEnabled(value === archiveAccountName);
  };

  const { mutateAsync: archiveAccount } = useArchiveAccount(archiveAccountId);

  const notificationContext = useContext(NotificationContext);

  const handleArchiveAccount = useCallback(async () => {
    try {
      await archiveAccount();

      setShowModal(false);
      notificationContext.pushNotification({
        id: `account-${archiveAccountId}`,
        header: "Account Archived",
        body: `${archiveAccountName} archived`,
        variant: "success",
      });
    } catch (ex) {
      console.error("Failed to archive account", ex);
      notificationContext.pushNotification({
        id: `account-${archiveAccountId}`,
        header: "Failed to Archive Account",
        body: `${archiveAccountName} was not archived`,
        variant: "danger",
      });
    }
  }, [
    archiveAccount,
    archiveAccountId,
    archiveAccountName,
    notificationContext,
    setShowModal,
  ]);

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Archive Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Are you sure you would like to archive {archiveAccountName}?
        </Form.Label>
        <Form.Control
          style={{ width: "90%" }}
          type="text"
          placeholder="Enter account name"
          onChange={(e) => checkArchiveEnabled(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          variant="danger"
          label="Confirm"
          disabled={!archiveEnabled}
          onClick={handleArchiveAccount}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default AccountArchiveDialog;
