import { useQueryClient } from "@tanstack/react-query";
import {
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Alert, ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import type { AccountWithMetrics } from "../../../../api/src/accounts/accounts.service";
import type { Model } from "../../../../api/src/models/models.service";
import Loading from "../../Loading";
import ActionButton from "../../components/ActionButton";
import { useErrorMessage } from "../../components/FormError";
import IndeterminateCheckbox from "../../components/IndeterminateCheckbox";
import ModelAssignDialog from "../../components/ModelAssignDialog";
import TabContainerWithTabs from "../../components/TabContainer";
import { Table, useTable } from "../../components/Table/Table";
import TablePager from "../../components/Table/TablePager";
import { StatusFilter } from "../../components/Table/filters";
import {
  onColumnFiltersChange,
  onPaginationChange,
  onSortingChange,
  useTableSettings,
} from "../../components/Table/tableSettings";
import { displayAccountName } from "../../lib/display";
import { useDebounce } from "../../lib/forms";
import { formatCurrency, formatPercent } from "../../lib/numbers";
import { useQueryModels } from "../../models/lib";
import { getRiskAllocationDisplay } from "../../rebalances/lib";
import ManageNav from "../ManageNav";
import { useQueryHouseholds } from "../household/lib";
import {
  displayAccountStatus,
  useQueryAccounts,
  useQueryExportAccounts,
  useUpdateAccountsModel,
} from "./lib";

type AccountRow = Pick<
  AccountWithMetrics,
  | "id"
  | "displayName"
  | "displayNumber"
  | "householdId"
  | "accountBalance"
  | "cashBalance"
  | "cashBalanceWeight"
  | "riskAllocation"
  | "type"
  | "isActive"
  | "inceptionDate"
> & {
  householdName?: string;
  model?: Model;
  latestRebalanceId: number | null;
  latestRebalanceDate: Date | null;
};

const Accounts = () => {
  const columnHelper = useMemo(() => createColumnHelper<AccountRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor(
        (row) => displayAccountName(row.displayName, row.displayNumber),
        {
          id: "displayName",
          cell: (info) => (
            <Link to={`/clients/accounts/${info.row.original.id}`}>
              {info.getValue()}
            </Link>
          ),
          header: () => "Name",
          minSize: 225,
        },
      ),
      columnHelper.accessor((row) => row.householdName ?? "", {
        id: "householdName",
        cell: (info) =>
          typeof info.getValue() === "undefined" ? null : (
            <Link to={`/clients/households/${info.row.original.householdId}`}>
              {info.getValue()}
            </Link>
          ),
        header: () => "Household",
        minSize: 225,
      }),
      columnHelper.accessor((row) => row.accountBalance ?? 0, {
        id: "accountBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("type", {
        header: () => "Type",
      }),
      columnHelper.accessor((row) => row.riskAllocation?.current, {
        id: "riskAllocation",
        cell: (info) => getRiskAllocationDisplay(info.getValue()),
        header: () => "Allocation",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Cash",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.cashBalanceWeight ?? 0, {
        id: "cashBalanceWeight",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Cash Weight",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.model?.name ?? "", {
        id: "model",
        cell: (info) =>
          info.getValue() === "" ? (
            ""
          ) : (
            <Link to={`/models/models/${info.row.original.model?.id}`}>
              {info.getValue()}
            </Link>
          ),
        header: () => "Model",
        minSize: 225,
      }),
      columnHelper.accessor("latestRebalanceDate", {
        cell: (info) => {
          const val = info.getValue();
          return typeof val === "undefined" || val === null ? (
            ""
          ) : (
            <Link to={`/rebalances/${info.row.original.latestRebalanceId}`}>
              {val.toLocaleDateString()}
            </Link>
          );
        },
        header: () => "Pending Rebalance",
        enableColumnFilter: false,
        enableSorting: false,
      }),
      columnHelper.accessor("inceptionDate", {
        cell: (info) => info.getValue().toLocaleDateString(),
        header: () => "Opening Date",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("isActive", {
        cell: (info) => displayAccountStatus(info.row.original),
        header: () => "Status",
        meta: {
          filterComponent: StatusFilter,
        },
      }),
    ],
    [columnHelper],
  );

  const [params] = useSearchParams();
  const filteredIds = params.getAll("id").map((id) => parseInt(id));

  const [tableSettings, setTableSettings] = useTableSettings("accounts", {
    sorting: [{ id: "displayName", desc: false }],
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableSettings.filters,
  );
  const [debouncedFilters, setDebouncedFilters] = useState(columnFilters);
  const [sorting, setSorting] = useState<SortingState>(tableSettings.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableSettings.pageSize,
    pageIndex: 0,
  });

  // Store columnFilters in a ref so we can access its current value in the debounced function
  const columnFiltersRef = useRef(columnFilters);
  columnFiltersRef.current = columnFilters;

  // Debounce filter changes
  const [debouncedSetFilters] = useDebounce(async () => {
    setDebouncedFilters(columnFiltersRef.current);
  }, 500); // delay in ms

  useEffect(() => {
    debouncedSetFilters();
  }, [columnFilters, debouncedSetFilters]);

  const { isPending, data: accounts } = useQueryAccounts(
    {
      includeBalances: true,
      includeInactive: true,
      filters: debouncedFilters,
      sorting,
      pageSize: pagination.pageSize,
      pageIndex: pagination.pageIndex,
    },
    filteredIds.length <= 0 ? undefined : filteredIds,
  );

  const { isPending: isPendingHouseholds, data: dataHouseholds } =
    useQueryHouseholds();

  const householdsMap = useMemo(
    () => _.keyBy(dataHouseholds?.data ?? [], "id"),
    [dataHouseholds?.data],
  );

  const {
    isPending: isPendingModels,
    isError: isErrorModels,
    data: dataModels,
  } = useQueryModels();

  const accountssWithModel = useMemo(
    () =>
      (accounts?.data ?? []).map((account) => ({
        ...account,
        model: (dataModels ?? []).find(
          (model) => model.id === account.assignedModelId,
        ),
      })),
    [accounts?.data, dataModels],
  );

  const { table, rowSelection, setRowSelection } = useTable({
    columns,
    data: accountssWithModel.map((account) => {
      const matchedHousehold =
        typeof account.householdId === "undefined"
          ? undefined
          : householdsMap[account.householdId];

      return {
        ...account,
        householdName: matchedHousehold?.name,
        latestRebalanceId: matchedHousehold?.latestRebalanceId ?? null,
        latestRebalanceDate: matchedHousehold?.latestRebalanceDate ?? null,
      };
    }),
    getRowId: (row) => row.id.toString(),
    //turn off built-in client-side filtering
    manualFiltering: true,
    manualSorting: true,
    manualPagination: true,
    pageCount: accounts?.config.pageCount ?? -1, // # of pages; comes from server or else totalRows/pageSize
    autoResetPageIndex: false,
    autoResetExpanded: false,
    autoResetAll: false,
    state: {
      columnFilters,
      sorting,
      pagination,
    },
    onColumnFiltersChange: onColumnFiltersChange(
      columnFilters,
      setColumnFilters,
      tableSettings,
      setTableSettings,
    ),
    onSortingChange: onSortingChange(
      sorting,
      setSorting,
      tableSettings,
      setTableSettings,
    ),
    onPaginationChange: onPaginationChange(
      pagination,
      setPagination,
      tableSettings,
      setTableSettings,
    ),
  });

  const updateAccountsModel = useUpdateAccountsModel();

  const modelOptions = isPendingModels
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorModels
      ? [
          <option key={null} value="">
            There was an error loading models
          </option>,
        ]
      : [
          <option key={null} value="">
            Select a model
          </option>,
          ...(dataModels ?? [])
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            )),
        ];

  const { setError, resetError } = useErrorMessage();
  const [showModal, setShowModal] = useState(false);
  const [selectedModelId, setModelId] = useState(0);

  const handleAssignModelClick = useCallback(() => {
    setModelId(0);
    setShowModal(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const queryClient = useQueryClient();

  const handleSaveChanges = useCallback(async () => {
    resetError();

    try {
      const updatedData = await updateAccountsModel.mutateAsync({
        modelId: selectedModelId,
        accounts: rowSelection,
      });
      const updatedDataDic = _.keyBy(updatedData, "account_id");
      setRowSelection({});
      queryClient.setQueryData(
        [
          "accounts",
          {
            includeBalances: true,
            includeInactive: false,
          },
          1,
        ],
        {
          data: accounts?.data.map(
            (account): AccountWithMetrics =>
              !(account.id in updatedDataDic)
                ? account
                : {
                    ...account,
                    assignedModelId: updatedDataDic[account.id].model_id,
                  },
          ),
          page: 1,
        },
      );
    } catch (err) {
      const message = "Failed to assign model to accounts";
      setError(message);
      console.error(message, err);
    } finally {
      setShowModal(false);
    }
  }, [
    resetError,
    updateAccountsModel,
    selectedModelId,
    rowSelection,
    setRowSelection,
    queryClient,
    accounts?.data,
    setError,
  ]);

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExportAccounts();

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return (
    <TabContainerWithTabs tabs={ManageNav}>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Household Assignments"
                icon="/icons/compare.svg"
                as={Link}
                to="/clients/accounts/mapping"
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Export"
                icon="/icons/folded-list.svg"
                onClick={onExport}
                disabled={isLoadingExport}
              />
            </ButtonGroup>
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Assign Model"
                icon="/icons/assign.svg"
                onClick={() => handleAssignModelClick()}
                disabled={Object.keys(rowSelection).length === 0}
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          {isPending || isPendingHouseholds ? (
            <Loading />
          ) : (
            <>
              <Table disablePagination={true} table={table} />
              {accountssWithModel.length <= 0 ? (
                <Alert>Accounts not found</Alert>
              ) : null}
              <TablePager
                table={table}
                totalRows={accounts?.config.totalCount ?? 0}
              />{" "}
            </>
          )}
        </Col>
        <ModelAssignDialog
          showModal={showModal}
          handleModalClose={handleModalClose}
          handleSaveChanges={handleSaveChanges}
          rowSelection={rowSelection}
          selectedModelId={selectedModelId}
          setModelId={setModelId}
          modelOptions={modelOptions}
          isSaving={updateAccountsModel.isPending}
          title="accounts"
        />
      </Row>
    </TabContainerWithTabs>
  );
};

export default Accounts;
