import { useContext, useMemo } from "react";
import { Alert, Col } from "react-bootstrap";
import { AxisOptions, Chart } from "react-charts";
import type { SecurityCategoryAssetClassColumns } from "../../../api/src/firms/firms.service";
import { securityCategoryAssetClassColumnsMap } from "../firm/lib";
import { ThemeContext } from "../lib/display";
import { formatPercent } from "../lib/numbers";

export type AssetAllocationDataPoint = { category: string; allocation: number };
export type AssetAllocationSeries = {
  label: string;
  data: AssetAllocationDataPoint[];
};

const AssetAllocationBarChart = ({
  data,
}: {
  data: AssetAllocationSeries[];
}) => {
  const defaultColors = ["#54636d", "#5D5FEF", "#DDE0E2"];

  const primaryAxis = useMemo(
    (): AxisOptions<AssetAllocationDataPoint> => ({
      getValue: (datum) =>
        securityCategoryAssetClassColumnsMap.get(
          datum.category as SecurityCategoryAssetClassColumns,
        )?.label,
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<AssetAllocationDataPoint>[] => [
      {
        getValue: (datum) => datum.allocation,
        elementType: "bar",
        formatters: {
          scale: (value: number | null) =>
            value === null ? "" : formatPercent(value),
        },
      },
    ],
    [],
  );

  const { currentTheme } = useContext(ThemeContext);

  return data.length <= 0 ? (
    <Alert>No data provided for asset allocation chart</Alert>
  ) : (
    <div>
      {data.length > 1 && (
        <div className="text-center">
          <div className="d-inline-flex justify-content-between gap-4 border rounded px-3 py-1">
            {data.map((series, index) => (
              <div key={index} className="d-inline-block">
                <span className="align-middle me-2">{series.label ?? ""}</span>
                <div
                  className="d-inline-block rounded-circle align-middle"
                  style={{
                    backgroundColor: defaultColors[index],
                    width: "1rem",
                    height: "1rem",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <Col>
        <div
          className="text-center"
          style={{
            height: "375px",
          }}
        >
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
              defaultColors,
              dark: currentTheme === "dark",
              padding: { left: 0, right: 0, top: 0, bottom: 0 },
            }}
          />
        </div>
      </Col>
    </div>
  );
};

export default AssetAllocationBarChart;
