import { useContext, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AccountHoldingResponse } from "../../../../api/src/accounts/accounts.service";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import type { Transaction } from "../../../../api/src/transactions/lib";
import type { TransactionsController } from "../../../../api/src/transactions/transactions.controller";
import HoldingsByAccountTable, {
  HoldingsFieldMode,
} from "../../components/HoldingsByAccountTable";
import { useAuthenticatedFetch } from "../../lib/api";
import { HouseholdContext } from "./HouseholdInfo";
import { useQueryHouseholdHoldings } from "./lib";

const HouseholdHoldings = ({
  accountSelection,
  customTaxPeriodParams,
}: {
  accountSelection: Record<string, boolean>;
  customTaxPeriodParams: string;
}) => {
  const { householdId: householdIdStr } = useParams();
  const householdId = parseInt(householdIdStr ?? "-1");
  const { household } = useContext(HouseholdContext);

  const [fieldMode, setFieldMode] = useState<HoldingsFieldMode>("value");

  const { data: dataHoldings, isPending: isPendingHoldings } =
    useQueryHouseholdHoldings(householdId, {
      customTaxPeriodParams,
      enabled: typeof householdIdStr !== "undefined",
    });

  const { data: dataModelSummary, isPending: isPendingModelSummaries } =
    useAuthenticatedFetch<
      UnpackResponse<HouseholdsController["getHouseholdModelSummary"]>
    >(`/households/${householdIdStr}/model-summary`);

  const { data: dataTransactions, isPending: isPendingTransactions } =
    useAuthenticatedFetch<
      UnpackResponse<TransactionsController["getTransactionsForHousehold"]>
    >(`/transactions/household/${householdIdStr}`);

  const selectedAccounts = useMemo(
    () =>
      (household?.accounts ?? []).filter(
        (account) => accountSelection[account.id] === true,
      ),
    [accountSelection, household?.accounts],
  );
  const holdings: AccountHoldingResponse[] = useMemo(
    () =>
      (dataHoldings ?? [])
        .filter((account) => accountSelection[account.id] === true)
        .flatMap((account) => account.holdings),
    [accountSelection, dataHoldings],
  );
  const transactions: Transaction[] = useMemo(
    () =>
      (dataTransactions?.data ?? []).filter(
        (transaction) =>
          accountSelection[transaction.accountId] === true &&
          (transaction.type === "Income" || transaction.type === "Expense"),
      ),
    [accountSelection, dataTransactions?.data],
  );

  return (
    <>
      <Row>
        <Col xxl={3} xl={4} md={6} className="mb-3">
          <Form.Select
            value={fieldMode}
            onChange={(ev) =>
              setFieldMode(ev.currentTarget.value as HoldingsFieldMode)
            }
          >
            <option value="value">Value</option>
            <option value="weight">Weight</option>
            <option value="modelWeight">Model Weight</option>
            <option value="modelWeightDifference">
              Current - Model Weight
            </option>
            <option value="unrealizedGainLoss">Unrealized Gain/Loss</option>
            <option value="realizedGainLoss">Realized Gain/Loss</option>
            <option value="income">Income</option>
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <HoldingsByAccountTable
            holdings={holdings}
            accounts={selectedAccounts}
            modelSummaries={dataModelSummary?.data ?? []}
            transactions={transactions}
            fieldMode={fieldMode}
            isLoading={
              isPendingHoldings ||
              isPendingModelSummaries ||
              isPendingTransactions
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default HouseholdHoldings;
