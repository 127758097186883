import { useQueryClient } from "@tanstack/react-query";
import type { HouseholdsController } from "../../../api/src/households/households.controller";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { Note } from "../../../api/src/notes/notes.service";
import {
  deserializeDate,
  useAuthenticatedMutationNew,
  useAuthenticatedQuery,
} from "./api";

export function useQueryHouseholdNotes(
  householdId: number,
  { enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<HouseholdsController["getHouseholdNotes"]>,
    Note[]
  >({
    queryKey: ["households", householdId, "notes"],
    queryFn: async (notes) => notes.data.map(deserializeNote),
    queryOptions: { enabled },
  });
}

export function useCreateHouseholdNote(householdId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<HouseholdsController["createHouseholdNote"]>,
    Note | null
  >({
    mutationKey: ["POST", "households", householdId, "notes"],
    mutationFn: (note) => {
      queryClient.invalidateQueries({
        queryKey: ["households", householdId, "notes"],
      });
      return note.data === null ? null : deserializeNote(note.data);
    },
  });
}

export function deserializeNote(note: SerializedObject<Note>): Note {
  return {
    ...note,
    createdAt: deserializeDate(note.createdAt),
    updatedAt: deserializeDate(note.updatedAt),
  };
}
