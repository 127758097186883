import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { Alert, ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { ExtendedSecurityGroup } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import HelpButton from "../components/HelpButton";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import {
  onColumnFiltersChange,
  onPaginationChange,
  onSortingChange,
  useTableSettings,
} from "../components/Table/tableSettings";
import ModelsNav from "./ModelsNav";
import SecurityGroupDeleteDialog from "./SecurityGroupDeleteDialog";
import { ModelsHelp, useQuerySecurityGroups } from "./lib";

type SecurityGroupRow = Required<ExtendedSecurityGroup>;

const SecurityGroups = () => {
  const [showModal, setShowModal] = useState(false);
  const [deleteSecurityGroupId, setDeleteSecurityGroupId] = useState(0);
  const handleDeleteSecurityGroupClick = useCallback(
    (deleteSecurityGroupId: number) => {
      setDeleteSecurityGroupId(deleteSecurityGroupId);
      setShowModal(true);
    },
    [],
  );

  const columnHelper = useMemo(
    () => createColumnHelper<SecurityGroupRow>(),
    [],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => (
          <Link to={info.row.original.id.toString()}>{info.getValue()}</Link>
        ),
        header: () => "Name",
        minSize: 275,
      }),

      columnHelper.accessor("description", {
        header: () => "Description",
        minSize: 375,
      }),
      columnHelper.accessor("modelCount", {
        header: () => "Models Used In",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.secondarySecurities.length, {
        id: "secondarySecurities",
        header: () => "Secondary Securities",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.updatedTime, {
        id: "updatedTime",
        cell: (info) => new Date(info.getValue()).toLocaleDateString(),
        header: () => "Last Modified",
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: "deleteSecurityGroup",
        cell: (info) => (
          <ActionButton
            onClick={() => {
              handleDeleteSecurityGroupClick(info.row.original.id);
            }}
            variant="icon"
            icon="/icons/trash.svg"
            label="Delete Sleeve"
            type="button"
          />
        ),
        header: () => "",
        enableColumnFilter: false,
      }),
    ],
    [columnHelper, handleDeleteSecurityGroupClick],
  );

  const {
    isPending: isPendingSecurityGroups,
    isError: isErrorSecurityGroups,
    data: dataSecurityGroups,
  } = useQuerySecurityGroups({ includeMetrics: true });

  const [tableSettings, setTableSettings] = useTableSettings("security-groups");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableSettings.filters,
  );
  const [sorting, setSorting] = useState<SortingState>(tableSettings.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableSettings.pageSize,
    pageIndex: 0,
  });

  const { table } = useTable({
    columns,
    data: (dataSecurityGroups ?? []) as SecurityGroupRow[],
    getRowId: (row) => row.id.toString(),
    state: { columnFilters, sorting, pagination },
    onColumnFiltersChange: onColumnFiltersChange(
      columnFilters,
      setColumnFilters,
      tableSettings,
      setTableSettings,
    ),
    onSortingChange: onSortingChange(
      sorting,
      setSorting,
      tableSettings,
      setTableSettings,
    ),
    onPaginationChange: onPaginationChange(
      pagination,
      setPagination,
      tableSettings,
      setTableSettings,
    ),
  });

  return (
    <TabContainerWithTabs tabs={ModelsNav}>
      <Row>
        <Col className="d-flex justify-content-between">
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="New"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
          </ButtonToolbar>
          <HelpButton
            title="Help – Security Groups"
            body={<ModelsHelp />}
            buttonProps={{ className: "mb-3" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {isPendingSecurityGroups ? (
            <Loading />
          ) : isErrorSecurityGroups ? (
            <Alert variant="danger">
              There was an error loading security groups
            </Alert>
          ) : (
            <Table table={table} />
          )}
        </Col>
      </Row>
      <SecurityGroupDeleteDialog
        showModal={showModal}
        setShowModal={setShowModal}
        deleteSecurityGroupId={deleteSecurityGroupId}
        setDeleteSecurityGroupId={setDeleteSecurityGroupId}
      />
    </TabContainerWithTabs>
  );
};

export default SecurityGroups;
