import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { ModelRequest } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError, { useErrorMessage } from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import HelpTooltip from "../components/HelpTooltip";
import SecurityRestrictions from "../components/SecurityRestrictions";
import SubmitButton from "../components/SubmitButton";
import {
  getSchemaFieldLabel,
  idOptionalSelectorSchema,
  primarySecuritiesSchema,
  securityGroupsSchema,
  securityRestrictionSchema,
  sleevesSchema,
} from "../lib/forms";
import ModelAssetAllocationChart from "./ModelAssetAllocationChart";
import ModelDeleteDialog from "./ModelDeleteDialog";
import ModelInfoActionButtons from "./ModelInfoActionButtons";
import ModelPerformance from "./ModelPerformance";
import ModelRiskAllocationChart from "./ModelRiskAllocationChart";
import PrimarySecurities from "./PrimarySecurities";
import SecurityGroupsSelector from "./SecurityGroupsSelector";
import SleevesSelector from "./SleevesSelector";
import {
  primarySecurityHelpMessage,
  securityGroupHelpMessage,
  securityRestrictionHelpMessage,
  sleeveHelpMessage,
  sumModelWeights,
  useCreateModel,
  useQueryBenchmarks,
  useQueryModel,
  useQueryModels,
  useUpdateModel,
} from "./lib";

type ModelForm = Omit<ModelRequest, "id">;

const schema: yup.ObjectSchema<ModelForm> = yup
  .object({
    name: yup.string().required().label("Model Name"),
    description: yup.string().label("Description"),
    benchmarkId: idOptionalSelectorSchema.label("Benchmark"),
    sleeves: sleevesSchema.required(),
    securityGroups: securityGroupsSchema.required(),
    primarySecurities: primarySecuritiesSchema.required(),
    securityRestrictions: securityRestrictionSchema.required(),
  })
  .test({
    name: "weights-sum-100",
    test: (value) =>
      sumModelWeights(
        value.primarySecurities,
        value.securityGroups,
        value.sleeves,
      ) === 100,
    message: "The sum of all weights must equal 100%",
  });

const ModelInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValidating },
    reset,
    control,
    watch,
    getValues,
  } = useForm<ModelForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    fields: securityGroupFields,
    append: appendSecurityGroup,
    remove: removeSecurityGroup,
    replace: replaceSecurityGroups,
  } = useFieldArray({ name: "securityGroups", control, keyName: "_field_id" });
  const {
    fields: sleeveFields,
    append: appendSleeve,
    remove: removeSleeve,
    replace: replaceSleeves,
  } = useFieldArray({ name: "sleeves", control, keyName: "_field_id" });
  const {
    fields: primarySecurityFields,
    append: appendPrimarySecurity,
    remove: removePrimarySecurity,
    replace: replacePrimarySecurities,
  } = useFieldArray({
    name: "primarySecurities",
    control,
    keyName: "_field_id",
  });
  const {
    fields: securityRestrictionFields,
    append: appendSecurityRestriction,
    remove: removeSecurityRestriction,
    replace: replaceSecurityRestriction,
  } = useFieldArray({
    name: "securityRestrictions",
    control,
    keyName: "_field_id",
  });

  const { setError, resetError, errorMessage } = useErrorMessage();

  const { modelId: modelIdStr } = useParams();
  const modelId = parseInt(modelIdStr ?? "-1");
  const isNew = modelIdStr === "new";

  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: dataModel,
  } = useQueryModel(modelId, { enabled: !isNew });

  useEffect(() => {
    if (typeof dataModel !== "undefined") {
      reset(dataModel);
    }
  }, [dataModel, reset]);

  const {
    isPending: isPendingBenchmarks,
    isError: isErrorBenchmarks,
    data: dataBenchmarks,
  } = useQueryBenchmarks();

  const benchmarkOptions = isPendingBenchmarks
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorBenchmarks
      ? [
          <option key={null} value="">
            There was an error loading benchmarks
          </option>,
        ]
      : [
          <option key={null} value="">
            Select a benchmark
          </option>,
          ...(dataBenchmarks ?? []).map((benchmark) => (
            <option key={benchmark.id} value={benchmark.id}>
              {benchmark.name}
            </option>
          )),
        ];

  const createModel = useCreateModel();
  const updateModel = useUpdateModel(modelId);

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useCallback(
    async (data: ModelForm) => {
      const requestData = {
        ...data,
        // Filter out empty sleeve rows
        sleeves: data.sleeves.filter(
          (sleeve) => sleeve.id !== -1 && sleeve.weight !== 0,
        ),
      };

      try {
        if (isNew) {
          const newModelId = await createModel.mutateAsync(requestData);
          notificationContext.pushNotification({
            id: `model-${newModelId}`,
            header: "Model Created",
            body: `${data.name} model created`,
            variant: "success",
          });
          navigate(`../${newModelId}`);
        } else {
          const modelBody = await updateModel.mutateAsync(requestData);
          reset(modelBody);
          notificationContext.pushNotification({
            id: `model-${modelIdStr}`,
            header: "Model Updated",
            body: `${data.name} model updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save model", err);
        notificationContext.pushNotification({
          id: `model-${modelIdStr}`,
          header: "Failed to Save Model",
          body: `Model ${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
    [
      createModel,
      isNew,
      modelIdStr,
      navigate,
      notificationContext,
      reset,
      updateModel,
    ],
  );

  const watchPrimarySecurityArray = watch("primarySecurities", []);
  const controlledPrimarySecurityFields = primarySecurityFields.map(
    (field, index) => ({
      ...field,
      ...watchPrimarySecurityArray[index],
    }),
  );

  const watchSleevesArray = watch("sleeves", []);
  const watchSecurityGroupsArray = watch("securityGroups", []);

  const watchSecurityRestrictionsArray = watch("securityRestrictions", []);
  const controlledSecurityRestrictionFields = securityRestrictionFields.map(
    (field, index) => ({
      ...field,
      ...watchSecurityRestrictionsArray[index],
    }),
  );

  // react-hook-form does not have form-level validation,
  // so we must perform it manually.
  useEffect(() => {
    if (isValidating) {
      const sum = sumModelWeights(
        watchPrimarySecurityArray,
        watchSecurityGroupsArray,
        watchSleevesArray,
      );

      if (sum === 100) {
        resetError();
      } else {
        setError("The sum of all weights must equal 100");
      }
    }
  }, [
    watchPrimarySecurityArray,
    watchSleevesArray,
    setError,
    resetError,
    isValidating,
    watchSecurityGroupsArray,
  ]);

  const [showModal, setShowModal] = useState(false);
  const [deleteModelId, setDeleteModelId] = useState(0);
  const [selectedModelId, setModelId] = useState(0);
  const handleDeleteModelClick = useCallback(() => {
    setDeleteModelId(deleteModelId);
    setShowModal(true);
  }, [deleteModelId]);

  const {
    isPending: isPendingModels,
    isError: isErrorModels,
    data: dataModels,
  } = useQueryModels({ includeMetrics: true });

  const selectModels = (dataModels ?? []).filter(
    (model) => model.id !== modelId,
  );

  const modelOptions = isPendingModels
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorModels
      ? [
          <option key={null} value="">
            There was an error loading models
          </option>,
        ]
      : [
          <option key="" value="">
            None
          </option>,
          ...selectModels
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((model) => (
              <option
                key={model.id}
                value={model.id}
                hidden={model.id === deleteModelId}
              >
                {model.name}
              </option>
            )),
        ];

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !dataModel && !isNew ? (
    <FormError message="Model not found" />
  ) : (
    <>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <h1>Model Details</h1>
              </Col>
              <Col md="auto">
                <ModelInfoActionButtons
                  deleteHandler={handleDeleteModelClick}
                />
              </Col>
            </Row>
            <Content>
              {errorMessage}
              <Row>
                <Col xxl={9} md={7}>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-name">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.name)}
                        </Form.Label>
                        <Form.Control type="text" {...register("name")} />
                        <FormFieldError field={errors.name} />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-description">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.description)}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register("description")}
                        />
                        <FormFieldError field={errors.description} />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-benchmarkId">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.benchmarkId)}
                        </Form.Label>
                        <Form.Select
                          placeholder="Select model benchmark"
                          // Registering before loading creates a race condition
                          {...(isPendingBenchmarks
                            ? {}
                            : register("benchmarkId"))}
                        >
                          {benchmarkOptions}
                        </Form.Select>
                        <FormFieldError field={errors.benchmarkId} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex">
                    <h2 className="me-1">Sleeves</h2>
                    <HelpTooltip tooltip={sleeveHelpMessage} />
                  </div>
                  <SleevesSelector
                    fields={sleeveFields}
                    replace={replaceSleeves}
                    add={appendSleeve}
                    remove={removeSleeve}
                    register={register}
                  />
                  <div className="d-flex">
                    <h2 className="me-1">Security Groups</h2>
                    <HelpTooltip tooltip={securityGroupHelpMessage} />
                  </div>
                  <SecurityGroupsSelector
                    fields={securityGroupFields}
                    replace={replaceSecurityGroups}
                    add={appendSecurityGroup}
                    remove={removeSecurityGroup}
                    register={register}
                  />
                  <div className="d-flex">
                    <h2 className="me-1">Primary Securities</h2>
                    <HelpTooltip tooltip={primarySecurityHelpMessage} />
                  </div>
                  <PrimarySecurities
                    fields={controlledPrimarySecurityFields}
                    replace={replacePrimarySecurities}
                    add={appendPrimarySecurity}
                    remove={removePrimarySecurity}
                    register={register}
                    control={control}
                    getValues={getValues}
                    errors={errors.primarySecurities}
                    securityGroups={watchSecurityGroupsArray}
                    sleeves={watchSleevesArray}
                  />
                  <div className="d-flex">
                    <h2 className="me-1">Security Restrictions</h2>
                    <HelpTooltip tooltip={securityRestrictionHelpMessage} />
                  </div>
                  <SecurityRestrictions
                    fields={controlledSecurityRestrictionFields}
                    replace={replaceSecurityRestriction}
                    add={appendSecurityRestriction}
                    remove={removeSecurityRestriction}
                    register={register}
                    control={control}
                    getValues={getValues}
                    errors={errors.securityRestrictions}
                  />
                  <Row>
                    <Col>
                      <SubmitButton isSubmitting={isSubmitting} />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <ModelPerformance model={getValues()} isLoading={isLoading} />
                  <ModelAssetAllocationChart
                    model={getValues()}
                    isLoading={isLoading}
                  />
                  <ModelRiskAllocationChart
                    model={getValues()}
                    isLoading={isLoading}
                  />
                </Col>
                <ModelDeleteDialog
                  showModal={showModal}
                  setShowModal={setShowModal}
                  deleteModelId={modelId}
                  selectedModelId={selectedModelId}
                  setModelId={setModelId}
                  modelOptions={modelOptions}
                />
              </Row>
            </Content>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ModelInfo;
