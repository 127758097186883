import { useCallback, useState } from "react";
import ActionButton from "../../components/ActionButton";
import { NoteForm, NotesPane } from "../../components/Notes";
import {
  useCreateHouseholdNote,
  useQueryHouseholdNotes,
} from "../../lib/notes";

const HouseholdNotesButton = ({ householdId }: { householdId: number }) => {
  const [showNotes, setShowNotes] = useState(false);
  const onShowNotes = useCallback(() => {
    setShowNotes(!showNotes);
  }, [showNotes]);

  const {
    isPending: isPendingNotes,
    isError: isErrorNotes,
    data: dataNotes,
  } = useQueryHouseholdNotes(householdId);

  const createNote = useCreateHouseholdNote(householdId);

  const onCreateNote = useCallback(
    async (data: NoteForm) => {
      return await createNote.mutateAsync(data);
    },
    [createNote],
  );

  return (
    <>
      <ActionButton
        variant="secondary"
        icon="/icons/folded-list.svg"
        label="Notes"
        type="button"
        onClick={onShowNotes}
      />
      <NotesPane
        showNotes={showNotes}
        setShowNotes={setShowNotes}
        notes={dataNotes ?? []}
        onCreate={onCreateNote}
        isLoading={isPendingNotes}
        isError={isErrorNotes}
      />
    </>
  );
};

export default HouseholdNotesButton;
