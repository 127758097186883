import { createColumnHelper } from "@tanstack/react-table";
import { groupBy } from "lodash";
import { useEffect, useMemo } from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";
import { Path, useFieldArray, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import type { AccountWithMetrics } from "../../../../api/src/accounts/accounts.service";
import type {
  EditableHouseholdTrading,
  EditableHouseholdTradingAccounts,
  EditableHouseholdTradingHoldings,
} from "../../../../api/src/households-base/households-base.service";
import type { AccountHoldingWithSecurity } from "../../../../api/src/positions/positions.service";
import type { RiskAllocation } from "../../../../api/src/securities/securities.service";
import Loading from "../../Loading";
import FormFieldError from "../../components/FormFieldError";
import SecurityLink from "../../components/SecurityLink";
import { Table, useTable } from "../../components/Table/Table";
import { displayAccountName } from "../../lib/display";
import { formatCurrencyComponent } from "../../lib/numbers";
import { useQueryModels } from "../../models/lib";
import { getRiskAllocationDisplay } from "../../rebalances/lib";

type AccountHoldingRow = {
  accountName?: string;
  riskAllocation: RiskAllocation;
} & Pick<
  AccountHoldingWithSecurity,
  "id" | "value" | "security" | "unrealizedGainLoss"
> &
  EditableHouseholdTradingHoldings &
  EditableHouseholdTradingAccounts;

function HouseholdTradingHoldingsTable<TForm extends EditableHouseholdTrading>({
  holdings,
  accounts,
  isEditable = true,
}: {
  holdings: AccountHoldingWithSecurity[];
  accounts?: AccountWithMetrics[];
  isEditable?: boolean;
}) {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext<EditableHouseholdTrading>();

  const { fields: holdingFields, update: updateHoldingField } = useFieldArray({
    name: "holdings",
    control,
    keyName: "_field_id",
  });

  const {
    isPending: isPendingModels,
    isError: isErrorModels,
    data: dataModels,
  } = useQueryModels();

  const modelOptions = useMemo(
    () => [
      <option key={null} value="">
        None
      </option>,
      ...(dataModels ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((model) => (
          <option key={model.id} value={model.id}>
            {model.name}
          </option>
        )),
    ],
    [dataModels],
  );

  const columnHelper = useMemo(
    () => createColumnHelper<AccountHoldingRow>(),
    [],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("accountName", {
        header: "Account Name",
        cell: (info) => (
          <span className="fw-bold">
            <Link to={`/clients/accounts/${info.row.original.accountId}`}>
              {info.getValue() ?? ""}
            </Link>
          </span>
        ),
        size: 230,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.symbol, {
        header: "Allocation",
        id: "allocation",
        cell: (info) => (
          <SecurityLink
            symbol={info.getValue()}
            description={info.row.original.security?.description}
          />
        ),
        aggregatedCell: (info) => (
          <span className="fw-bold">
            {getRiskAllocationDisplay(info.row.original.riskAllocation)}
          </span>
        ),
        size: 180,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("value", {
        cell: (info) => formatCurrencyComponent(info.getValue(), 2),
        header: () => "Value",
        aggregatedCell: (info) => (
          <span className="fw-bold">
            {formatCurrencyComponent(info.getValue(), 2)}
          </span>
        ),
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("unrealizedGainLoss", {
        cell: (info) => formatCurrencyComponent(info.getValue(), 2),
        header: () => "Unrealized Gain/Loss",
        aggregatedCell: (info) => (
          <span className="fw-bold">
            {formatCurrencyComponent(info.getValue(), 2)}
          </span>
        ),
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("restrictSecurity", {
        cell: (info) => (
          <Form.Check
            type="checkbox"
            checked={holdingFields[info.row.index].restrictSecurity}
            onChange={(val) =>
              updateHoldingField(info.row.index, {
                ...holdingFields[info.row.index],
                restrictSecurity: val.currentTarget.checked,
              })
            }
            disabled={!isEditable}
          />
        ),
        size: 120,
        header: () => "Restrict Security?",
        meta: {
          className: "text-center",
        },
        enableColumnFilter: false,
      }),
      columnHelper.accessor("treatment", {
        cell: (info) =>
          !holdingFields[info.row.index].restrictSecurity ? null : (
            <Form.Select
              {...register(
                `holdings.${info.row.index}.treatment` as Path<TForm>,
              )}
              disabled={!isEditable}
            >
              <option value="hold_only">Hold only</option>
              <option value="sell_no_tax">Sell if no tax consequences</option>
            </Form.Select>
          ),
        header: () => "Treatment",
        size: 180,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("assignedModelId", {
        cell: () => null,
        aggregatedCell: (info) => (
          <Form.Select
            {...register(
              `accounts.${info.row.original.accountId}.assignedModelId` as Path<TForm>,
            )}
            disabled={!isEditable}
          >
            {modelOptions}
          </Form.Select>
        ),
        header: () => "Model",
        size: 180,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("riskScore", {
        cell: () => null,
        aggregatedCell: (info) => (
          <Form.Control
            type="number"
            min={1}
            max={10}
            step={0.1}
            {...register(
              `accounts.${info.row.original.accountId}.riskScore` as Path<TForm>,
            )}
            disabled={!isEditable}
          />
        ),
        header: () => "Risk Score",
        size: 140,
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("capitalGainsBudgetLT", {
        cell: () => null,
        aggregatedCell: (info) => (
          <Form.Group>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                min={0}
                {...register(
                  `accounts.${info.row.original.accountId}.capitalGainsBudgetLT` as Path<TForm>,
                )}
                disabled={!isEditable}
              />
            </InputGroup>
            <FormFieldError
              field={
                errors.accounts?.[info.row.original.accountId]
                  ?.capitalGainsBudgetLT
              }
            />
          </Form.Group>
        ),
        header: () => "Capital Gains Budget LT",
        size: 180,
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor("capitalGainsBudgetST", {
        cell: () => null,
        aggregatedCell: (info) => (
          <Form.Group>
            <InputGroup>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type="number"
                min={0}
                {...register(
                  `accounts.${info.row.original.accountId}.capitalGainsBudgetST` as Path<TForm>,
                )}
                disabled={!isEditable}
              />
            </InputGroup>
            <FormFieldError
              field={
                errors.accounts?.[info.row.original.accountId]
                  ?.capitalGainsBudgetST
              }
            />
          </Form.Group>
        ),
        header: () => "Capital Gains Budget ST",
        size: 180,
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
    ],
    [
      columnHelper,
      errors.accounts,
      holdingFields,
      isEditable,
      modelOptions,
      register,
      updateHoldingField,
    ],
  );

  const holdingAccounts = useMemo(
    () => groupBy(holdings, "accountId"),
    [holdings],
  );
  const accountsWithHoldings = useMemo(
    () =>
      Object.entries(holdingAccounts).flatMap(([accountId, holdings]) => {
        const account = accounts?.find(
          (account) => account.id === parseInt(accountId),
        );

        return holdings.map((holding) => {
          const symbol = holding.security?.identifier ?? "";
          const securityRestriction = account?.securityRestrictions.find(
            (restriction) => restriction.symbol === symbol,
          );
          const restrictSecurity = typeof securityRestriction !== "undefined";

          return {
            ...holding,
            accountName: displayAccountName(
              account?.displayName,
              account?.displayNumber,
            ),
            assignedModelId: account?.assignedModelId,
            riskAllocation: account?.riskAllocation?.current ?? {
              growth: 0.5,
              defensive: 0.5,
              unknown: 0,
            },
            restrictSecurity,
            symbol,
            treatment: securityRestriction?.treatment ?? "sell_no_tax",
            riskScore: account?.riskScore ?? null,
          };
        });
      }),
    [accounts, holdingAccounts],
  );

  const { table } = useTable({
    columns,
    data: accountsWithHoldings,
    initialState: {
      sorting: [{ id: "value", desc: true }],
      grouping: ["accountName"],
      pagination: {
        pageSize: 9999,
      },
    },
    manualPagination: true,
    autoResetExpanded: false,
    getRowId: (row) => row.id.toString(),
  });

  useEffect(() => {
    if (accountsWithHoldings.length > 0) {
      table.toggleAllRowsExpanded(true);
    }
  }, [accountsWithHoldings, table]);

  return isPendingModels ? (
    <Loading message="Holdings" />
  ) : isErrorModels ? (
    <Alert variant="danger">An error occurred</Alert>
  ) : (
    <Table table={table} disablePagination isDisableGrouping isFixedGrouping />
  );
}

export default HouseholdTradingHoldingsTable;
