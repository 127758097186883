import _ from "lodash";
import { useContext, useMemo, useState } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../../api/src/lib";
import Loading from "../../Loading";
import TaxPerformancePeriodSelector from "../../components/TaxPerformancePeriodSelector";
import { useAuthenticatedFetch } from "../../lib/api";
import { formatCurrencyComponent, formatPercent } from "../../lib/numbers";
import { HouseholdContext } from "./HouseholdInfo";
import HouseholdTaxTable from "./HouseholdTaxTable";

const HouseholdTax = () => {
  const { household } = useContext(HouseholdContext);
  const { householdId } = useParams();
  const [customTaxPeriodParams, setCustomTaxPeriodParams] = useState("");

  const {
    data: dataHouseholdAccountTaxPerformance,
    isPending: isPendingHouseholdAccountTaxPerformance,
    isError: isErrorHouseholdAccountTaxPerformance,
  } = useAuthenticatedFetch<
    UnpackResponse<HouseholdsController["getHouseholdPerformance"]>
  >(`/households/${householdId}/performance?${customTaxPeriodParams}`);

  const taxableAccounts = useMemo(
    () => (household?.accounts ?? []).filter((account) => account.isTaxable),
    [household?.accounts],
  );

  // holds the total income and balance for all accounts
  const householdTotals = useMemo(
    () =>
      (dataHouseholdAccountTaxPerformance?.data ?? []).reduce(
        (sums, account) => ({
          balance: sums.balance + account.balance,
          income: sums.income + account.income,
        }),
        {
          balance: 0,
          income: 0,
        },
      ),
    [dataHouseholdAccountTaxPerformance?.data],
  );

  const taxTotals = useMemo(() => {
    const accountsMap = _.keyBy(taxableAccounts, "id");
    // holds the total income and balance for all taxable accounts
    const householdAccountTaxPerformanceData = (
      dataHouseholdAccountTaxPerformance?.data ?? []
    ).filter((account) => account.isTaxable);

    return householdAccountTaxPerformanceData.reduce(
      (sums, account) => {
        const accountWithMetrics = accountsMap[account.accountId!];

        return {
          balance: sums.balance + account.balance,
          realizedGainLoss: sums.realizedGainLoss + account.realizedGainLoss,
          realizedGainLossST:
            sums.realizedGainLossST + account.realizedGainLossST,
          realizedGainLossLT:
            sums.realizedGainLossLT + account.realizedGainLossLT,
          income: sums.income + account.income,
          unrealizedGainLoss:
            sums.unrealizedGainLoss + account.unrealizedGainLoss,
          unrealizedLoss:
            sums.unrealizedLoss + (accountWithMetrics?.unrealizedLoss ?? 0),
        };
      },
      {
        balance: 0,
        realizedGainLoss: 0,
        realizedGainLossST: 0,
        realizedGainLossLT: 0,
        income: 0,
        unrealizedGainLoss: 0,
        unrealizedLoss: 0,
      },
    );
  }, [dataHouseholdAccountTaxPerformance?.data, taxableAccounts]);

  const oldestAccount = _.minBy(household?.accounts ?? [], "inceptionDate");

  return (
    <>
      <Row className="mb-2">
        <Col xl={2} md={2}>
          <TaxPerformancePeriodSelector
            inceptionDate={oldestAccount?.inceptionDate}
            setCustomPeriodParams={setCustomTaxPeriodParams}
          />
        </Col>
        {isPendingHouseholdAccountTaxPerformance ? (
          <Loading message="Tax Details" />
        ) : isErrorHouseholdAccountTaxPerformance ? (
          <Alert variant="danger">Failed to load tax details</Alert>
        ) : (
          <>
            <Col md={4}>
              <Row>
                <Col as="dt" md={7} sm={8} xs={8}>
                  Total Div./Int.
                </Col>
                <Col as="dd">
                  {formatCurrencyComponent(householdTotals.income, 2)} (
                  {formatPercent(
                    householdTotals.balance === 0
                      ? 0
                      : householdTotals.income / householdTotals.balance,
                    2,
                  )}
                  )
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={7} sm={8} xs={8}>
                  Total Taxable Div./Int.
                </Col>
                <Col as="dd" md={5} sm={4} xs={4}>
                  {formatCurrencyComponent(taxTotals.income, 2)} (
                  {formatPercent(
                    taxTotals.balance === 0
                      ? 0
                      : taxTotals.income / taxTotals.balance,
                    2,
                  )}
                  )
                </Col>
              </Row>
              <Row>
                <Col as="dt" md={7} sm={8} xs={8}>
                  TLH Opportunities
                </Col>
                <Col as="dd" md={5} sm={4} xs={4}>
                  {formatCurrencyComponent(taxTotals.unrealizedLoss, 2)} (
                  {formatPercent(
                    taxTotals.balance === 0
                      ? 0
                      : taxTotals.unrealizedLoss / taxTotals.balance,
                    2,
                  )}
                  )
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col as="dt" md={8} sm={8} xs={8}>
                  Taxable Realized LT G/L
                </Col>
                <Col as="dd">
                  {formatCurrencyComponent(taxTotals.realizedGainLossLT, 2)}
                </Col>
              </Row>
              <Row>
                <Col as="dt" sm={8} xs={8}>
                  Taxable Realized ST G/L
                </Col>
                <Col as="dd">
                  {formatCurrencyComponent(taxTotals.realizedGainLossST, 2)}
                </Col>
              </Row>
              <Row>
                <Col as="dt" sm={8} xs={8}>
                  Total Taxable Realized G/L
                </Col>
                <Col as="dd">
                  {formatCurrencyComponent(
                    taxTotals.realizedGainLossLT + taxTotals.realizedGainLossST,
                    2,
                  )}
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col>
          <h3>Account Holdings</h3>
          <HouseholdTaxTable
            accounts={taxableAccounts}
            customTaxPeriodParams={customTaxPeriodParams}
          />
        </Col>
      </Row>
    </>
  );
};

export default HouseholdTax;
