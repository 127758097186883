import { createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RenderSuggestionParams } from "react-autosuggest";
import { Alert, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { AccountWithMetrics } from "../../../../api/src/accounts/accounts.service";
import type { UnpackResponse } from "../../../../api/src/lib";
import type { Model } from "../../../../api/src/models/models.service";
import ActionButton from "../../components/ActionButton";
import AllocationBar from "../../components/AllocationBar/AllocationBar";
import Autocomplete, { Suggestion } from "../../components/Autocomplete";
import IndeterminateCheckbox from "../../components/IndeterminateCheckbox";
import { Table, useTable } from "../../components/Table/Table";
import { useAuthenticatedFetch } from "../../lib/api";
import { displayAccountName } from "../../lib/display";
import { formatCurrency, formatPercent } from "../../lib/numbers";
import { useQueryModels } from "../../models/lib";
import { getRiskAllocation } from "../../rebalances/lib";

export type AccountItem = AccountWithMetrics & {
  model?: Model;
  isNew?: boolean;
};

const PREFIX_LENGTH = 1;

const AccountsSelector = ({
  accounts,
  setAccounts,
}: {
  accounts: AccountItem[];
  setAccounts: React.Dispatch<React.SetStateAction<AccountItem[]>>;
}) => {
  const [name, setName] = useState("");
  const [prefix, setPrefix] = useState("");
  const { data, refetch, isError, isFetching } = useAuthenticatedFetch<
    UnpackResponse<AccountsController["filterUnassigned"]>
  >(
    `/accounts/filter/unassigned?name=${prefix}&balances=true`,
    undefined,
    { enabled: false },
    ["/accounts/filter/unassigned", prefix, true],
  );

  const onSelect = useCallback(
    (value: AccountItem) => {
      setAccounts([...accounts, { ...value, isNew: true }]);
    },
    [setAccounts, accounts],
  );

  const loadSuggestions = useCallback(
    (value: string) => {
      if (value.length >= PREFIX_LENGTH)
        setPrefix(value.slice(0, PREFIX_LENGTH));
      setName(value);
    },
    [setPrefix, setName],
  );

  const getSuggestionValue = useCallback(
    (suggestion: AccountItem) =>
      displayAccountName(suggestion.displayName, suggestion.displayNumber),
    [],
  );

  const renderSuggestion = useCallback(
    (suggestion: AccountItem, params: RenderSuggestionParams) => (
      <Suggestion active={params.isHighlighted}>
        {displayAccountName(suggestion.displayName, suggestion.displayNumber)}
      </Suggestion>
    ),
    [],
  );

  useEffect(() => {
    if (prefix.length >= PREFIX_LENGTH) refetch();
  }, [refetch, prefix]);

  return (
    <Form.Group className="mb-3" controlId="form-add-account">
      <Form.Label>Add Account</Form.Label>
      <Autocomplete
        suggestions={
          (data?.data ?? [])
            .filter(
              (account) =>
                account.displayName
                  .toLowerCase()
                  .includes(name.toLowerCase()) &&
                accounts.every(
                  (existingAccount) => existingAccount.id !== account.id,
                ),
            )
            .slice(0, 10) as AccountItem[]
        }
        isLoading={isFetching}
        onSelect={onSelect}
        loadSuggestions={loadSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        placeholder="Search accounts by name..."
      />
      {!isError ? null : (
        <Form.Text className="text-danger">Failed to load accounts</Form.Text>
      )}
    </Form.Group>
  );
};

const HouseholdAccounts = ({
  accounts,
  setAccounts,
  setRowSelection,
}: {
  accounts: AccountItem[];
  setAccounts: React.Dispatch<React.SetStateAction<AccountItem[]>>;
  setRowSelection: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >;
}) => {
  const columnHelper = useMemo(() => createColumnHelper<AccountItem>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) => displayAccountName(row.displayName, row.displayNumber),
        {
          id: "name",
          cell: (info) => (
            <Link to={`/clients/accounts/${info.row.original.id}`}>
              {info.getValue()}
            </Link>
          ),
          header: () => "Name",
          minSize: 225,
          enableColumnFilter: false,
        },
      ),
      columnHelper.accessor("type", {
        header: () => "Account Type",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.accountBalance ?? 0, {
        id: "accountBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Total Assets",
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => {
          const accountBalance = info.row.original.accountBalance ?? 0;
          const cashPercentage =
            (accountBalance ?? 0) === 0 ? 0 : info.getValue() / accountBalance;
          return `${formatCurrency(info.getValue())} (${formatPercent(
            cashPercentage,
            2,
          )})`;
        },
        header: () => "Cash ($ and %)",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.riskAllocation?.current, {
        id: "riskAllocation",
        cell: (info) => {
          return (
            <>
              <AllocationBar
                data={info.getValue()}
                converter={getRiskAllocation}
              />
            </>
          );
        },
        header: () => "Allocation",
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => {
          return (
            <ActionButton
              variant="icon"
              icon="/icons/trash.svg"
              label="Remove Account"
              type="button"
              onClick={() => {
                const updatedAccounts = accounts.filter(
                  (a) => a.id !== row.original.id,
                );
                setAccounts(updatedAccounts);
              }}
            />
          );
        },
      }),
      columnHelper.display({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) =>
          row.original.isNew === true ? null : (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
      }),
    ],
    [accounts, columnHelper, setAccounts],
  );

  const { data: dataModels } = useQueryModels();

  const accountsWithModel = useMemo(
    () =>
      accounts.map((account) => {
        const model = (dataModels ?? []).find(
          (model) => model.id === account.assignedModelId,
        );

        return {
          ...account,
          model,
        };
      }),
    [accounts, dataModels],
  );

  const { table, rowSelection } = useTable({
    columns: columns,
    data: accountsWithModel,
    initialState: {
      sorting: [{ id: "name", desc: true }],
      pagination: {
        pageSize: 9999,
      },
    },
    manualPagination: true,
    getRowId: (row) => row.id.toString(),
  });

  useEffect(() => {
    setRowSelection(rowSelection);
  }, [rowSelection, setRowSelection]);

  useEffect(() => {
    table.toggleAllRowsSelected();
  }, [table]);

  return (
    <div>
      <AccountsSelector accounts={accounts} setAccounts={setAccounts} />
      {accounts.length <= 0 ? (
        <Alert>No household accounts</Alert>
      ) : (
        <div className="overflow-x-auto">
          <Table table={table} />
        </div>
      )}
    </div>
  );
};

export default HouseholdAccounts;
