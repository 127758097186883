import { useCallback } from "react";
import { Button, ButtonGroup, Form, InputGroup, Table } from "react-bootstrap";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  Path,
  UseFieldArrayRemove,
  UseFormRegister,
} from "react-hook-form";
import type { SimpleAssignedItem } from "../../../api/src/models/models.service";
import FormFieldError from "../components/FormFieldError";
import { addRemoveLabel } from "../lib/display";
import { useQuerySleeves } from "./lib";

type SleeveField = SimpleAssignedItem & { _field_id: string };

const emptySleeve: SleeveField = {
  id: -1,
  weight: 0,
  _field_id: "",
};

/**
 * A multi-item form component for sleeves in an investing model. This component
 * displays an arbitrary number of rows for users to view and modify selected sleeves.
 */
function SleevesSelector<TForm extends { sleeves: SimpleAssignedItem[] }>({
  fields,
  replace,
  add,
  remove,
  register,
  errors,
}: {
  /** The collection of sleeves in a model */
  fields: SleeveField[];
  /** The setter function for the fields prop */
  replace: (sleeves: SleeveField[]) => void;
  add: (sleeve: SleeveField) => void;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<TForm>;
  errors?: Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<SimpleAssignedItem>> | undefined)[]
  >;
}) {
  const {
    isPending: isPendingSleeves,
    isError: isErrorSleeves,
    data: dataSleeves,
  } = useQuerySleeves();

  const sleeveOptions = isPendingSleeves
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorSleeves
      ? [
          <option key={null} value="">
            There was an error loading sleeves
          </option>,
        ]
      : [
          <option key={null} value="">
            Select a sleeve
          </option>,
          ...(dataSleeves ?? []).map((sleeve) => (
            <option key={sleeve.id} value={sleeve.id}>
              {sleeve.name}
            </option>
          )),
        ];

  const addSleeve = useCallback(() => {
    add({ ...emptySleeve });
  }, [add]);

  const removeSleeve = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove],
  );

  return (
    <Table className="form-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Weight</th>
          <th>{addRemoveLabel}</th>
        </tr>
      </thead>
      <tbody>
        {fields.length <= 0 ? (
          <tr>
            <td>None</td>
            <td>{/* Empty */}</td>
            <td>
              <Button onClick={addSleeve}>+</Button>
            </td>
          </tr>
        ) : (
          fields.map((sleeve, index) => (
            <tr key={sleeve._field_id}>
              <td className="align-top">
                <Form.Select
                  placeholder="Select sleeve"
                  // Registering before loading creates a race condition
                  {...(isPendingSleeves
                    ? {}
                    : register(`sleeves.${index}.id` as Path<TForm>))}
                >
                  {sleeveOptions}
                </Form.Select>
                <FormFieldError field={errors?.[index]?.id} />
              </td>
              <td className="align-top">
                <Form.Group>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      step={0.01}
                      {...register(`sleeves.${index}.weight` as Path<TForm>)}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                  <FormFieldError field={errors?.[index]?.weight} />
                </Form.Group>
              </td>
              <td className="align-top">
                <ButtonGroup>
                  <Button onClick={removeSleeve(index)}>-</Button>
                  {index !== fields.length - 1 ? null : (
                    <Button onClick={addSleeve}>+</Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
}

export default SleevesSelector;
