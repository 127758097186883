import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../Loading";
import { RebalanceContext } from "./RebalanceInfo";
import HouseholdCashBillingRebalance from "../clients/household/HouseholdCashBillingRebalance";

const RebalanceHouseholdCashBilling = () => {
  const { rebalance, household } = useContext(RebalanceContext);

  return typeof rebalance === "undefined" ? (
    <Loading />
  ) : (
    <Row>
      <Col>
        {household && <HouseholdCashBillingRebalance household={household} />}
      </Col>
    </Row>
  );
};

export default RebalanceHouseholdCashBilling;
