import { useQueryClient } from "@tanstack/react-query";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { ModelsController } from "../../../api/src/models/models.controller";
import type {
  Benchmark,
  ExtendedBenchmark,
  ExtendedModelSleeve,
  ExtendedSecurityGroup,
  Model,
  ModelPerformance,
  ModelRequestSecurities,
  PrimarySecurity,
  SecurityGroup,
  SecurityGroupRequest,
  SimpleAssignedItem,
  Sleeve,
} from "../../../api/src/models/models.service";
import type { RiskAllocation } from "../../../api/src/securities/securities.service";
import {
  deserializeDate,
  processEmptyResponse,
  useAuthenticatedMutationNew,
  useAuthenticatedQuery,
} from "../lib/api";

export function sumModelWeights(
  primarySecurities: PrimarySecurity[],
  securityGroups: SimpleAssignedItem[] = [],
  sleeves: SimpleAssignedItem[] = [],
) {
  return (
    sumWeights(primarySecurities) +
    sumWeights(sleeves) +
    sumWeights(securityGroups)
  );
}

function sumWeights(arr: { weight: number | string }[]) {
  return arr.reduce((sum, list) => {
    const weightStr = list.weight as unknown as string;
    return sum + parseFloat(weightStr === "" ? "0" : weightStr);
  }, 0);
}

export const primarySecurityHelpMessage =
  "Priority securities to have in a portfolio";
export const secondarySecurityHelpMessage =
  "Securities that can be used in place of primary securities in a portfolio";
export const securityGroupHelpMessage =
  "Groupings of a primary security and its associated secondary securities";
export const sleeveHelpMessage =
  "Sub-models that can be combined to form an assignable model";
export const securityRestrictionHelpMessage =
  "Securities that should remain held but that have no alternative security";

export const ModelsHelp = () => (
  <section>
    <p>
      Models are target portfolios that can be assigned to households and
      accounts. Models dictate what trades are proposed when generating
      household and account rebalances.
    </p>
    <h3>Models</h3>
    <p>
      <strong>Models</strong> are a combination of Primary Securities, Security
      Groups, and Sleeves. Each Primary Security, Security Group, and Sleeve is
      given a target weight within the portfolio.
    </p>
    <h3>Sleeves</h3>
    <p>
      <strong>Sleeves</strong> are sub-models that can be reused within multiple
      Models. They consist of Primary Securities and Security Groups, just like
      Models. <strong>Sleeves</strong> can not be directly assigned to a
      household or account. AdviceCloud does not allow the use of{" "}
      <strong>Sleeves</strong> within other <strong>Sleeves</strong>. The Asset
      Category and Region are optional labels that can help categorize{" "}
      <strong>Sleeves</strong>.
    </p>
    <h3>Security Groups</h3>
    <p>
      <strong>Security Groups</strong> consist of a primary security and its
      secondary securities. <strong>Security Groups</strong> can be assigned to
      models and sleeves just like Primary Securities. In a{" "}
      <strong>Security Group</strong>, Primary Securities are given priority
      over Secondary Securities when generating rebalances and proposing trades.
      Secondary Securities are used in place of Primary Securities in certain
      situations, such as for tax-loss harvesting or selling out of a position.
      Secondary Securities have other uses, such as facilitating a gradual
      migration from an old to a new Model without generating a large amount of
      trades immediately.
    </p>
    <h3>Benchmarks</h3>
    <p>
      <strong>Benchmarks</strong> are an optional feature of Models that allow
      comparison of a Model to another portfolio, such as one or more ETFs.
      Assigning a <strong>Benchmark</strong> to a Model is useful for indicating
      the intended theme of a Model. Benchmarks have no effect on rebalancing
      and are purely for informational purposes.
    </p>
  </section>
);

export function useQueryModels({
  includeMetrics = false,
  enabled = true,
} = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getAllModels"]>,
    Model[],
    [
      string,
      string,
      {
        includeMetrics: boolean;
      },
    ]
  >({
    queryKey: ["models", "models", { includeMetrics }],
    queryFn: async (models) => models.data.map(deserializeModel),
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}?metrics=${key[2].includeMetrics}`,
    queryOptions: { enabled },
  });
}

export function useQueryModel(modelId: number, { enabled = true } = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getModel"]>,
    Model
  >({
    queryKey: ["models", "models", modelId],
    queryFn: async (model) => deserializeModel(model.data),
    queryOptions: { enabled },
  });
}

export function useCreateModel() {
  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["createModel"]>,
    number
  >({
    mutationKey: ["POST", "models", "models"],
    mutationFn: ({ data }) => data,
  });
}

export function useUpdateModel(modelId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["updateModel"]>,
    Model
  >({
    mutationKey: ["PUT", "models", "models", modelId],
    mutationFn: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ["models", "models", modelId, { includeMetrics: true }],
      });

      return deserializeModel(data);
    },
  });
}

export function useQueryModelAssignments(modelId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getModelAssignments"]>,
    {
      assignedAccountCount: number;
      assignedHouseholdCount: number;
    }
  >({
    queryKey: ["models", "models", modelId, "assignments"],
    queryFn: async (assignments) => assignments.data,
    queryOptions: { enabled: modelId !== 0 },
  });
}

export function useReplaceAccountHouseholdModel(
  oldModelId: number,
  newModelId: number,
) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["replaceModelForAccountsAndHouseholds"]>,
    void,
    void
  >({
    mutationKey: ["PUT", "models", "models", "replace", oldModelId, newModelId],
    mutationFn: () => {
      queryClient.invalidateQueries({
        queryKey: ["models", "models", oldModelId],
      });
      queryClient.invalidateQueries({
        queryKey: ["models", "models", { includeMetrics: true }],
      });
    },
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}/${key[2]}/${key[3]}${key[4] === "0" ? "" : `?newModelId=${key[4]}`}`,
    responseCallback: processEmptyResponse,
  });
}

export function deserializeModel<TModel extends Model>(
  model: SerializedObject<TModel>,
) {
  return {
    ...model,
    createdTime: deserializeDate(model.createdTime),
    updatedTime: deserializeDate(model.updatedTime),
  } as TModel;
}

export function useQuerySleeves({ includeMetrics = false } = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getAllSleeves"]>,
    ExtendedModelSleeve[],
    [
      string,
      string,
      {
        includeMetrics: boolean;
      },
    ]
  >({
    queryKey: ["models", "sleeves", { includeMetrics }],
    queryFn: async (sleeves) => sleeves.data.map(deserializeSleeve),
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}?metrics=${key[2].includeMetrics}`,
  });
}

export function useQuerySleeve(sleeveId: number, { enabled = true } = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getSleeve"]>,
    ExtendedModelSleeve
  >({
    queryKey: ["models", "sleeves", sleeveId],
    queryFn: async (sleeve) => deserializeSleeve(sleeve.data),
    queryOptions: { enabled },
  });
}

export function useCreateSleeve() {
  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["createSleeve"]>,
    number
  >({
    mutationKey: ["POST", "models", "sleeves"],
    mutationFn: ({ data }) => data,
  });
}

export function useUpdateSleeve(sleeveId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["updateSleeve"]>,
    Sleeve
  >({
    mutationKey: ["PUT", "models", "sleeves", sleeveId],
    mutationFn: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: ["models", "sleeves", sleeveId, { includeMetrics: true }],
      });

      return deserializeSleeve(data);
    },
  });
}

export function useQuerySleeveAssignments(sleeveId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getSleeveAssignments"]>,
    { id: number; name: string }[]
  >({
    queryKey: ["models", "sleeves", sleeveId, "assignments"],
    queryFn: async (assignments) => assignments.data,
    queryOptions: { enabled: sleeveId !== 0 },
  });
}

export function useDeleteSleeve(sleeveId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["deleteSleeve"]>,
    void,
    void
  >({
    mutationKey: ["DELETE", "models", "sleeves", sleeveId],
    mutationFn: () => {
      queryClient.invalidateQueries({
        queryKey: ["models", "sleeves", sleeveId],
      });
      queryClient.invalidateQueries({
        queryKey: ["models", "sleeves", { includeMetrics: true }],
      });
    },
    responseCallback: processEmptyResponse,
  });
}

export function deserializeSleeve<TSleeve extends Sleeve>(
  sleeve: SerializedObject<TSleeve>,
) {
  return {
    ...sleeve,
    createdTime: deserializeDate(sleeve.createdTime),
    updatedTime: deserializeDate(sleeve.updatedTime),
  } as TSleeve;
}

export function useQueryBenchmarks({ includeMetrics = false } = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getAllBenchmarks"]>,
    ExtendedBenchmark[],
    [
      string,
      string,
      {
        includeMetrics: boolean;
      },
    ]
  >({
    queryKey: ["models", "benchmarks", { includeMetrics }],
    queryFn: async (sleeves) => sleeves.data.map(deserializeBenchmark),
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}?metrics=${key[2].includeMetrics}`,
  });
}

export function useQueryBenchmark(
  benchmarkId: number,
  { enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getBenchmark"]>,
    ExtendedBenchmark
  >({
    queryKey: ["models", "benchmarks", benchmarkId],
    queryFn: async (benchmark) => deserializeBenchmark(benchmark.data),
    queryOptions: { enabled },
  });
}

export function useCreateBenchmark() {
  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["createBenchmark"]>,
    number
  >({
    mutationKey: ["POST", "models", "benchmarks"],
    mutationFn: ({ data }) => data,
  });
}

export function useUpdateBenchmark(benchmarkId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["updateBenchmark"]>,
    Benchmark
  >({
    mutationKey: ["PUT", "models", "benchmarks", benchmarkId],
    mutationFn: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [
          "models",
          "benchmarks",
          benchmarkId,
          { includeMetrics: true },
        ],
      });

      return deserializeBenchmark(data);
    },
  });
}

export function useDeleteBenchmark(benchmarkId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["deleteBenchmark"]>,
    void,
    void
  >({
    mutationKey: ["DELETE", "models", "benchmarks", benchmarkId],
    mutationFn: () => {
      queryClient.invalidateQueries({
        queryKey: ["models", "benchmarks", benchmarkId],
      });
      queryClient.invalidateQueries({
        queryKey: ["models", "benchmarks", { includeMetrics: true }],
      });
    },
    responseCallback: processEmptyResponse,
  });
}

export function deserializeBenchmark<TBenchmark extends Benchmark>(
  benchmark: SerializedObject<TBenchmark>,
) {
  return {
    ...benchmark,
    createdTime: deserializeDate(benchmark.createdTime),
    updatedTime: deserializeDate(benchmark.updatedTime),
  } as TBenchmark;
}

export function useQuerySecurityGroups({
  includeMetrics = false,
  enabled = true,
} = {}) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getAllSecurityGroups"]>,
    ExtendedSecurityGroup[],
    [
      string,
      string,
      {
        includeMetrics: boolean;
      },
    ]
  >({
    queryKey: ["models", "security-groups", { includeMetrics }],
    queryFn: async (securityGroups) =>
      securityGroups.data.map(deserializeSecurityGroup),
    urlBuilderFn: (key) =>
      `/${key[0]}/${key[1]}?metrics=${key[2].includeMetrics}`,
    queryOptions: { enabled },
  });
}

export function useQuerySecurityGroup(
  securityGroupId: number,
  { enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getSecurityGroup"]>,
    SecurityGroup
  >({
    queryKey: ["models", "security-groups", securityGroupId],
    queryFn: async (securityGroup) =>
      deserializeSecurityGroup(securityGroup.data),
    queryOptions: { enabled },
  });
}

export function useCreateSecurityGroup() {
  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["createSecurityGroup"]>,
    number,
    SecurityGroupRequest
  >({
    mutationKey: ["POST", "models", "security-groups"],
    mutationFn: ({ data }) => data,
  });
}

export function useUpdateSecurityGroup(securityGroupId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["updateSecurityGroup"]>,
    SecurityGroup,
    SecurityGroupRequest
  >({
    mutationKey: ["PUT", "models", "security-groups", securityGroupId],
    mutationFn: ({ data }) => {
      queryClient.invalidateQueries({
        queryKey: [
          "models",
          "security-groups",
          securityGroupId,
          { includeMetrics: true },
        ],
      });

      return deserializeSecurityGroup(data);
    },
  });
}

export function useQuerySecurityGroupAssignments(securityGroupId: number) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getSecurityGroupAssignments"]>,
    { id: number; name: string; type: "model" | "sleeve" }[]
  >({
    queryKey: ["models", "security-groups", securityGroupId, "assignments"],
    queryFn: async (assignments) => assignments.data,
    queryOptions: { enabled: securityGroupId !== 0 },
  });
}

export function useDeleteSecurityGroup(securityGroupId: number) {
  const queryClient = useQueryClient();

  return useAuthenticatedMutationNew<
    UnpackResponse<ModelsController["deleteSecurityGroup"]>,
    void,
    void
  >({
    mutationKey: ["DELETE", "models", "security-groups", securityGroupId],
    mutationFn: () => {
      queryClient.invalidateQueries({
        queryKey: ["models", "security-groups", securityGroupId],
      });
      queryClient.invalidateQueries({
        queryKey: ["models", "security-groups", { includeMetrics: true }],
      });
    },
    responseCallback: processEmptyResponse,
  });
}

export function deserializeSecurityGroup<TSecurityGroup extends SecurityGroup>(
  securityGroup: SerializedObject<TSecurityGroup>,
) {
  return {
    ...securityGroup,
    createdTime: deserializeDate(securityGroup.createdTime),
    updatedTime: deserializeDate(securityGroup.updatedTime),
  } as TSecurityGroup;
}

function getModelSecuritiesRequest(model: Partial<ModelRequestSecurities>) {
  return {
    primarySecurities: (model?.primarySecurities ?? []).map((security) => ({
      ...security,
      weight:
        typeof security.weight === "number"
          ? security.weight
          : parseInt(security.weight),
    })),
    securityGroups: (model?.securityGroups ?? [])
      .filter(
        (securityGroup) =>
          securityGroup.id &&
          securityGroup.id !== -1 &&
          securityGroup.weight !== 0,
      )
      .map((securityGroup) => ({
        ...securityGroup,
        weight:
          typeof securityGroup.weight === "number"
            ? securityGroup.weight
            : parseInt(securityGroup.weight),
      })),
    sleeves: (model?.sleeves ?? [])
      .filter((sleeve) => sleeve.id && sleeve.id !== -1 && sleeve.weight !== 0)
      .map((sleeve) => ({
        ...sleeve,
        weight:
          typeof sleeve.weight === "number"
            ? sleeve.weight
            : parseInt(sleeve.weight),
      })),
  };
}

export function useQueryModelPerformance(
  model: Partial<ModelRequestSecurities>,
  { enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getModelPerformance"]>,
    ModelPerformance
  >({
    queryKey: ["models", "performance"],
    queryFn: async (modelPerformance) => modelPerformance.data,
    queryOptions: { enabled, gcTime: 0 },
    fetchOptions: {
      method: "POST",
      body: getModelSecuritiesRequest(model),
    },
  });
}

export function useQueryModelAssetAllocation(
  model: Partial<ModelRequestSecurities>,
  { enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getModelAssetAllocation"]>,
    {
      category: string;
      allocation: number;
    }[]
  >({
    queryKey: ["models", "asset-allocation"],
    queryFn: async (assetAllocation) => assetAllocation.data,
    queryOptions: { enabled, gcTime: 0 },
    fetchOptions: {
      method: "POST",
      body: getModelSecuritiesRequest(model),
    },
  });
}

export function useQueryModelRiskAllocation(
  model: Partial<ModelRequestSecurities>,
  { enabled = true } = {},
) {
  return useAuthenticatedQuery<
    UnpackResponse<ModelsController["getModelRiskAllocation"]>,
    {
      current: RiskAllocation;
      target: RiskAllocation;
    }
  >({
    queryKey: ["models", "risk-allocation"],
    queryFn: async (riskAllocation) => riskAllocation.data,
    queryOptions: { enabled, gcTime: 0 },
    fetchOptions: {
      method: "POST",
      body: getModelSecuritiesRequest(model),
    },
  });
}
