import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const ModelsNav = () => (
  <nav>
    <Nav variant="tabs" defaultActiveKey="/models/models">
      <Nav.Item>
        <NavLink to="/models/models" className="nav-link">
          Models
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/models/sleeves" className="nav-link">
          Sleeves
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/models/security-groups" className="nav-link">
          Security Groups
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/models/benchmarks" className="nav-link">
          Benchmarks
        </NavLink>
      </Nav.Item>
    </Nav>
  </nav>
);

export default ModelsNav;
