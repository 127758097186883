import type { DashboardController } from "../../api/src/dashboard/dashboard.controller";
import type { UnpackResponse } from "../../api/src/lib";
import { useAuthenticatedQuery } from "./lib/api";
import { DateWithoutTime } from "./lib/date";

function useQueryDataDate() {
  return useAuthenticatedQuery<
    UnpackResponse<DashboardController["getAsOfDataTime"]>,
    Date | undefined
  >({
    queryKey: ["dashboard", "data-date"],
    queryFn: async (data) =>
      data?.data === null ? undefined : new DateWithoutTime(data.data),
  });
}

const DataDate = () => {
  const { data: date } = useQueryDataDate();
  return typeof date === "undefined" ? null : (
    <small>Account data as of {date.toLocaleDateString()}</small>
  );
};

export default DataDate;
