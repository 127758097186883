import { useCallback } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../components/SubmitButton";
import Loading from "../Loading";
import {
  useQueryModelAssignments,
  useReplaceAccountHouseholdModel,
} from "./lib";

const ModelDeleteDialog = ({
  showModal,
  setShowModal,
  deleteModelId,
  selectedModelId,
  setModelId,
  modelOptions,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteModelId: number;
  selectedModelId: number;
  setModelId: React.Dispatch<React.SetStateAction<number>>;
  modelOptions: JSX.Element[];
}) => {
  const navigate = useNavigate();

  // get assigned household and accounts count
  const { isLoading: isLoadingAssignments, data: assignmentCount } =
    useQueryModelAssignments(deleteModelId);

  const handleModalClose = useCallback(() => {
    setModelId(0);
    setShowModal(false);
  }, [setModelId, setShowModal]);

  // first replace current Model assignment to new for accounts and household
  const replaceAccountHouseholdModel = useReplaceAccountHouseholdModel(
    deleteModelId,
    selectedModelId,
  );

  const handleSaveChanges = useCallback(async () => {
    await replaceAccountHouseholdModel.mutateAsync();
    setShowModal(false);
    setModelId(0);
    navigate("/models");
  }, [navigate, replaceAccountHouseholdModel, setModelId, setShowModal]);

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete Model</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingAssignments ? (
          <Loading />
        ) : assignmentCount?.assignedHouseholdCount === 0 &&
          assignmentCount?.assignedAccountCount === 0 ? (
          <Row className="m-2">
            Are you sure you would like to delete this model?
          </Row>
        ) : (
          <>
            <Row className="m-2">
              This model is assigned to{" "}
              {assignmentCount?.assignedHouseholdCount} household(s) and{" "}
              {assignmentCount?.assignedAccountCount} account(s). Which model
              would you instead like to assign to these household(s) and
              account(s)?
            </Row>
            <Row className="m-2">
              <Form.Select
                placeholder="Select assigned model"
                value={selectedModelId}
                onChange={(e) => setModelId(+e.target.value)}
              >
                {modelOptions}
              </Form.Select>
            </Row>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          variant="danger"
          label="Delete"
          onClick={handleSaveChanges}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ModelDeleteDialog;
