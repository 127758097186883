import { useCallback } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import SubmitButton from "../components/SubmitButton";
import Loading from "../Loading";
import { useDeleteSleeve, useQuerySleeveAssignments } from "./lib";

const SleeveDeleteDialog = ({
  showModal,
  setShowModal,
  deleteSleeveId,
  setDeleteSleeveId,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteSleeveId: number;
  setDeleteSleeveId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const navigate = useNavigate();

  // get models using the sleeve
  const { isLoading: isLoadingAssignments, data: sleeveAssignments } =
    useQuerySleeveAssignments(deleteSleeveId);

  const handleModalClose = useCallback(() => {
    setDeleteSleeveId(0);
    setShowModal(false);
  }, [setDeleteSleeveId, setShowModal]);

  const deleteSleeve = useDeleteSleeve(deleteSleeveId);

  const handleDeleteSleeve = useCallback(async () => {
    await deleteSleeve.mutateAsync();
    setShowModal(false);
    navigate("/models/sleeves");
  }, [deleteSleeve, navigate, setShowModal]);

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete Sleeve</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingAssignments ? (
          <Loading />
        ) : sleeveAssignments?.length === 0 ? (
          <div className="m-2">
            Are you sure you would like to delete this sleeve?
          </div>
        ) : (
          <>
            <div className="m-2">
              This sleeve is attached to the below models. First, please
              un-attach the sleeve from below models.
            </div>
            <ListGroup as="ul">
              {sleeveAssignments?.map((model) => (
                <ListGroup.Item key={model.id} as="li">
                  <Link to={`/models/models/${model.id}`}>
                    <span>{model.name}</span>
                  </Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          hidden={sleeveAssignments?.length !== 0}
          variant="danger"
          label="Delete"
          onClick={handleDeleteSleeve}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default SleeveDeleteDialog;
