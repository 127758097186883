import { FilterFn, Header, Row as TanTableRow } from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useController, useForm } from "react-hook-form";
import type { ContactsController } from "../../../../api/src/contacts/contacts.controller";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import type {
  PageOrientation,
  RateOfReturn,
  ReportPeriod,
} from "../../../../api/src/reports/reports.service";
import type { TasksController } from "../../../../api/src/tasks/tasks.controller";
import Loading from "../../Loading";
import { useQueryCrmUsers } from "../../firm/lib";
import { useAuthenticatedFetch } from "../../lib/api";
import { InlineError } from "../../lib/display";
import DateInput from "../DateInput";
import FormFieldError from "../FormFieldError";
import MultiSelector from "../MultiSelector";
const AllOption = () => <option value="">All</option>;

export function numberStringFilter<TData>(
  row: TanTableRow<TData>,
  columnId: string,
  filterValue: string,
) {
  const value = row.getValue<number | string>(columnId);
  const valueStr = typeof value === "string" ? value : value.toString();
  return valueStr.includes(filterValue);
}

export const TextFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const value = (header.column.getFilterValue() ?? "") as string;

  const onChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      header.column.setFilterValue(ev.target.value);
    },
    [header.column],
  );

  return (
    <Form.Control
      type="text"
      value={value}
      onChange={onChange}
      placeholder="Search"
    />
  );
};

// takes two date strings and returns Date objects
function toDate(filterValue: [string, string]): [Date | null, Date | null] {
  const [startStr, endStr] = filterValue;
  const start = startStr ? new Date(startStr) : null;
  const end = endStr ? new Date(endStr) : null;
  return [start, end];
}

// Date filter function
export const dateFilterFn: FilterFn<any> = (row, columnId, filterValue) => {
  if (!row || !columnId) {
    return false;
  }

  const date = new Date(row.getValue(columnId) as Date);
  const [start, end] = toDate(filterValue);

  if (end) {
    // If end date is defined, set time to end of day
    end.setHours(23, 59, 59, 999);
  }
  // If start date is defined, check if date is after or equal to start
  if (start && date.getTime() < start.getTime()) {
    return false;
  }

  // If end date is defined, check if date is before or equal to end
  if (end && date.getTime() > end.getTime()) {
    return false;
  }

  // If neither start nor end date is defined, or date is between start and end, return true
  return true;
};

export const multiSelectIncludes: FilterFn<any> = (
  row,
  columnId,
  filterValue: unknown[],
) => {
  return (
    // Migrated saved filters may not be an array value
    !(filterValue instanceof Array) ||
    filterValue.length <= 0 ||
    filterValue.some((val) =>
      row.getValue(columnId) instanceof Array
        ? (row.getValue(columnId) as unknown[]).some(
            (rowVal) => String(rowVal) === String(val),
          )
        : String(val) === row.getValue(columnId)?.toString(),
    )
  );
};

export const DateRangeFilter = <TRow, TValue>({
  header,
  name,
}: {
  header: Header<TRow, TValue>;
  name: string; // name of the filter
}) => {
  const { control } = useForm();

  const {
    field: { value: valueStart },
    fieldState: { error: startError },
  } = useController({
    // react-hook-form hook to control form values
    name: `${name}Start`,
    control,
    defaultValue: "",
  });

  const {
    field: { value: valueEnd },
  } = useController({
    name: `${name}End`,
    control,
    defaultValue: "",
  });

  useEffect(() => {
    const value = [valueStart, valueEnd];

    if (!valueStart && !valueEnd) {
      // If both start and end dates are not defined, remove the filter
      header.column.setFilterValue(undefined);
    } else {
      header.column.setFilterValue(value);
    }
  }, [valueStart, valueEnd, header]);

  useEffect(() => {
    if (valueStart && valueEnd && new Date(valueStart) > new Date(valueEnd)) {
      control.setError(`${name}Start`, {
        type: "validate",
        message: "Start date cannot be later than end date",
      });
    } else {
      control.setError(`${name}Start`, {
        type: "validate",
        message: undefined,
      });
    }
  }, [valueStart, valueEnd, control, name]);

  return (
    <>
      <DateInput name={`${name}Start`} control={control} />
      <DateInput name={`${name}End`} control={control} />
      {startError && <FormFieldError field={startError} />}
    </>
  );
};

export const yesNoOptions = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

export const YesNoSingleFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const onChange = useCallback(
    (values: number[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return (
    <MultiSelector
      options={yesNoOptions}
      placeholder="All"
      setValue={onChange}
    />
  );
};

export const StatusFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const value = (header.column.getFilterValue() ?? undefined) as
    | string
    | undefined;

  const onChange = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      header.column.setFilterValue(
        ev.currentTarget.value === ""
          ? undefined
          : ev.currentTarget.value === "true",
      );
    },
    [header.column],
  );

  return (
    <Form.Select value={value} onChange={onChange} placeholder="All">
      <AllOption />
      <option value="true">Active</option>
      <option value="false">Inactive</option>
    </Form.Select>
  );
};

export const AssignedToFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const {
    isPending: isPendingAssignees,
    isError: isErrorAssignees,
    data: dataAssignees,
  } = useQueryCrmUsers();

  const assigneeOptions = useMemo(
    () =>
      (dataAssignees?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((assignee) => ({
          label: assignee.name,
          value: assignee.id,
        })),
    [dataAssignees?.data],
  );

  const onChange = useCallback(
    (values: number[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return isPendingAssignees ? (
    <Loading />
  ) : isErrorAssignees ? (
    <InlineError>Failed to load assignees</InlineError>
  ) : (
    <MultiSelector
      options={assigneeOptions}
      placeholder="All"
      setValue={onChange}
    />
  );
};

export const TagFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const {
    isLoading: isLoadingTags,
    isError: isErrorTags,
    data: dataTags,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<ContactsController["getAllTags"]>>
  >("/contacts/tags?document_type=Contact");

  const tagOptions = useMemo(
    () =>
      (dataTags?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((tag) => ({
          label: tag.name,
          value: tag.name,
        })),
    [dataTags?.data],
  );

  const onChange = useCallback(
    (values: string[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return isLoadingTags ? (
    <Loading />
  ) : isErrorTags ? (
    <InlineError>Failed to load Tags</InlineError>
  ) : (
    <MultiSelector options={tagOptions} placeholder="All" setValue={onChange} />
  );
};

export const CategoryFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const {
    isPending: isPendingCategories,
    isError: isErrorCategories,
    data: dataCategories,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<TasksController["getCategories"]>>
  >("/tasks/categories");

  const categoryOptions = useMemo(
    () =>
      (dataCategories?.data ?? [])
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map((category) => ({
          label: category.name,
          value: category.id,
        })),
    [dataCategories?.data],
  );

  const onChange = useCallback(
    (values: number[]) => {
      const labels = values
        .map((value) => {
          const option = categoryOptions.find(
            (option) => option.value === value,
          );
          return option ? option.label : null;
        })
        .filter((label) => label !== null);
      header.column.setFilterValue(labels);
    },
    [categoryOptions, header.column],
  );

  return isPendingCategories ? (
    <Loading />
  ) : isErrorCategories ? (
    <InlineError>Failed to load categories</InlineError>
  ) : (
    <MultiSelector
      options={categoryOptions}
      placeholder="All"
      setValue={onChange}
    />
  );
};

export const priorityOptions = [
  {
    label: "Low",
    value: 1,
  },
  {
    label: "Medium",
    value: 2,
  },
  {
    label: "High",
    value: 3,
  },
];

export const PriorityFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const onChange = useCallback(
    (values: number[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return (
    <MultiSelector
      options={priorityOptions}
      placeholder="All"
      setValue={onChange}
    />
  );
};

export const transactionTypeOptions = [
  { label: "Income", value: 1 },
  { label: "Expense", value: 2 },
  { label: "Transfer", value: 3 },
  { label: "Trade", value: 4 },
  { label: "Other", value: 5 },
];
export const TransactionTypeFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const onChange = useCallback(
    (values: number[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return (
    <MultiSelector
      options={transactionTypeOptions}
      placeholder="All"
      setValue={onChange}
    />
  );
};

export const transactionSubTypeOptions = [
  { label: "Deposits", value: 1 },
  { label: "Withdrawals", value: 2 },
  { label: "Transfers", value: 3 },
  { label: "Buys", value: 4 },
  { label: "Sells", value: 5 },
  { label: "Dividends/Interest", value: 6 },
  { label: "Maturity", value: 7 },
  { label: "Dividend Reinvestment", value: 8 },
  { label: "Split", value: 9 },
  { label: "Tax", value: 10 },
  { label: "Sweep", value: 11 },
  { label: "Capital Gain Distribution", value: 12 },
  { label: "Fees", value: 13 },
  { label: "Other Income", value: 14 },
  { label: "Other Expense", value: 15 },
  { label: "Symbol Change", value: 16 },
  { label: "Unknown", value: 17 },
];

export const TransactionSubTypeFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const onChange = useCallback(
    (values: number[]) => {
      header.column.setFilterValue(values);
    },
    [header.column],
  );

  return (
    <MultiSelector
      options={transactionSubTypeOptions}
      placeholder="All"
      setValue={onChange}
    />
  );
};

export const ReportPeriodFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const value = (header.column.getFilterValue() ?? undefined) as
    | ReportPeriod
    | undefined;

  const onChange = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      header.column.setFilterValue(ev.target.value);
    },
    [header.column],
  );

  return (
    <Form.Select value={value} onChange={onChange} placeholder="All">
      <AllOption />
      <option value="M">Monthly</option>
      <option value="Q">Quarterly</option>
    </Form.Select>
  );
};

export const ReportPageOrientationFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const value = (header.column.getFilterValue() ?? undefined) as
    | PageOrientation
    | undefined;

  const onChange = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      header.column.setFilterValue(ev.target.value);
    },
    [header.column],
  );

  return (
    <Form.Select value={value} onChange={onChange} placeholder="All">
      <AllOption />
      <option value="M">Monthly</option>
      <option value="Q">Quarterly</option>
    </Form.Select>
  );
};

export const ReportRateOfReturnFilter = <TRow, TValue>({
  header,
}: {
  header: Header<TRow, TValue>;
}) => {
  const value = (header.column.getFilterValue() ?? undefined) as
    | RateOfReturn
    | undefined;

  const onChange = useCallback(
    (ev: React.ChangeEvent<HTMLSelectElement>) => {
      header.column.setFilterValue(ev.target.value);
    },
    [header.column],
  );

  return (
    <Form.Select value={value} onChange={onChange} placeholder="All">
      <AllOption />
      <option value="time-weighted">Time-Weighted</option>
      <option value="internal">Internal</option>
    </Form.Select>
  );
};
