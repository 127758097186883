import { useContext } from "react";
import { Alert, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { HouseholdsController } from "../../../api/src/households/households.controller";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import AllocationBar from "../components/AllocationBar/AllocationBar";
import AssetAllocationBarChart, {
  AssetAllocationSeries,
} from "../components/AssetAllocationBarChart";
import LabelValuePair from "../components/LabelValuePair";
import { useAuthenticatedFetch } from "../lib/api";
import { naLabel, pendingLabel } from "../lib/display";
import { formatCurrency } from "../lib/numbers";
import { useQueryModel } from "../models/lib";
import RebalanceHoldings from "./RebalanceHoldings";
import { RebalanceContext } from "./RebalanceInfo";
import { getRiskAllocation, rebalanceTypeDisplay } from "./lib";

const RebalanceSummary = () => {
  const { rebalance, household } = useContext(RebalanceContext);
  const trades = rebalance?.accounts.flatMap((account) => account.trades) ?? [];

  const {
    isLoading: isLoadingModel,
    isError: isErrorModel,
    data: dataModel,
  } = useQueryModel(household?.assignedModelId ?? -1, {
    enabled:
      typeof household !== "undefined" &&
      typeof household.assignedModelId !== "undefined" &&
      household.assignedModelId !== null,
  });

  const {
    isLoading: isLoadingHouseholdAllocation,
    isError: isErrorHouseholdAllocation,
    data: dataHouseholdAllocation,
  } = useAuthenticatedFetch<
    UnpackResponse<HouseholdsController["getAllocationDetails"]>
  >(`/households/${household?.id}/allocation-details`, undefined, {
    enabled: typeof household !== "undefined",
  });

  const assetAllocationSeries: AssetAllocationSeries[] = [
    {
      label: "Current",
      data: dataHouseholdAllocation?.data.current ?? [],
    },
    {
      label: "Post-Rebalance",
      data: rebalance?.assetAllocation ?? [],
    },
    {
      label: "Target",
      data: dataHouseholdAllocation?.data.target ?? [],
    },
  ];

  const buyTotal = trades
    .filter((trade) => trade.side === "buy")
    .reduce((sum, trade) => sum + trade.amount * trade.price, 0);

  const sellTotal = trades
    .filter((trade) => trade.side === "sell")
    .reduce((sum, trade) => sum + trade.amount * trade.price, 0);

  const realizedGainLoss =
    (rebalance?.realizedGainLossSt ?? 0) + (rebalance?.realizedGainLossLt ?? 0);

  const currencyLabelValueProps = {
    orientation: "horizontal" as "horizontal" | "vertical",
    widths: { label: { lg: 3, xs: 12 }, value: { lg: 4 } },
    classNameValue: "text-lg-end",
    plaintext: true,
  };

  return typeof rebalance === "undefined" ? (
    <Loading />
  ) : (
    <Row>
      <Col xl={6} className="mb-3">
        <LabelValuePair
          label="Rebalance Reason"
          value={rebalanceTypeDisplay[rebalance.type]}
          orientation="horizontal"
          widths={{ label: { xl: 4, lg: 3 } }}
          plaintext
        />
        <LabelValuePair
          label="Current Model"
          value={
            isLoadingModel ? (
              <Loading />
            ) : isErrorModel ? (
              <span className="text-danger">Failed to load model</span>
            ) : typeof dataModel === "undefined" ? (
              naLabel
            ) : (
              <Link to={`/models/models/${dataModel.id}`}>
                {dataModel.name}
              </Link>
            )
          }
          orientation="horizontal"
          widths={{ label: { xl: 4, lg: 3 } }}
          plaintext
        />
        <LabelValuePair
          label="AUM"
          value={
            rebalance.status === "requested"
              ? pendingLabel
              : formatCurrency(household?.householdBalance ?? 0, 2)
          }
          {...currencyLabelValueProps}
        />
        <LabelValuePair
          label="Cash"
          value={
            rebalance.status === "requested"
              ? pendingLabel
              : formatCurrency(household?.cashBalance ?? 0, 2)
          }
          {...currencyLabelValueProps}
        />
        <LabelValuePair
          label="Buy $"
          value={
            rebalance.status === "requested"
              ? pendingLabel
              : formatCurrency(buyTotal, 2)
          }
          {...currencyLabelValueProps}
        />
        <LabelValuePair
          label="Sell $"
          value={
            rebalance.status === "requested"
              ? pendingLabel
              : formatCurrency(sellTotal, 2)
          }
          {...currencyLabelValueProps}
        />
        <LabelValuePair
          label="Net $"
          value={
            rebalance.status === "requested"
              ? pendingLabel
              : formatCurrency(sellTotal - buyTotal, 2)
          }
          {...currencyLabelValueProps}
        />
        <LabelValuePair
          label="Realized Gains"
          value={
            rebalance.status === "requested"
              ? pendingLabel
              : formatCurrency(realizedGainLoss, 2)
          }
          {...currencyLabelValueProps}
        />
        <LabelValuePair
          label="Current Allocation"
          value={
            <AllocationBar
              data={rebalance.riskAllocation?.current}
              converter={getRiskAllocation}
            />
          }
          orientation="horizontal"
          widths={{ label: { xl: 4, lg: 3 }, value: { xl: 6, lg: 6 } }}
          plaintext
        />
        <LabelValuePair
          label="Target Allocation"
          value={
            rebalance.status === "requested" ? (
              pendingLabel
            ) : (
              <AllocationBar
                data={rebalance.riskAllocation?.target}
                converter={getRiskAllocation}
              />
            )
          }
          orientation="horizontal"
          widths={{ label: { xl: 4, lg: 3 }, value: { xl: 6, lg: 6 } }}
          plaintext
        />
        <LabelValuePair
          label="Post-Rebalance Allocation"
          value={
            rebalance.status === "requested" ? (
              pendingLabel
            ) : (
              <AllocationBar
                data={rebalance.riskAllocation?.postRebalance}
                converter={getRiskAllocation}
              />
            )
          }
          orientation="horizontal"
          widths={{ label: { xl: 4, lg: 3 }, value: { xl: 6, lg: 6 } }}
          plaintext
        />
      </Col>
      <Col xl={6}>
        {isLoadingHouseholdAllocation ? (
          <Loading />
        ) : isErrorHouseholdAllocation ? (
          <Alert variant="danger">Failed to load asset allocation</Alert>
        ) : (
          <AssetAllocationBarChart data={assetAllocationSeries} />
        )}
      </Col>
      <Col xs={12}>
        <RebalanceHoldings />
      </Col>
    </Row>
  );
};

export default RebalanceSummary;
