import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import type { ModelRequestSecurities } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import FastTrackAttribution from "../components/FastTrackAttribution";
import { useDebounce } from "../lib/forms";
import { formatPercent } from "../lib/numbers";
import { useQueryModelPerformance } from "./lib";

const ModelPerformance = ({
  model,
  isLoading = false,
}: {
  model: Partial<ModelRequestSecurities>;
  isLoading: boolean;
}) => {
  const {
    isPending: isPendingPerformance,
    isError: isErrorPerformance,
    data: dataPerformance,
    refetch,
  } = useQueryModelPerformance(model, { enabled: false });

  const [debouncedRefetch] = useDebounce(
    async () => {
      await refetch();
    },
    isPendingPerformance ? 0 : 1000,
  );

  useEffect(() => {
    if (!isLoading) {
      debouncedRefetch();
    }
    // debounceRefetch is a new instance every timer cycle;
    // Using it as a dependency causes this hook to refire repeatedly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, isLoading]);

  return isLoading || isPendingPerformance ? (
    <Loading />
  ) : isErrorPerformance || typeof dataPerformance === "undefined" ? (
    <Alert variant="danger">Failed to load model performance</Alert>
  ) : (
    <div>
      <h4>Performance</h4>
      <dl>
        <dt>Expense Ratio</dt>
        <dd>{formatPercent(dataPerformance.expenseRatio, 2)}</dd>
        <dt>Dividend Yield</dt>
        <dd>{formatPercent(dataPerformance.dividendYield, 2)}</dd>
        <dt>1 Year</dt>
        <dd>{formatPercent(dataPerformance.returns.oneYear, 2)}</dd>
        <dt>3 Years</dt>
        <dd>{formatPercent(dataPerformance.returns.threeYear, 2)}</dd>
        <dt>5 Years</dt>
        <dd>{formatPercent(dataPerformance.returns.fiveYear, 2)}</dd>
      </dl>
      <p>
        <FastTrackAttribution />
      </p>
    </div>
  );
};

export default ModelPerformance;
