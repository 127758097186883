import { Form } from "react-bootstrap";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

function DateInput<TFieldValues extends FieldValues = FieldValues>({
  name,
  control,
  isEditable = true,
}: {
  name: FieldPath<TFieldValues>;
  control: Control<TFieldValues>;
  isEditable?: boolean;
}) {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <Form.Control
          {...field}
          type="date"
          readOnly={!isEditable}
          value={
            typeof field.value === "string"
              ? field.value.substring(0, 10)
              : field.value?.toISOString().substring(0, 10)
          }
        />
      )}
      control={control}
    />
  );
}

export default DateInput;
