import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { Button, ButtonGroup, Form, Table } from "react-bootstrap";
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  Path,
  UseFieldArrayRemove,
  UseFormRegister,
} from "react-hook-form";
import * as yup from "yup";
import type { FirmsController } from "../../../api/src/firms/firms.controller";
import type {
  AdvisorCodeRequest,
  CustodianCode,
} from "../../../api/src/firms/firms.service";
import type { UnpackResponse } from "../../../api/src/lib";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { useErrorMessage } from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import { useAuthenticatedMutationAsync } from "../lib/api";
import { custodianCodeToDisplayName } from "../lib/custodian";
import { MAX_DATE } from "../lib/date";
import { addRemoveLabel } from "../lib/display";

export const custodianCodeValues: CustodianCode[] = [
  "SWB",
  "NFS",
  "TDA",
  "IBK",
  "PER",
  "TIA",
  "APX",
  "MLT",
  "RJA",
  "DST",
];

export const advisorCodeSchema = yup
  .array()
  .of(
    yup.object({
      accountNumber: yup.string().required().label("Account Number"),
      custodian: yup
        .string()
        .oneOf(custodianCodeValues)
        .required()
        .label("Custodian"),
      closeDate: yup.date().required().label("Close Date"),
    }),
  )
  .required();

type AdvisorCodeField = AdvisorCodeRequest & {
  _field_id: string;
};

const emptyAdvisorCode: AdvisorCodeField = {
  accountNumber: "",
  custodian: "SWB",
  closeDate: MAX_DATE,
  _field_id: "",
};

/**
 * A multi-item form component for primary securities on an investing model. This component
 * displays an arbitrary number of rows for users to view and modify primary securities.
 */
function AdvisorCodesEditor<
  TForm extends { advisorCodes: AdvisorCodeRequest[] },
>({
  fields,
  add,
  remove,
  register,
  onRetire,
  errors,
}: {
  /** The collection of primary securities for a model */
  fields: AdvisorCodeField[];
  /** The setter function for the fields prop */
  replace: (primarySecurities: AdvisorCodeField[]) => void;
  add: (primarySecurity: AdvisorCodeField) => void;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<TForm>;
  onRetire?: (code: string, custodian: CustodianCode, closeDate: Date) => void;
  errors?: Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<AdvisorCodeRequest>> | undefined)[]
  >;
}) {
  const addAdvisorCode = useCallback(() => {
    add({ ...emptyAdvisorCode });
  }, [add]);

  const removeAdvisorCode = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove],
  );

  const custodianOptions = useMemo(
    () =>
      custodianCodeValues.map((custodianCode) => (
        <option key={custodianCode} value={custodianCode}>
          {custodianCodeToDisplayName(custodianCode)}
        </option>
      )),
    [],
  );

  const quickRemove = typeof onRetire === "undefined";

  const [selectedAdvisorCode, setSelectedAdvisorCode] = useState<{
    code: string;
    custodian: CustodianCode;
  }>();
  const [showModal, setShowModal] = useState(false);
  const {
    setError: setAdvisorCodeError,
    resetError: resetAdvisorCodeError,
    errorMessage: advisorCodeErrorMessage,
  } = useErrorMessage();

  const onRetireAdvisorCode = useCallback(
    (code: string, custodian: CustodianCode) => {
      setSelectedAdvisorCode({ code, custodian });
      setShowModal(true);
    },
    [],
  );

  const onCloseRetire = useCallback(() => {
    setSelectedAdvisorCode(undefined);
    setShowModal(false);
  }, []);

  const retireAdvisorCode = useAuthenticatedMutationAsync<
    UnpackResponse<FirmsController["editAdvisorCode"]>
  >(
    "/firm/advisor-codes",
    async ({
      code,
      custodian,
      closeDate,
    }: {
      code: string;
      custodian: CustodianCode;
      closeDate: Date;
    }) => ({
      method: "PATCH",
      body: JSON.stringify({
        code,
        custodian,
        closeDate,
      }),
    }),
  );

  const onConfirmRetireAdvisorCode = useMutation({
    mutationFn: async () => {
      resetAdvisorCodeError();

      try {
        if (typeof selectedAdvisorCode !== "undefined") {
          const closeDate = new Date();
          await retireAdvisorCode({ ...selectedAdvisorCode, closeDate });

          if (typeof onRetire !== "undefined") {
            onRetire(
              selectedAdvisorCode.code,
              selectedAdvisorCode.custodian,
              closeDate,
            );
            onCloseRetire();
          }
        }
      } catch (err) {
        const message = "Failed to retire advisor code";
        console.error(message, err);
        setAdvisorCodeError(message);
      }
    },
  });

  return (
    <>
      <Table className="form-table">
        <thead>
          <tr>
            <th>Custodian</th>
            <th>Account Number</th>
            <th>{addRemoveLabel}</th>
          </tr>
        </thead>
        <tbody>
          {fields.length <= 0 ? (
            <tr>
              <td>Please add a master account</td>
              <td>{/* Empty */}</td>
              <td>
                <Button onClick={addAdvisorCode}>+</Button>
              </td>
            </tr>
          ) : (
            fields.map((advisorCode, index) => (
              <tr key={advisorCode._field_id}>
                <td className="align-top">
                  <Form.Select
                    placeholder="Select custodian"
                    {...register(
                      `advisorCodes.${index}.custodian` as Path<TForm>,
                    )}
                    disabled={typeof advisorCode.code !== "undefined"}
                  >
                    {custodianOptions}
                  </Form.Select>
                  <FormFieldError field={errors?.[index]?.custodian} />
                </td>
                <td className="align-top">
                  <Form.Control
                    type="text"
                    placeholder="xxxx-xxxx"
                    {...register(
                      `advisorCodes.${index}.accountNumber` as Path<TForm>,
                    )}
                    disabled={typeof advisorCode.code !== "undefined"}
                  />
                  <FormFieldError field={errors?.[index]?.accountNumber} />
                </td>
                <td className="align-top text-nowrap">
                  {quickRemove ||
                  typeof advisorCode.code === "undefined" ||
                  advisorCode.closeDate <= new Date() ? null : (
                    <Button
                      onClick={() =>
                        onRetireAdvisorCode(
                          advisorCode.code ?? advisorCode.accountNumber,
                          advisorCode.custodian,
                        )
                      }
                      variant="danger"
                      className="me-2"
                    >
                      Retire
                    </Button>
                  )}
                  <ButtonGroup>
                    {!quickRemove &&
                    typeof advisorCode.code !== "undefined" ? null : (
                      <Button onClick={removeAdvisorCode(index)}>-</Button>
                    )}
                    {index !== fields.length - 1 ? null : (
                      <Button onClick={addAdvisorCode}>+</Button>
                    )}
                  </ButtonGroup>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <ConfirmationDialog
        title="Retire Master Account"
        message="Are you sure you want to retire this master account? This will disable access to any accounts associated with it."
        showModal={showModal}
        errorMessage={advisorCodeErrorMessage}
        isConfirming={onConfirmRetireAdvisorCode.isPending}
        handleConfirm={onConfirmRetireAdvisorCode.mutateAsync}
        handleClose={onCloseRetire}
      />
    </>
  );
}

export default AdvisorCodesEditor;
