import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../Loading";
import { RebalanceContext } from "./RebalanceInfo";
import HouseholdPerformance from "../clients/household/HouseholdPerformance";
import HouseholdTaxSummary from "../clients/household/HouseholdTaxSummary";

const RebalanceHouseholdDetails = () => {
  const { rebalance, household } = useContext(RebalanceContext);

  return typeof rebalance === "undefined" ? (
    <Loading />
  ) : (
    <Row>
      <Col xl={4} className="mb-3">
        <h4>Performance</h4>
        {household && (
          <HouseholdPerformance household={household} useall={true} />
        )}
      </Col>
      <Col xl={4} className="mb-3">
        {household && (
          <HouseholdTaxSummary household={household} useall={true} />
        )}
      </Col>
    </Row>
  );
};

export default RebalanceHouseholdDetails;
