import _ from "lodash";
import { useCallback, useMemo } from "react";
import { Button, ButtonGroup, Form, Table } from "react-bootstrap";
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  Path,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";
import type { SecondarySecurity } from "../../../api/src/models/models.service";
import FormFieldError from "../components/FormFieldError";
import SymbolField from "../components/SymbolField";
import { addRemoveLabel } from "../lib/display";
import { useQuerySecurities } from "../lib/security";

type SecondarySecurityField = SecondarySecurity & {
  _field_id: string;
};

const emptySecondarySecurity: SecondarySecurityField = {
  symbol: "",
  treatment: "like_primary",
  _field_id: "",
};

/**
 * A multi-item form component for secondary securities on an investing model. This component
 * displays an arbitrary number of rows for users to view and modify secondary securities.
 */
function SecurityGroupSecondarySecurities<
  TForm extends { secondarySecurities: SecondarySecurity[] },
>({
  fields,
  replace,
  add,
  remove,
  register,
  control,
  getValues,
  errors,
}: {
  /** The collection of secondary securities for a model */
  fields: SecondarySecurityField[];
  /** The setter function for the secondary securities prop */
  replace: (secondarySecurities: SecondarySecurityField[]) => void;
  add: (secondarySecurity: SecondarySecurityField) => void;
  remove: UseFieldArrayRemove;
  register: UseFormRegister<TForm>;
  control: Control<TForm>;
  getValues: UseFormGetValues<TForm>;
  errors?: Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<SecondarySecurity>> | undefined)[]
  >;
}) {
  const addSecondarySecurity = useCallback(() => {
    add({ ...emptySecondarySecurity });
  }, [add]);

  const removeSecondarySecurity = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove],
  );

  const { data: securities } = useQuerySecurities(
    fields.map((security) => security.symbol),
  );

  const symbolsMap = useMemo(
    () => _.keyBy(securities ?? [], "identifier"),
    [securities],
  );

  return (
    <Table className="form-table form-table-sm">
      <thead>
        <tr>
          <th>Symbol</th>
          <th>Treatment</th>
          <th>{addRemoveLabel}</th>
        </tr>
      </thead>
      <tbody>
        {fields.length <= 0 ? (
          <tr>
            <td>None</td>
            <td>{/* Empty */}</td>
            <td>
              <Button onClick={addSecondarySecurity}>+</Button>
            </td>
          </tr>
        ) : (
          fields.map((security, index) => (
            <tr key={security._field_id}>
              <td className="align-top">
                <SymbolField
                  fieldName={
                    `secondarySecurities.${index}.symbol` as Path<TForm>
                  }
                  register={register}
                  control={control}
                  getValues={getValues}
                  description={symbolsMap[security.symbol]?.description}
                />
                <FormFieldError field={errors?.[index]?.symbol} />
              </td>
              <td className="align-top">
                <Form.Select
                  placeholder="Select"
                  {...register(
                    `secondarySecurities.${index}.treatment` as Path<TForm>,
                  )}
                >
                  <option value="like_primary">Treat like primary</option>
                  <option value="hold_only">Hold only</option>
                  <option value="sell_no_tax">
                    Sell if no tax consequences
                  </option>
                </Form.Select>
                <FormFieldError field={errors?.[index]?.treatment} />
              </td>
              <td className="align-top">
                <ButtonGroup>
                  <Button onClick={removeSecondarySecurity(index)}>-</Button>
                  {index !== fields.length - 1 ? null : (
                    <Button onClick={addSecondarySecurity}>+</Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
}

export default SecurityGroupSecondarySecurities;
