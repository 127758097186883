import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ButtonToolbar, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import type { AccountsController } from "../../../../api/src/accounts/accounts.controller";
import type { Account } from "../../../../api/src/accounts/accounts.service";
import type { HouseholdsController } from "../../../../api/src/households/households.controller";
import type { SerializedObject, UnpackResponse } from "../../../../api/src/lib";
import type {
  EditableTask,
  EditableWorkflow,
} from "../../../../api/src/tasks/tasks.service";
import Loading from "../../Loading";
import { NotificationContext } from "../../Notifications";
import ActionButton from "../../components/ActionButton";
import CreateTaskPane from "../../components/CreateTaskPane";
import CreateWorkflowPane from "../../components/CreateWorkflowPane";
import FormFieldError from "../../components/FormFieldError";
import { NoteForm, NotesPane } from "../../components/Notes";
import SubmitButton from "../../components/SubmitButton";
import SummaryPill from "../../components/SummaryPill";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../../lib/api";
import { deserializeNote } from "../../lib/notes";
import { formatCurrency } from "../../lib/numbers";
import { deserializeTask, deserializeWorkflow } from "../../tasks/lib";
import { useQueryHousehold } from "../household/lib";
import AccountArchiveDialog from "./AccountArchiveDialog";
import { AccountContext } from "./AccountInfo";
import { useUnarchiveAccount } from "./lib";

const schema: yup.ObjectSchema<{ name: string }> = yup.object({
  name: yup.string().required().label("Name"),
});

const AccountNameField = ({
  account,
  accountId,
}: {
  account?: Account;
  accountId?: string;
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    setFocus,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<{ name: string }>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset({ name: account?.displayName });
  }, [account?.displayName, reset]);

  const updateAccountName = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<AccountsController["updateName"]>>
  >(`/accounts/${accountId}/name`, async (name: string) => ({
    method: "PUT",
    body: JSON.stringify({ name }),
  }));

  const queryClient = useQueryClient();
  const notificationContext = useContext(NotificationContext);

  const [enableNameField, setEnableNameField] = useState(false);

  const onSubmit = useMutation({
    mutationFn: async (name: string) => {
      try {
        await updateAccountName(name);
        setEnableNameField(false);
        queryClient.setQueryData(
          ["accounts", parseInt(accountId ?? ""), true, false],
          {
            data: { ...account, displayName: name },
          },
        );
        reset({ name });
        notificationContext.pushNotification({
          id: `account-${accountId}-name`,
          header: "Account Name Updated",
          body: `${name} updated`,
          variant: "success",
        });
      } catch (err) {
        console.error("Failed to save account name", err);
        notificationContext.pushNotification({
          id: `account-${accountId}-name`,
          header: "Failed to Update Account Name",
          body: `${name} was not saved`,
          variant: "danger",
        });
      }
    },
  });

  const onSaveName = useCallback(
    (ev: React.MouseEvent) => {
      if (!enableNameField) {
        ev.preventDefault();
        setEnableNameField(true);
      }
    },
    [enableNameField],
  );

  // Focus the name field on toggle. This does not work if we call
  // it before the re-render that displays the input field, so we
  // have to watch it using the effect hook.
  useEffect(() => {
    if (enableNameField) {
      setFocus("name");
    }
  }, [enableNameField, setFocus]);

  return (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data.name))}>
      <div className="d-flex align-items-center">
        <div style={{ maxWidth: 360 }}>
          {enableNameField ? null : (
            <span className="fs-3 fw-semibold lh-1 text-wrap">
              {getValues("name")}
            </span>
          )}
          <Form.Group controlId="form-name" hidden={!enableNameField}>
            <Form.Control {...register("name")} type="text" size="lg" />
            <FormFieldError field={errors.name} />
          </Form.Group>
        </div>
        <SubmitButton
          variant="icon"
          icon={enableNameField ? "/icons/save.svg" : "/icons/edit.svg"}
          label={enableNameField ? "Save" : "Edit"}
          type={enableNameField ? "submit" : "button"}
          onClick={onSaveName}
          isSubmitting={isSubmitting}
          className="ms-2 me-3"
        />
      </div>
    </Form>
  );
};

const AccountHeader = () => {
  const { account } = useContext(AccountContext);
  const { accountId } = useParams();

  const { isFetching: isFetchingHousehold, data: household } =
    useQueryHousehold(account?.householdId ?? 0, {
      enabled: typeof account?.householdId !== "undefined",
    });
  const linkedToCrm =
    typeof household?.idExternal.wealthbox !== "undefined" ||
    typeof household?.idExternal.redtail !== "undefined";

  const [showNotes, setShowNotes] = useState(false);
  const onShowNotes = useCallback(() => {
    setShowNotes(!showNotes);
  }, [showNotes]);

  const {
    isFetching: isFetchingNotes,
    isError: isErrorNotes,
    data: dataNotes,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<HouseholdsController["getHouseholdNotes"]>>
  >(`/households/${account?.householdId}/notes`, undefined, {
    enabled: linkedToCrm && typeof account?.householdId !== "undefined",
  });

  const notes = useMemo(
    () =>
      typeof dataNotes?.data === "undefined"
        ? undefined
        : dataNotes.data.map(deserializeNote),
    [dataNotes?.data],
  );

  const createNote = useAuthenticatedMutationAsync<
    SerializedObject<
      UnpackResponse<HouseholdsController["createHouseholdNote"]>
    >
  >(`/households/${account?.householdId}/notes`, async (data: NoteForm) => ({
    method: "POST",
    body: JSON.stringify(data),
  }));

  const queryClient = useQueryClient();

  const onCreateNote = useCallback(
    async (data: NoteForm) => {
      const body = await createNote(data);
      queryClient.setQueryData([`/households/${account?.householdId}/notes`], {
        data: [...(notes ?? []), body.data],
      });
      return body.data === null ? null : deserializeNote(body.data);
    },
    [createNote, queryClient, account?.householdId, notes],
  );

  const [isShowCreateTask, setIsShowCreateTask] = useState(false);
  const showCreateTask = useCallback(() => {
    setIsShowCreateTask(!isShowCreateTask);
  }, [isShowCreateTask]);

  const createTask = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<HouseholdsController["createTask"]>>
  >(
    `/households/${account?.householdId}/tasks`,
    async (data: EditableTask) => ({
      method: "POST",
      body: JSON.stringify(data),
    }),
  );

  const onCreateTask = useCallback(
    async (data: EditableTask) => {
      if (
        typeof account?.householdId === "undefined" ||
        typeof household === "undefined"
      ) {
        throw new Error("Account household is not yet loaded");
      }

      const body = await createTask(data);
      if (body.data === null) {
        return null;
      } else {
        queryClient.setQueryData(
          [
            "households",
            account.householdId,
            { includeAccounts: true, includeCRM: true },
          ],
          {
            ...household,
            tasks: [...(household.tasks ?? []), deserializeTask(body.data)],
          },
        );

        return deserializeTask(body.data);
      }
    },
    [account?.householdId, createTask, queryClient, household],
  );

  const [isShowCreateWorkflow, setIsShowCreateWorkflow] = useState(false);
  const showCreateWorkflow = useCallback(() => {
    setIsShowCreateWorkflow(!isShowCreateWorkflow);
  }, [isShowCreateWorkflow]);

  const createWorkflow = useAuthenticatedMutationAsync<
    SerializedObject<UnpackResponse<HouseholdsController["createWorkflow"]>>
  >(
    `/households/${account?.householdId}/workflows`,
    async (data: EditableWorkflow) => ({
      method: "POST",
      body: JSON.stringify(data),
    }),
  );

  const onCreateWorkflow = useCallback(
    async (data: EditableWorkflow) => {
      if (
        typeof account?.householdId === "undefined" ||
        typeof household === "undefined"
      ) {
        throw new Error("Account household is not yet loaded");
      }

      const body = await createWorkflow(data);

      if (body.data === null) {
        return null;
      } else {
        queryClient.setQueryData(
          [
            "households",
            account.householdId,
            { includeAccounts: true, includeCRM: true },
          ],
          {
            ...household,
            workflows: [
              ...(household.workflows ?? []),
              deserializeWorkflow(body.data),
            ],
          },
        );

        return deserializeWorkflow(body.data);
      }
    },
    [account?.householdId, createWorkflow, queryClient, household],
  );

  const [showModal, setShowModal] = useState(false);
  const handleArchiveAccountClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const { mutateAsync: unarchiveAccount } = useUnarchiveAccount(
    parseInt(accountId ?? "-1"),
  );

  const notificationContext = useContext(NotificationContext);

  const handleUnarchiveAccount = useCallback(async () => {
    try {
      await unarchiveAccount();

      setShowModal(false);
      notificationContext.pushNotification({
        id: `account-${accountId}`,
        header: "Account Unarchived",
        body: `${account?.displayName} unarchived`,
        variant: "success",
      });
    } catch (ex) {
      console.error("Failed to unarchive account", ex);
      notificationContext.pushNotification({
        id: `account-${accountId}`,
        header: "Failed to Unarchive Account",
        body: `${account?.displayName} was not unarchived`,
        variant: "danger",
      });
    }
  }, [account?.displayName, accountId, notificationContext, unarchiveAccount]);

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <ButtonToolbar className="mb-3 justify-content-end gap-2">
                {isFetchingHousehold ? (
                  <Loading />
                ) : !linkedToCrm ? null : (
                  <>
                    <ActionButton
                      variant="secondary"
                      icon="/icons/tasks.svg"
                      label="Create Task"
                      type="button"
                      onClick={showCreateTask}
                    />
                    <ActionButton
                      variant="secondary"
                      icon="/icons/flowchart.svg"
                      label="Create Workflow"
                      type="button"
                      onClick={showCreateWorkflow}
                    />
                    <ActionButton
                      variant="secondary"
                      icon="/icons/folded-list.svg"
                      label="Notes"
                      type="button"
                      onClick={onShowNotes}
                    />
                  </>
                )}
                {account?.isActive ? (
                  <ActionButton
                    type="button"
                    label="Archive"
                    icon="/icons/trash.svg"
                    onClick={handleArchiveAccountClick}
                  />
                ) : (
                  <ActionButton
                    type="button"
                    label="Unarchive"
                    icon="/icons/trash.svg"
                    onClick={handleUnarchiveAccount}
                  />
                )}
              </ButtonToolbar>
            </Col>
            <AccountArchiveDialog
              showModal={showModal}
              setShowModal={setShowModal}
              archiveAccountId={accountId ? Number(accountId) : 0}
              archiveAccountName={account?.displayName ?? ""}
            />
          </Row>
          <Row>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-between flex-wrap"
            >
              <div className="mb-3">
                <AccountNameField account={account} accountId={accountId} />
              </div>
              <div>
                <SummaryPill
                  label="AUM"
                  value={formatCurrency(account?.accountBalance ?? 0)}
                  size="sm"
                  className="me-3 mb-3"
                />
                <SummaryPill
                  label="Cash"
                  value={`${formatCurrency(account?.cashBalance ?? 0)}`}
                  size="sm"
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <CreateTaskPane
        show={isShowCreateTask}
        setShow={setIsShowCreateTask}
        onCreate={onCreateTask}
      />
      <CreateWorkflowPane
        show={isShowCreateWorkflow}
        setShow={setIsShowCreateWorkflow}
        onCreate={onCreateWorkflow}
      />
      <NotesPane
        showNotes={showNotes}
        setShowNotes={setShowNotes}
        notes={notes ?? []}
        onCreate={onCreateNote}
        isLoading={isFetchingNotes}
        isError={isErrorNotes}
      />
    </>
  );
};

export default AccountHeader;
