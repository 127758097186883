import { useCallback, useContext, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { NotificationContext } from "../../Notifications";
import SubmitButton from "../../components/SubmitButton";
import { useArchiveContact } from "./lib";

const ContactArchiveDialog = ({
  showModal,
  setShowModal,
  archiveContactId,
  archiveContactName,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  archiveContactId: number;
  archiveContactName: string;
}) => {
  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const [archiveEnabled, setArchiveEnabled] = useState(false);

  const checkArchiveEnabled = (value: string) => {
    setArchiveEnabled(value === archiveContactName);
  };

  const archiveContact = useArchiveContact(archiveContactId);

  const notificationContext = useContext(NotificationContext);

  const handleArchiveContact = useCallback(async () => {
    try {
      await archiveContact.mutateAsync();

      setShowModal(false);
      notificationContext.pushNotification({
        id: `contact-${archiveContactId}`,
        header: "Contact Archived",
        body: `${archiveContactName} archived`,
        variant: "success",
      });
    } catch (ex) {
      console.error("Failed to archive contact", ex);
      notificationContext.pushNotification({
        id: `contact-${archiveContactId}`,
        header: "Failed to Archive Contact",
        body: `${archiveContactName} was not archived`,
        variant: "danger",
      });
    }
  }, [
    archiveContact,
    archiveContactId,
    archiveContactName,
    notificationContext,
    setShowModal,
  ]);

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Archive Contact</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          Are you sure you would like to archive {archiveContactName}?
        </Form.Label>
        <Form.Control
          style={{ width: "90%" }}
          type="text"
          placeholder="Enter contact name"
          onChange={(e) => checkArchiveEnabled(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          variant="danger"
          label="Confirm"
          disabled={!archiveEnabled}
          onClick={handleArchiveContact}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default ContactArchiveDialog;
