import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import type { BenchmarkRequest } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import Content from "../components/Content";
import FormError, { useErrorMessage } from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import SubmitButton from "../components/SubmitButton";
import { getSchemaFieldLabel, primarySecuritiesSchema } from "../lib/forms";
import BenchmarkDeleteDialog from "./BenchmarkDeleteDialog";
import ModelAssetAllocationChart from "./ModelAssetAllocationChart";
import ModelInfoActionButtons from "./ModelInfoActionButtons";
import ModelPerformance from "./ModelPerformance";
import ModelRiskAllocationChart from "./ModelRiskAllocationChart";
import PrimarySecurities from "./PrimarySecurities";
import {
  sumModelWeights,
  useCreateBenchmark,
  useQueryBenchmark,
  useUpdateBenchmark,
} from "./lib";

type BenchmarkForm = Omit<BenchmarkRequest, "id">;

const schema: yup.ObjectSchema<BenchmarkForm> = yup
  .object({
    name: yup.string().required().label("Benchmark Name"),
    description: yup.string().label("Description"),
    primarySecurities: primarySecuritiesSchema.required(),
  })
  .test({
    name: "weights-sum-100",
    test: (value) => sumModelWeights(value.primarySecurities) === 100,
    message: "The sum of all weights must equal 100%",
  });

const BenchmarkInfo = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValidating },
    reset,
    control,
    watch,
    getValues,
  } = useForm<BenchmarkForm>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const {
    fields: primarySecurityFields,
    append: appendPrimarySecurity,
    remove: removePrimarySecurity,
    replace: replacePrimarySecurities,
  } = useFieldArray({
    name: "primarySecurities",
    control,
    keyName: "_field_id",
  });

  const { setError, resetError, errorMessage } = useErrorMessage();

  const { benchmarkId: benchmarkIdStr } = useParams();
  const benchmarkId = parseInt(benchmarkIdStr ?? "-1");
  const isNew = benchmarkIdStr === "new";

  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: dataBenchmark,
  } = useQueryBenchmark(benchmarkId, { enabled: !isNew });

  useEffect(() => {
    if (typeof dataBenchmark !== "undefined") {
      reset(dataBenchmark);
    }
  }, [dataBenchmark, reset]);

  const createBenchmark = useCreateBenchmark();
  const updateBenchmark = useUpdateBenchmark(benchmarkId);

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useCallback(
    async (data: BenchmarkForm) => {
      try {
        if (isNew) {
          const newBenchmarkId = await createBenchmark.mutateAsync(data);
          notificationContext.pushNotification({
            id: `benchmark-${newBenchmarkId}`,
            header: "Benchmark Created",
            body: `${data.name} benchmark created`,
            variant: "success",
          });
          navigate(`../${newBenchmarkId}`);
        } else {
          const benchmarkBody = await updateBenchmark.mutateAsync(data);
          reset(benchmarkBody);
          notificationContext.pushNotification({
            id: `benchmark-${benchmarkIdStr}`,
            header: "Benchmark Updated",
            body: `${data.name} benchmark updated`,
            variant: "success",
          });
        }
      } catch (err) {
        console.error("Failed to save benchmark", err);
        notificationContext.pushNotification({
          id: `benchmark-${benchmarkIdStr}`,
          header: "Failed to Save Benchmark",
          body: `Benchmark ${data.name} was not saved`,
          variant: "danger",
        });
      }
    },
    [
      benchmarkIdStr,
      createBenchmark,
      isNew,
      navigate,
      notificationContext,
      reset,
      updateBenchmark,
    ],
  );

  const watchPrimarySecurityArray = watch("primarySecurities");
  const controlledPrimarySecurityFields = primarySecurityFields.map(
    (field, index) => ({
      ...field,
      ...watchPrimarySecurityArray[index],
    }),
  );

  // react-hook-form does not have form-level validation,
  // so we must perform it manually.
  useEffect(() => {
    if (isValidating) {
      const sum = sumModelWeights(watchPrimarySecurityArray);

      if (sum === 100) {
        resetError();
      } else {
        setError("The sum of all weights must equal 100");
      }
    }
  }, [watchPrimarySecurityArray, setError, resetError, isValidating]);

  const [showModal, setShowModal] = useState(false);
  const [deleteBenchmarkId, setDeleteBenchmarkId] = useState(0);
  const handleDeleteBenchmarkClick = useCallback(() => {
    setDeleteBenchmarkId(benchmarkId);
    setShowModal(true);
  }, [benchmarkId]);

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <FormError message="An error occurred" />
  ) : !dataBenchmark && !isNew ? (
    <FormError message="Benchmark not found" />
  ) : (
    <>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <h1>Benchmark Details</h1>
              </Col>
              <Col md="auto">
                <ModelInfoActionButtons
                  deleteHandler={handleDeleteBenchmarkClick}
                />
              </Col>
            </Row>
            <Content>
              {errorMessage}
              <Row>
                <Col xxl={9} md={7}>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-name">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.name)}
                        </Form.Label>
                        <Form.Control type="text" {...register("name")} />
                        <FormFieldError field={errors.name} />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group controlId="form-description">
                        <Form.Label>
                          {getSchemaFieldLabel(schema.fields.description)}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          {...register("description")}
                        />
                        <FormFieldError field={errors.description} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <h2>Securities</h2>
                  <PrimarySecurities
                    fields={controlledPrimarySecurityFields}
                    replace={replacePrimarySecurities}
                    add={appendPrimarySecurity}
                    remove={removePrimarySecurity}
                    register={register}
                    control={control}
                    getValues={getValues}
                    errors={errors.primarySecurities}
                  />
                  <Row>
                    <Col>
                      <SubmitButton isSubmitting={isSubmitting} />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <ModelPerformance model={getValues()} isLoading={isLoading} />
                  <ModelAssetAllocationChart
                    model={getValues()}
                    isLoading={isLoading}
                  />
                  <ModelRiskAllocationChart
                    model={getValues()}
                    isLoading={isLoading}
                  />
                </Col>
                <BenchmarkDeleteDialog
                  showModal={showModal}
                  setShowModal={setShowModal}
                  deleteBenchmarkId={deleteBenchmarkId}
                  setDeleteBenchmarkId={setDeleteBenchmarkId}
                />
              </Row>
            </Content>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default BenchmarkInfo;
