import { Container, Image, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import GlobalSearchBox from "./components/GlobalSearchBox";
import DataDate from "./DataDate";
import LoginButton from "./LoginButton";

const Header = () => (
  <Navbar
    as="header"
    sticky="top"
    role="menubar"
    className="d-lg-flex d-none border-bottom"
  >
    <Container fluid>
      <Navbar.Brand as={Link} to="/dashboard" className="bg-light p-1 rounded">
        <Image src="/logo.svg" height="40" alt="AdviceCloud logo" />
      </Navbar.Brand>
      <GlobalSearchBox />
      <div className="d-flex align-items-end gap-5">
        <DataDate />
        <LoginButton />
      </div>
    </Container>
  </Navbar>
);

export default Header;
