import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import type { ModelRequestSecurities } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import AllocationBar from "../components/AllocationBar/AllocationBar";
import { useDebounce } from "../lib/forms";
import { getRiskAllocation } from "../rebalances/lib";
import { useQueryModelRiskAllocation } from "./lib";

const ModelRiskAllocationChart = ({
  model,
  isLoading = false,
}: {
  model: Partial<ModelRequestSecurities>;
  isLoading: boolean;
}) => {
  const {
    isPending: isPendingRiskAllocation,
    isError: isErrorRiskAllocation,
    data: dataRiskAllocation,
    refetch,
  } = useQueryModelRiskAllocation(model, { enabled: false });

  const [debouncedRefetch] = useDebounce(
    async () => {
      await refetch();
    },
    isPendingRiskAllocation ? 0 : 1000,
  );

  useEffect(() => {
    if (
      !isLoading &&
      model &&
      ((model.primarySecurities?.length ?? 0) > 0 ||
        (model.sleeves?.length ?? 0) > 0 ||
        (model.securityGroups?.length ?? 0) > 0)
    ) {
      debouncedRefetch();
    }
    // debounceRefetch is a new instance every timer cycle;
    // Using it as a dependency causes this hook to refire repeatedly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, isLoading]);

  return isLoading || isPendingRiskAllocation ? (
    <Loading message="Risk Allocation" />
  ) : isErrorRiskAllocation ? (
    <Alert variant="danger">Failed to load risk allocation</Alert>
  ) : (
    <>
      <h4>Risk Allocation</h4>
      <AllocationBar
        data={dataRiskAllocation.current}
        converter={getRiskAllocation}
      />
    </>
  );
};

export default ModelRiskAllocationChart;
