import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import { useContext, useMemo } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import type { BillingController } from "../../../api/src/billing/billing.controller";
import type { FeeStructure } from "../../../api/src/billing/lib";
import type { HouseholdWithMetrics } from "../../../api/src/households/households.service";
import type { SerializedObject, UnpackResponse } from "../../../api/src/lib";
import type { Month } from "../../../api/src/lib/date";
import Loading from "../Loading";
import { NotificationContext } from "../Notifications";
import { useQueryAccounts } from "../clients/account/lib";
import {
  displayHouseholdStatus,
  useQueryHouseholds,
} from "../clients/household/lib";
import Content from "../components/Content";
import FormError from "../components/FormError";
import FormFieldError from "../components/FormFieldError";
import HelpButton from "../components/HelpButton";
import HelpTooltip from "../components/HelpTooltip";
import IndeterminateCheckbox from "../components/IndeterminateCheckbox";
import SubmitButton from "../components/SubmitButton";
import { Table, useTable } from "../components/Table/Table";
import {
  AssignedToFilter,
  multiSelectIncludes,
  StatusFilter,
} from "../components/Table/filters";
import { useQueryCrmUsers } from "../firm/lib";
import {
  useAuthenticatedFetch,
  useAuthenticatedMutationAsync,
} from "../lib/api";
import { monthOptions, monthToDisplay } from "../lib/date";
import { dateSchema, getSchemaFieldLabel, monthSchema } from "../lib/forms";
import { formatCurrency } from "../lib/numbers";
import { BillingHelp, standardBillingDateHelpMessage } from "./lib";

type FirmWideBillingReportForm = { month: Month; year: number };

const schemaFirmWide: yup.ObjectSchema<FirmWideBillingReportForm> = yup.object({
  month: monthSchema.required().label("Month"),
  year: yup.number().required().label("Year"),
});

const FirmWideBillingForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FirmWideBillingReportForm>({
    mode: "onBlur",
    resolver: yupResolver(schemaFirmWide),
  });

  const {
    isPending: isPendingAccounts,
    isError: isErrorAccounts,
    data: dataAccounts,
  } = useQueryAccounts({ pageSize: 10000 });
  const accounts = dataAccounts?.data;

  const {
    isPending: isPendingHouseholds,
    isError: isErrorHouseholds,
    data: dataHouseholds,
  } = useQueryHouseholds({ includeInactive: true });

  const navigate = useNavigate();

  const createReport = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["runBillingReport"]>
  >(
    "/billing/reports",
    async ({
      startDate,
      endDate,
      householdIds,
    }: {
      startDate: Date;
      endDate: Date;
      householdIds?: number[];
    }) => ({
      method: "POST",
      body: JSON.stringify({
        startDate,
        endDate,
        periodType: "standard",
        householdIds,
      }),
    }),
  );

  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async ({ month, year }: FirmWideBillingReportForm) => {
      const startDate = new Date(year, monthOptions.indexOf(month), 1);
      const endDate = new Date(year, monthOptions.indexOf(month) + 1, 0);

      try {
        const body = await createReport({ startDate, endDate });
        notificationContext.pushNotification({
          id: `billing-report-${body.data}`,
          header: "Billing Report Created",
          body: "Generating billing report... We will notify you when the report is ready.",
          variant: "success",
        });
        navigate(`../${body.data}`);
      } catch (err) {
        const message = "Failed to run billing";
        console.error(message, err);
        notificationContext.pushNotification({
          id: "billing-report-",
          header: "Failed to Create Billing Report",
          body: "Billing report was not created",
          variant: "danger",
        });
      }
    },
  });

  const now = new Date();
  const year = now.getFullYear();
  const yearOptions = [...Array(4).keys()].map((index) => year - index);

  const householdsWithAccounts = useMemo(
    () =>
      (dataHouseholds?.data ?? []).map((household) => ({
        ...household,
        accounts: (accounts ?? []).filter(
          (account) => account.householdId === household.id,
        ),
      })),
    [accounts, dataHouseholds?.data],
  );

  const householdsWithoutFeeStructures = useMemo(
    () =>
      householdsWithAccounts.filter(
        (household) =>
          household.accounts.length > 0 &&
          typeof household.feeStructureId === "undefined",
      ),
    [householdsWithAccounts],
  );
  const accountsWithoutFeeStructures = useMemo(
    () =>
      householdsWithoutFeeStructures
        .flatMap((household) => household.accounts)
        .filter((account) => typeof account.feeStructureId === "undefined"),
    [householdsWithoutFeeStructures],
  );

  const unassignedFeeStructuresMessages = useMemo(
    () =>
      [
        {
          value: householdsWithoutFeeStructures.length > 0,
          message: `${householdsWithoutFeeStructures.length} households`,
        },
        {
          value: accountsWithoutFeeStructures.length > 0,
          message: `${accountsWithoutFeeStructures.length} accounts`,
        },
      ]
        .filter((obj) => obj.value)
        .map((obj) => obj.message),
    [
      accountsWithoutFeeStructures.length,
      householdsWithoutFeeStructures.length,
    ],
  );

  return isPendingAccounts || isPendingHouseholds ? (
    <Loading />
  ) : isErrorAccounts || isErrorHouseholds ? (
    <FormError message="An error occurred" />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col className="d-flex justify-content-between">
          <h1>Run Billing</h1>
          <HelpButton
            title="Help"
            body={<BillingHelp />}
            buttonProps={{ className: "mb-3" }}
          />
        </Col>
      </Row>
      <Content>
        {unassignedFeeStructuresMessages.length <= 0 ? null : (
          <Row>
            <Col>
              <Alert variant="warning">
                There are {unassignedFeeStructuresMessages.join(" and ")}{" "}
                without an assigned fee structure. Please verify that this is
                correct or{" "}
                <Link to="/billing/summary">
                  assign fee structures to your households and accounts
                </Link>{" "}
                otherwise.
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={3} sm={6} className="mb-3">
            <Form.Group controlId="form-month">
              <Form.Label>
                {getSchemaFieldLabel(schemaFirmWide.fields.month)}
              </Form.Label>
              <Form.Select {...register("month")}>
                {monthOptions.map((month) => (
                  <option key={month} value={month}>
                    {monthToDisplay(month)}
                  </option>
                ))}
              </Form.Select>
              <FormFieldError field={errors.month} />
            </Form.Group>
          </Col>
          <Col md={2} sm={5} className="mb-3">
            <Form.Group controlId="form-year">
              <Form.Label>
                {getSchemaFieldLabel(schemaFirmWide.fields.year)}
              </Form.Label>
              <Form.Select {...register("year")}>
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
              <FormFieldError field={errors.year} />
            </Form.Group>
          </Col>
          <Col sm={1} className="mb-3 d-flex align-items-end py-2">
            <HelpTooltip tooltip={standardBillingDateHelpMessage} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} label="Run Billing" />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

type OneOffBillingReportForm = {
  startDate: Date;
  endDate: Date;
};

const schemaOneOff: yup.ObjectSchema<OneOffBillingReportForm> = yup.object({
  startDate: dateSchema.required().label("Start Date"),
  endDate: dateSchema.required().label("End Date"),
});

type HouseholdRow = Pick<
  HouseholdWithMetrics,
  "id" | "name" | "householdBalance" | "cashBalance" | "isActive"
> & {
  feeStructure?: FeeStructure;
  assignedUser?: { id: number; name: string };
};

const OneOffBillingForm = () => {
  const columnHelper = useMemo(() => createColumnHelper<HouseholdRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => (
          <Link to={`/clients/households/${info.row.original.id}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => "Name",
        minSize: 225,
      }),
      columnHelper.accessor((row) => row.householdBalance ?? 0, {
        id: "householdBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.cashBalance ?? 0, {
        id: "cashBalance",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "Cash",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.feeStructure?.name ?? "", {
        id: "feeStructure",
        cell: (info) =>
          info.getValue() === "" ? (
            ""
          ) : (
            <Link
              to={`/billing/fee-structure/${info.row.original.feeStructure?.feeStructureId}`}
            >
              {info.getValue()}
            </Link>
          ),
        header: () => "Fee Structure",
        minSize: 225,
      }),
      columnHelper.accessor((row) => row.assignedUser?.id.toString() ?? "", {
        id: "assignedUserId",
        cell: (info) => info.row.original.assignedUser?.name ?? "",
        header: () => "Assigned To",
        minSize: 100,
        filterFn: multiSelectIncludes,
        meta: {
          filterComponent: AssignedToFilter,
        },
      }),
      columnHelper.accessor("isActive", {
        cell: (info) => displayHouseholdStatus(info.row.original),
        header: () => "Status",
        meta: {
          filterComponent: StatusFilter,
        },
      }),
    ],
    [columnHelper],
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<OneOffBillingReportForm>({
    mode: "onBlur",
    resolver: yupResolver(schemaOneOff),
  });

  const {
    isPending: isPendingHouseholds,
    isError: isErrorHouseholds,
    data: dataHouseholds,
  } = useQueryHouseholds({
    includeBalances: true,
    includeCRM: true,
    includeInactive: true,
  });
  const households = dataHouseholds?.data;

  const {
    isPending: isPendingFeeStructures,
    isError: isErrorFeeStructures,
    data: dataFeeStructures,
  } = useAuthenticatedFetch<
    SerializedObject<UnpackResponse<BillingController["getAllFeeStructures"]>>
  >("/billing/fee-structures");

  const {
    isPending: isPendingAssignees,
    isError: isErrorAssignees,
    data: dataAssignees,
  } = useQueryCrmUsers();

  const householdsWithFeeStructure = useMemo(
    () =>
      (households ?? []).map((household) => ({
        ...household,
        feeStructure: (dataFeeStructures?.data ?? []).find(
          (feeStructure) =>
            feeStructure.feeStructureId === household.feeStructureId,
        ),
        assignedUser: (dataAssignees?.data ?? []).find(
          (user) => user.id === household.assignedUserId,
        ),
      })),
    [dataAssignees?.data, dataFeeStructures?.data, households],
  );

  const { table, rowSelection } = useTable({
    data: householdsWithFeeStructure,
    columns,
    getRowId: (row) => row.id.toString(),
    initialState: {
      sorting: [{ id: "name", desc: false }],
      columnFilters: [
        {
          id: "status",
          value: "Active",
        },
      ],
    },
  });

  const createReport = useAuthenticatedMutationAsync<
    UnpackResponse<BillingController["runBillingReport"]>
  >(
    "/billing/reports",
    async ({
      startDate,
      endDate,
      householdIds,
    }: {
      startDate: Date;
      endDate: Date;
      householdIds: number[];
    }) => ({
      method: "POST",
      body: JSON.stringify({
        startDate,
        endDate,
        periodType: "custom",
        householdIds,
      }),
    }),
  );

  const navigate = useNavigate();
  const notificationContext = useContext(NotificationContext);

  const onSubmit = useMutation({
    mutationFn: async ({ startDate, endDate }: OneOffBillingReportForm) => {
      const householdIds = Object.keys(rowSelection).map((idStr) =>
        parseInt(idStr),
      );

      try {
        const body = await createReport({ startDate, endDate, householdIds });
        notificationContext.pushNotification({
          id: `billing-report-${body.data}`,
          header: "Billing Report Created",
          body: "Billing report created",
          variant: "success",
        });
        navigate(`../${body.data}`);
      } catch (err) {
        console.error("Failed to run billing", err);
        notificationContext.pushNotification({
          id: "billing-report-",
          header: "Failed to Create Billing Report",
          body: "Billing report was not created",
          variant: "danger",
        });
      }
    },
  });

  return isPendingHouseholds || isPendingFeeStructures || isPendingAssignees ? (
    <Loading />
  ) : isErrorHouseholds || isErrorFeeStructures || isErrorAssignees ? (
    <FormError message="An error occurred" />
  ) : (
    <Form onSubmit={handleSubmit((data) => onSubmit.mutateAsync(data))}>
      <Row>
        <Col className="d-flex justify-content-between">
          <h1>Run Billing</h1>
          <HelpButton
            title="Help"
            body={<BillingHelp />}
            buttonProps={{ className: "mb-3" }}
          />
        </Col>
      </Row>
      <Content>
        <Row className="mb-3">
          <Col md={2} sm={6} className="mb-3">
            <Form.Group controlId="form-startDate">
              <Form.Label>
                {getSchemaFieldLabel(schemaOneOff.fields.startDate)}
              </Form.Label>
              <Form.Control type="date" {...register("startDate")} />
              <FormFieldError field={errors.startDate} />
            </Form.Group>
          </Col>
          <Col md={2} sm={6} className="mb-3">
            <Form.Group controlId="form-endDate">
              <Form.Label>
                {getSchemaFieldLabel(schemaOneOff.fields.endDate)}
              </Form.Label>
              <Form.Control type="date" {...register("endDate")} />
              <FormFieldError field={errors.endDate} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <h2>Select Households</h2>
            <Table table={table} />
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton isSubmitting={isSubmitting} label="Run Billing" />
          </Col>
        </Row>
      </Content>
    </Form>
  );
};

const CreateBillingReport = () => {
  const [params] = useSearchParams();
  const type = params.get("type") ?? "standard";

  return type === "standard" ? <FirmWideBillingForm /> : <OneOffBillingForm />;
};

export default CreateBillingReport;
