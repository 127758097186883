import { keyBy } from "lodash";
import { useCallback } from "react";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import type { CashSettingsController } from "../../../api/src/cash-settings/cash-settings.controller";
import type { CashSetting } from "../../../api/src/cash-settings/lib";
import type { Household } from "../../../api/src/households-base/households-base.service";
import type { UnpackResponse } from "../../../api/src/lib";
import Loading from "../Loading";
import { useQueryAccounts } from "../clients/account/lib";
import { useQueryHouseholds } from "../clients/household/lib";
import ActionButton from "../components/ActionButton";
import TabContainerWithTabs from "../components/TabContainer";
import { useAuthenticatedQuery } from "../lib/api";
import { processFileResponse } from "../lib/file";
import AccountCashSettingsTable, {
  AccountCashSettingRow,
} from "./AccountCashSettingsTable";
import CashNav from "./CashNav";
import { useQueryCashSettings } from "./lib";

function useQueryExport() {
  return useAuthenticatedQuery<
    UnpackResponse<CashSettingsController["exportAccountsCashSettings"]>,
    void
  >({
    queryKey: ["cash-settings", "accounts", "export"],
    queryFn: async () => undefined,
    queryOptions: { enabled: false },
    responseCallback: processFileResponse,
  });
}

const AccountCashSettings = () => {
  const { isPending: isPendingAccounts, data: dataAccounts } = useQueryAccounts(
    {
      includeBalances: true,
      pageSize: 10000,
    },
  );
  const { data: dataHouseholds } = useQueryHouseholds();
  const { isPending: isPendingCashSettings, data: dataCashSettings } =
    useQueryCashSettings();

  const householdsMap: Record<number, Household> = (
    dataHouseholds?.data ?? []
  ).reduce(
    (result, household) => ({
      ...result,
      [household.id]: household,
    }),
    {},
  );
  const cashSettingsMap: Record<number, CashSetting> = keyBy(
    dataCashSettings?.data ?? [],
    "id",
  );

  const combinedCashSettingsAccountsHouseholds = (dataAccounts?.data ?? []).map(
    (account): AccountCashSettingRow => {
      const cashSetting =
        typeof account.cashSettingId === "undefined"
          ? undefined
          : cashSettingsMap[account.cashSettingId];
      const household =
        typeof account.householdId === "undefined"
          ? null
          : householdsMap[account.householdId];

      return {
        ...account,
        ...cashSetting,
        cashSettingId: cashSetting?.id,
        cashSettingName: cashSetting?.name,
        household,
      };
    },
  );

  const { isLoading: isLoadingExport, refetch: refetchExport } =
    useQueryExport();

  const onExport = useCallback(async () => {
    await refetchExport();
  }, [refetchExport]);

  return (
    <TabContainerWithTabs tabs={CashNav}>
      <Row>
        <Col>
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                variant="secondary"
                label="Export"
                icon="/icons/folded-list.svg"
                onClick={onExport}
                className="me-2"
                disabled={isLoadingExport}
              />
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <Row>
        <Col>
          {isPendingAccounts || isPendingCashSettings ? (
            <Loading />
          ) : (
            <AccountCashSettingsTable
              accountCashSettings={combinedCashSettingsAccountsHouseholds}
            />
          )}
        </Col>
      </Row>
    </TabContainerWithTabs>
  );
};

export default AccountCashSettings;
