import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { ButtonGroup, ButtonToolbar, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import type { ExtendedModel } from "../../../api/src/models/models.service";
import Loading from "../Loading";
import ActionButton from "../components/ActionButton";
import HelpButton from "../components/HelpButton";
import TabContainerWithTabs from "../components/TabContainer";
import { Table, useTable } from "../components/Table/Table";
import {
  onColumnFiltersChange,
  onPaginationChange,
  onSortingChange,
  useTableSettings,
} from "../components/Table/tableSettings";
import { formatCurrency, formatPercent } from "../lib/numbers";
import { getRiskAllocationDisplay } from "../rebalances/lib";
import ModelDeleteDialog from "./ModelDeleteDialog";
import ModelsNav from "./ModelsNav";
import { ModelsHelp, useQueryModels } from "./lib";

type ModelRow = Required<ExtendedModel>;

const Models = () => {
  const handleDeleteModelClick = useCallback((deleteModelId: number) => {
    setDeleteModelId(deleteModelId);
    setShowModal(true);
  }, []);

  const columnHelper = useMemo(() => createColumnHelper<ModelRow>(), []);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => (
          <Link to={info.row.original.id.toString()}>{info.getValue()}</Link>
        ),
        header: () => "Name",
        minSize: 275,
      }),
      columnHelper.accessor((row) => row.aum, {
        id: "aum",
        cell: (info) => formatCurrency(info.getValue()),
        header: () => "AUM",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.noOfAccounts, {
        id: "usageCount",
        header: () => "Number of Accounts",
        enableColumnFilter: false,
        meta: {
          className: "text-end",
          headerClassName: "text-end",
        },
      }),
      columnHelper.accessor((row) => row.riskAllocations, {
        id: "riskAllocation",
        cell: (info) => getRiskAllocationDisplay(info.getValue()),
        header: () => "Allocation",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.expenseRatio, {
        id: "weightedExpenseRatio",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Weighted Expense Ratio",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.returns.oneYear, {
        id: "performanceOneYear",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Performance 1 Year",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.returns.threeYear, {
        id: "performanceThreeYear",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Performance 3 Year",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.returns.fiveYear, {
        id: "performanceFiveYear",
        cell: (info) => formatPercent(info.getValue(), 2),
        header: () => "Performance 5 Year",
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.updatedTime, {
        id: "updatedTime",
        cell: (info) => new Date(info.getValue()).toLocaleDateString(),
        header: () => "Last Modified",
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: "deleteModel",
        cell: (info) => (
          <ActionButton
            onClick={() => {
              handleDeleteModelClick(info.row.original.id);
            }}
            variant="icon"
            icon="/icons/trash.svg"
            label="Delete Model"
            type="button"
          />
        ),
        header: () => "",
        enableColumnFilter: false,
      }),
    ],
    [columnHelper, handleDeleteModelClick],
  );

  const {
    isPending: isPendingModels,
    isError: isErrorModels,
    data: dataModels,
  } = useQueryModels({ includeMetrics: true });

  const [showModal, setShowModal] = useState(false);
  const [deleteModelId, setDeleteModelId] = useState(0);
  const [selectedModelId, setModelId] = useState(0);

  const selectModels = (dataModels || []).filter(
    (model) => model.id !== deleteModelId,
  );

  const modelOptions = isPendingModels
    ? [
        <option key={null} value="">
          Loading...
        </option>,
      ]
    : isErrorModels
      ? [
          <option key={null} value="">
            There was an error loading models
          </option>,
        ]
      : [
          <option key="" value="">
            None
          </option>,
          ...selectModels
            .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
            .map((model) => (
              <option
                key={model.id}
                value={model.id}
                hidden={model.id === deleteModelId}
              >
                {model.name}
              </option>
            )),
        ];

  const [tableSettings, setTableSettings] = useTableSettings("models");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableSettings.filters,
  );
  const [sorting, setSorting] = useState<SortingState>(tableSettings.sorting);
  const [pagination, setPagination] = useState<PaginationState>({
    pageSize: tableSettings.pageSize,
    pageIndex: 0,
  });

  const { table } = useTable({
    columns,
    data: (dataModels ?? []) as Required<ExtendedModel>[],
    getRowId: (row) => row.id.toString(),
    state: { columnFilters, sorting, pagination },
    onColumnFiltersChange: onColumnFiltersChange(
      columnFilters,
      setColumnFilters,
      tableSettings,
      setTableSettings,
    ),
    onSortingChange: onSortingChange(
      sorting,
      setSorting,
      tableSettings,
      setTableSettings,
    ),
    onPaginationChange: onPaginationChange(
      pagination,
      setPagination,
      tableSettings,
      setTableSettings,
    ),
  });

  return (
    <TabContainerWithTabs tabs={ModelsNav}>
      <Row>
        <Col className="d-flex justify-content-between">
          <ButtonToolbar className="mb-3">
            <ButtonGroup className="me-3">
              <ActionButton
                as={Link}
                to="new"
                variant="secondary"
                label="New"
                icon="/icons/new.svg"
              />
            </ButtonGroup>
          </ButtonToolbar>
          <HelpButton
            title="Help – Models"
            body={<ModelsHelp />}
            buttonProps={{ className: "mb-3" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>{isPendingModels ? <Loading /> : <Table table={table} />}</Col>
        <ModelDeleteDialog
          showModal={showModal}
          setShowModal={setShowModal}
          deleteModelId={deleteModelId}
          selectedModelId={selectedModelId}
          setModelId={setModelId}
          modelOptions={modelOptions}
        />
      </Row>
    </TabContainerWithTabs>
  );
};

export default Models;
