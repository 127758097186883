import { useCallback } from "react";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import SubmitButton from "../components/SubmitButton";
import Loading from "../Loading";
import {
  useDeleteSecurityGroup,
  useQuerySecurityGroupAssignments,
} from "./lib";

const SecurityGroupDeleteDialog = ({
  showModal,
  setShowModal,
  deleteSecurityGroupId,
  setDeleteSecurityGroupId,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  deleteSecurityGroupId: number;
  setDeleteSecurityGroupId: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const navigate = useNavigate();

  // get models using the sleeve
  const { isLoading: isLoadingAssignments, data: securityGroupAssignments } =
    useQuerySecurityGroupAssignments(deleteSecurityGroupId);

  const handleModalClose = useCallback(() => {
    setDeleteSecurityGroupId(0);
    setShowModal(false);
  }, [setDeleteSecurityGroupId, setShowModal]);

  const deleteSecurityGroup = useDeleteSecurityGroup(deleteSecurityGroupId);

  const handleDeleteSecurityGroup = useCallback(async () => {
    await deleteSecurityGroup.mutateAsync();
    setShowModal(false);
    navigate("/models/security-groups");
  }, [deleteSecurityGroup, navigate, setShowModal]);

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
      centered
    >
      <Modal.Header>
        <Modal.Title>Delete Security Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoadingAssignments ? (
          <Loading />
        ) : securityGroupAssignments?.length === 0 ? (
          <div className="m-2">
            Are you sure you would like to delete this security group?
          </div>
        ) : (
          <>
            <div className="m-2">
              This security group is attached to the below models and sleeves.
              First, please un-attach the security group from below models and
              sleeves.
            </div>
            <ListGroup as="ul">
              {securityGroupAssignments?.map((assignment) => (
                <ListGroup.Item
                  key={`${assignment.type}-${assignment.id}`}
                  as="li"
                >
                  <Link to={`/models/${assignment.type}s/${assignment.id}`}>
                    <span>{assignment.name}</span>
                  </Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleModalClose}>
          Discard
        </Button>
        <SubmitButton
          hidden={securityGroupAssignments?.length !== 0}
          variant="danger"
          label="Delete"
          onClick={handleDeleteSecurityGroup}
        />
      </Modal.Footer>
    </Modal>
  );
};
export default SecurityGroupDeleteDialog;
