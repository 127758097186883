import { useContext, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import type { EditableAccount } from "../../../../api/src/accounts/accounts.service";
import Loading from "../../Loading";
import AccountNumber from "../../components/AccountNumber";
import FormFieldError from "../../components/FormFieldError";
import MaskedInput from "../../components/MaskedInput";
import { custodianCodeToDisplayName } from "../../lib/custodian";
import { boolToYesNo, unassignedLabel } from "../../lib/display";
import { getSchemaFieldLabel, zipCodeSchema } from "../../lib/forms";
import { zipCodeMask } from "../../lib/masks";
import { useQueryHousehold } from "../household/lib";
import { AccountContext } from "./AccountInfo";
import { displayAccountStatus } from "./lib";

export const schema: yup.ObjectSchema<Pick<EditableAccount, "address">> =
  yup.object({
    address: yup.object().shape({
      address1: yup.string().required().label("Address 1"),
      address2: yup.string().label("Address 2"),
      address3: yup.string().label("Address 3"),
      city: yup.string().required().label("City"),
      state: yup.string().label("State"),
      zipCode: zipCodeSchema,
      country: yup.string().label("Country"),
    }),
  });

const AccountProfile = () => {
  const { account } = useContext(AccountContext);

  const {
    isFetching: isFetchingHousehold,
    isError: isErrorHousehold,
    data: household,
  } = useQueryHousehold(account?.householdId ?? 0, {
    enabled: typeof account?.householdId !== "undefined",
  });

  const {
    register,
    formState: { errors },
    control,
    reset,
  } = useForm<EditableAccount>();

  useEffect(() => {
    if (typeof account !== "undefined") {
      reset(account);
    }
  }, [account, reset]);

  return (
    <>
      {typeof account === "undefined" ? null : (
        <Row>
          <Col xl={6}>
            <dl>
              <Row>
                <Col as="dt" sm={4}>
                  Type
                </Col>
                <Col as="dd">{account.type}</Col>
              </Row>
              <Row>
                <Col as="dt" sm={4}>
                  Taxable
                </Col>
                <Col as="dd">{boolToYesNo(account.isTaxable)}</Col>
              </Row>
              <Row>
                <Col as="dt" sm={4}>
                  Tax Deferred
                </Col>
                <Col as="dd">{boolToYesNo(account.isTaxDeferred)}</Col>
              </Row>
              <Row>
                <Col as="dt" sm={4}>
                  Account Number
                </Col>
                <Col as="dd">
                  <AccountNumber
                    fullNumber={account.number}
                    displayNumber={account.displayNumber}
                  />
                </Col>
              </Row>
              <Row>
                <Col as="dt" sm={4}>
                  Household
                </Col>
                <Col as="dd">
                  {isFetchingHousehold ? (
                    <Loading />
                  ) : isErrorHousehold ? (
                    <span className="text-danger">
                      Failed to load household
                    </span>
                  ) : typeof household === "undefined" ? (
                    unassignedLabel
                  ) : (
                    <Link to={`/clients/households/${account.householdId}`}>
                      {household.name}
                    </Link>
                  )}
                </Col>
              </Row>
            </dl>
          </Col>
          <Col xl={6}>
            <dl>
              <Row>
                <Col as="dt" sm={4}>
                  Owner
                </Col>
                <Col as="dd">{account.owner}</Col>
              </Row>
              <Row>
                <Col as="dt" sm={4}>
                  Status
                </Col>
                <Col as="dd">{displayAccountStatus(account)}</Col>
              </Row>
              <Row>
                <Col as="dt" sm={4}>
                  Opening Date
                </Col>
                <Col as="dd">
                  {new Date(account.inceptionDate).toLocaleDateString()}
                </Col>
              </Row>
              <Row>
                <Col as="dt" sm={4}>
                  Custodian
                </Col>
                <Col as="dd">
                  {custodianCodeToDisplayName(account.custodian)}
                </Col>
              </Row>
              {account.archiveDate && (
                <Row>
                  <Col as="dt" sm={4}>
                    Archive Date
                  </Col>
                  <Col as="dd">{account.archiveDate.toLocaleDateString()}</Col>
                </Row>
              )}
            </dl>
          </Col>
        </Row>
      )}
      <Row as="dl">
        <Col xxl={4} xl={8}>
          <h2>Registered Address</h2>
          <Row>
            <Col as="dt" sm={4}>
              {getSchemaFieldLabel(yup.reach(schema, "address.address1"))}
            </Col>
            <Col as="dd">
              <Form.Control
                type="text"
                {...register("address.address1")}
                disabled
              />
              <FormFieldError field={errors.address?.address1} />
            </Col>
          </Row>
          <Row>
            <Col as="dt" sm={4}>
              {getSchemaFieldLabel(yup.reach(schema, "address.address2"))}
            </Col>
            <Col as="dd">
              <Form.Control
                type="text"
                {...register("address.address2")}
                disabled
              />
              <FormFieldError field={errors.address?.address2} />
            </Col>
          </Row>
          <Row>
            <Col as="dt" sm={4}>
              {getSchemaFieldLabel(yup.reach(schema, "address.city"))}
            </Col>
            <Col as="dd">
              <Form.Control
                type="text"
                {...register("address.city")}
                disabled
              />
              <FormFieldError field={errors.address?.city} />
            </Col>
          </Row>
          <Row>
            <Col as="dt" sm={4}>
              {getSchemaFieldLabel(yup.reach(schema, "address.state"))}
            </Col>
            <Col as="dd">
              <Form.Control
                type="text"
                {...register("address.state")}
                disabled
              />
              <FormFieldError field={errors.address?.state} />
            </Col>
          </Row>
          <Row>
            <Col as="dt" sm={4}>
              {getSchemaFieldLabel(yup.reach(schema, "address.zipCode"))}
            </Col>
            <Col as="dd">
              <MaskedInput
                type="text"
                name="address.zipCode"
                mask={zipCodeMask.mask}
                placeholder="xxxxx"
                control={control}
                disabled
              />
              <FormFieldError field={errors.address?.zipCode} />
            </Col>
          </Row>
          <Row>
            <Col as="dt" sm={4}>
              {getSchemaFieldLabel(yup.reach(schema, "address.country"))}
            </Col>
            <Col as="dd">
              <Form.Control
                type="text"
                {...register("address.country")}
                disabled
              />
              <FormFieldError field={errors.address?.country} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AccountProfile;
