import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import type { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js";
import {
  MutationKey,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import type { SerializedObject } from "../../../api/src/lib";
import { processFileResponse } from "./file";

type UseApiOptions = GetTokenSilentlyOptions["authorizationParams"] &
  Omit<RequestInit, "body"> & { body?: any };

/**
 * Callback for processing JSON response bodies.
 * @param res The HTTP response object
 */
export function processJsonResponse(res: Response) {
  return res.json();
}

/**
 * Callback for processing empty response bodies.
 * @param res The HTTP response object
 */
export async function processEmptyResponse(res: Response): Promise<void> {
  await res.text();
}

/**
 * Utility wrapper for the native `fetch` function.
 * @param url The HTTP resource URL
 * @param options The native `fetch` function options
 * @param callback The HTTP response body processing callback
 * @returns The processed HTTP response body
 */
export function publicFetch<TBody>(
  url: string,
  options: UseApiOptions = {},
  callback: (res: Response) => Promise<TBody> = processJsonResponse,
) {
  return async () => {
    const headers = {
      ...(!["POST", "PUT", "PATCH"].includes(options.method ?? "")
        ? {}
        : { "Content-Type": "application/json" }),
      ...options.headers,
    };
    if (headers["Content-Type"] === "multipart/form-data") {
      delete headers["Content-Type"];
    }

    const res = await fetch(url, {
      ...options,
      ...(!options.body
        ? {}
        : {
            body:
              typeof options.body === "string" ||
              options.body instanceof FormData
                ? options.body
                : JSON.stringify(options.body),
          }),
      headers: headers,
    });

    if (!res.ok) {
      throw new Error(`Request failed: ${await res.text()}`);
    }

    return (await callback(res)) as TBody;
  };
}

/**
 * Utility wrapper for the native `fetch` function.
 * Options can be specified asynchronously.
 * @param url The HTTP resource URL
 * @param options The native `fetch` function options
 * @param callback The HTTP response body processing callback
 * @returns The processed HTTP response body
 */
export function publicFetchAsync<TBody>(
  url: string,
  optionsAsync: (...args: any[]) => Promise<UseApiOptions> = () =>
    Promise.resolve({}),
  callback = processJsonResponse,
) {
  return async (...args: any[]) => {
    const fetchOptions = await optionsAsync(...args);

    return await publicFetch<TBody>(url, fetchOptions, callback)();
  };
}

/**
 * Utility wrapper for the native `fetch` function with application authentication.
 * @param url The HTTP resource URL
 * @param getAccessToken The Auth0 function to get the access token
 * @param options The native `fetch` function options
 * @param callback The HTTP response body processing callback
 * @returns The processed HTTP response body
 */
export function authenticatedFetch<TBody>(
  url: string,
  getAccessToken: Auth0ContextInterface["getAccessTokenSilently"],
  options: UseApiOptions = {},
  callback = processJsonResponse,
) {
  return async () => {
    const { audience, scope, ...fetchOptions } = options;
    const accessToken = await getAccessToken({
      authorizationParams: { /* audience, */ scope },
    });

    return await publicFetch<TBody>(
      url,
      {
        ...fetchOptions,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...fetchOptions.headers,
        },
      },
      callback,
    )();
  };
}

/**
 * Utility wrapper for the native `fetch` function with application authentication.
 * Options can be specified asynchronously.
 * @param url The HTTP resource URL
 * @param getAccessToken The Auth0 function to get the access token
 * @param options The native `fetch` function options
 * @param callback The HTTP response body processing callback
 * @returns The processed HTTP response body
 */
export function authenticatedFetchAsync<TBody>(
  url: string,
  getAccessToken: Auth0ContextInterface["getAccessTokenSilently"],
  optionsAsync: (...args: any[]) => Promise<UseApiOptions> = () =>
    Promise.resolve({}),
  callback = processJsonResponse,
) {
  return async (...args: any[]) => {
    const { audience, scope, ...fetchOptions } = await optionsAsync(...args);
    const accessToken = await getAccessToken({
      authorizationParams: { /* audience, */ scope },
    });

    return await publicFetch<TBody>(
      url,
      {
        ...fetchOptions,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...fetchOptions.headers,
        },
      },
      callback,
    )();
  };
}

/**
 * Query hook for authenticated API requests with the application API.
 * @param param0 Query configuration, including key, function, and options
 * @returns The react-query hook response
 */
export function useAuthenticatedQuery<
  TBody,
  TData = unknown,
  TQueryKey extends QueryKey = QueryKey,
>({
  queryKey,
  queryFn,
  urlBuilderFn = (key) => `/${key.join("/")}`,
  queryOptions,
  fetchOptions = {},
  responseCallback = processJsonResponse,
}: {
  queryKey: TQueryKey;
  queryFn: (value: SerializedObject<TBody>) => Promise<TData>;
  urlBuilderFn?: (key: TQueryKey) => string;
  queryOptions?: Omit<UseQueryOptions<TData>, "queryKey" | "queryFn">;
  fetchOptions?: UseApiOptions;
  responseCallback?: (res: Response) => Promise<TData>;
}) {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    ...queryOptions,
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey,
    queryFn: async () => {
      const url = urlBuilderFn(queryKey);
      const data = await authenticatedFetch<SerializedObject<TBody>>(
        `${process.env.REACT_APP_API_ROOT}${url}`,
        getAccessTokenSilently,
        fetchOptions,
        responseCallback,
      )();

      return queryFn(data);
    },
  });
}

/**
 * @deprecated Use `useAuthenticatedQuery` instead
 */
export const useAuthenticatedFetch = <TBody>(
  url: string,
  fetchOptions: UseApiOptions = {},
  queryOptions?: Omit<UseQueryOptions<TBody>, "queryKey" | "queryFn">,
  queryKey: QueryKey = [url],
  responseCallback = processJsonResponse,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const fullUrl = `${process.env.REACT_APP_API_ROOT}${url}`;

  return useQuery({
    queryKey,
    queryFn: authenticatedFetch<TBody>(
      fullUrl,
      getAccessTokenSilently,
      fetchOptions,
      responseCallback,
    ),
    ...queryOptions,
  });
};

/**
 * @deprecated Use `useAuthenticatedQuery` instead
 */
export const useAuthenticatedFileFetch = <TBody>(
  url: string,
  fetchOptions: UseApiOptions = {},
  queryOptions?: Omit<UseQueryOptions<TBody>, "queryKey" | "queryFn">,
  queryKey: QueryKey = [url],
) =>
  useAuthenticatedFetch(
    url,
    fetchOptions,
    queryOptions,
    queryKey,
    processFileResponse,
  );

/**
 * @deprecated Use `useAuthenticatedMutationNew` instead
 */
export const useAuthenticatedMutation = <TBody>(
  url: string,
  fetchOptions: UseApiOptions = {},
  responseCallback = processJsonResponse,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const fullUrl = `${process.env.REACT_APP_API_ROOT}${url}`;

  return authenticatedFetch<TBody>(
    fullUrl,
    getAccessTokenSilently,
    fetchOptions,
    responseCallback,
  );
};

/**
 * Create a mutation hook that includes authenticated fetching and data
 * deserialization.
 * @param param0.mutationKey The mutation query key
 * @param param0.mutationFn The callback function on the returned data
 * @returns The mutation hook
 */
export function useAuthenticatedMutationNew<
  TData = unknown,
  TReturn = unknown,
  TValue = unknown,
  TMutationKey extends MutationKey = MutationKey,
>({
  mutationKey,
  mutationFn,
  urlBuilderFn = (key) => `/${key.join("/")}`,
  mutationOptions,
  responseCallback = processJsonResponse,
}: {
  mutationKey: [string, ...TMutationKey];
  mutationFn: (value: SerializedObject<TData>) => TReturn;
  urlBuilderFn?: (key: TMutationKey) => string;
  mutationOptions?: Omit<
    UseMutationOptions<TReturn, Error, TValue>,
    "mutationKey" | "mutationFn"
  >;
  responseCallback?: (res: Response) => Promise<TData>;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const method = mutationKey[0] ?? "POST";

  return useMutation({
    ...mutationOptions,
    mutationKey,
    mutationFn: async (value: TValue) => {
      const url = urlBuilderFn(mutationKey.slice(1) as [...TMutationKey]);
      const data = await authenticatedFetch<SerializedObject<TData>>(
        `${process.env.REACT_APP_API_ROOT}${url}`,
        getAccessTokenSilently,
        { method, body: value },
        responseCallback,
      )();

      return mutationFn(data);
    },
  });
}

/**
 * @deprecated Use `useAuthenticatedMutationNew` instead
 */
export const useAuthenticatedMutationAsync = <TBody>(
  url: string,
  fetchOptionsAsync: (...args: any[]) => Promise<UseApiOptions> = () =>
    Promise.resolve({}),
  responseCallback = processJsonResponse,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const fullUrl = `${process.env.REACT_APP_API_ROOT}${url}`;

  return authenticatedFetchAsync<TBody>(
    fullUrl,
    getAccessTokenSilently,
    fetchOptionsAsync,
    responseCallback,
  );
};

/**
 * @deprecated Use `useAuthenticatedMutationNew` instead
 */
export const usePublicdMutationAsync = <TBody>(
  url: string,
  fetchOptionsAsync: (...args: any[]) => Promise<UseApiOptions> = () =>
    Promise.resolve({}),
  responseCallback = processJsonResponse,
) => {
  const fullUrl = `${process.env.REACT_APP_API_ROOT}${url}`;

  return publicFetchAsync<TBody>(fullUrl, fetchOptionsAsync, responseCallback);
};

/**
 * Deserialize a date value from an HTTP response body or other source.
 * @param dateValue The original date value to deserialize
 * @returns The deserialized date object, or the original value if empty
 */
export function deserializeDate<
  TValue extends Date | string | undefined | null,
>(dateValue: TValue) {
  if (typeof dateValue === "undefined" || dateValue === null) {
    return dateValue as Extract<TValue, undefined | null>;
  }

  const date = new Date(dateValue);

  if (date.toString() === "Invalid date") {
    throw new Error(`Failed to parse date: ${dateValue}`);
  }

  return date;
}
